import React from 'react'
import { useState } from 'react';
import UserService from '../../../httpServices/user.service';

function AddAdmin() {
    const [userInfo, setUserInfo] = useState({
        fullName_en: '',
        fullName_ar: '',
        userName_en: '',
        userName_ar: '',
        email: '',
        password: '',
    })
    const handleOnChange = (e) => {
        setUserInfo((prev) => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }
    const handleOnSubmit = (e) => {
        e.preventDefault();
        addUser(userInfo)
    }

    const addUser = async (userInfo) => {
        const data = await UserService.createUser('user', userInfo)
        if (data) {
            console.log('data: ', data.message_en)
        }
    }
    return (
        <div className="container">
            <div className="row ">
                <div className="col-10 mx-auto">
                    <div className='user d-flex flex-column justify-content-center' style={{ height: '100vh' }}>
                        <h2 className="user__title text-start my-3" style={{ fontFamily: 'sans-serif' }}>Create Admin</h2>
                        <form action="" onSubmit={handleOnSubmit}>
                            <div className="form-group my-2" >
                                <input type="text" className="form-control p-3" placeholder='Enter You En FullName' name='fullName_en' onChange={handleOnChange} />
                            </div>
                            <div className="form-group my-2" >
                                <input type="text" className="form-control p-3" placeholder='Enter Your Ar FullName' name={'fullName_ar'} onChange={handleOnChange} />
                            </div>
                            <div className="form-group my-2" >
                                <input type="text" className="form-control p-3" placeholder='Enter Your En userName' name={'userName_en'} onChange={handleOnChange} />
                            </div>
                            <div className="form-group my-2" >
                                <input type="number" className="form-control p-3" placeholder='Enter Your Phone' name={'phone'} onChange={handleOnChange} />
                            </div>
                            <div className="form-group my-2" >
                                <input type="text" className="form-control p-3" placeholder='Enter Your Ar UserName' name='userName_ar' onChange={handleOnChange} />
                            </div>
                            <div className="form-group my-2" >
                                <select className="form-control p-3" name="role" onChange={handleOnChange}>
                                    <option selected hidden>Select Role</option>
                                    <option value={"super admin"} >Super Admin</option>
                                    <option  value={"admin"}>Admin</option>
                                </select>
                            </div>
                            <div className="form-group my-2" >
                                <input type="text" className="form-control p-3" placeholder='Enter Your Email' name='email' onChange={handleOnChange} />
                            </div>
                            <div className="form-group my-2" >
                                <input type="text" className="form-control p-3" placeholder='Enter Your Password' name='password' onChange={handleOnChange} />
                            </div>
                            <div className="btn-wrapper d-flex justify-content-start my-3">
                                <button className="btn btn-primary p-3">Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAdmin