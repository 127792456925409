import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { TiDelete } from "react-icons/ti";
import { FaEdit } from "react-icons/fa";
import { CgAddR } from "react-icons/cg";
import {
  ALL_BLOGS,
  DELETE_BLOG,
  EDIT_BLOG,
  SET_USER,
} from "../../../../redux/types/types";
import { useEffect, useState } from "react";

import BlogServices from "../../../../httpServices/blogs.services";
import { AiOutlineComment } from "react-icons/ai";
import Like from "../LikeComponent/Like";
import BlogCard from "../BlogCard/BlogCard";
import UserService from "../../../../httpServices/user.service";
import "./blogs.css";
const Blogs = () => {
  let [eventBlogs, setEventBlogs] = useState([]);
  const { blogs } = useSelector((state) => state.blogs);
  let [recievedBlogs, setRecievedBlogs] = useState([]);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  let [like, setLike] = useState("");

  const deleteBlogHandler = (blogId) => {
    // calling delete from Blog Services

    BlogServices.deleteBlog(`blog/${blogId}`).then((_) => {
      dispatch({ type: DELETE_BLOG, payload: blogId });

      setRecievedBlogs((prev) => blogs.filter((blog) => blog._id != blogId));
    });
  };

  useEffect(() => {
    if (blogs.length == 0) {
      const getData = () => {
        BlogServices.getAllServices("blog")
          .then((data) => {
            if (data) {
              let copyBlog = [...data.blogs];
              copyBlog = copyBlog.filter((blog) => blog.blogType != "event");
              let copyEvents = [...data.blogs];
              copyEvents = copyEvents.filter((blog) => blog.blogType != "blog");
              setEventBlogs((_) => [
                ...copyEvents.map((blog) => {
                  return {
                    ...blog,
                    isLike: false,
                  };
                }),
              ]);
              setRecievedBlogs((_) => [
                ...copyBlog.map((blog) => {
                  return {
                    ...blog,
                    isLike: false,
                  };
                }),
              ]);
              dispatch({
                type: ALL_BLOGS,
                payload: [
                  ...data.blogs.map((blog) => {
                    return {
                      ...blog,
                      isLike: false,
                    };
                  }),
                ],
              });
            }
          })
          .catch((e) => {});
      };
      getData();
    } else {
      let copyBlog = [...blogs];
      copyBlog = copyBlog.filter((blog) => blog.blogType != "event");
      setRecievedBlogs((_) => [
        ...copyBlog.map((blog) => {
          return {
            ...blog,
            isLike: false,
          };
        }),
      ]);

      let copyEvents = [...blogs];
      copyEvents = copyEvents.filter((blog) => blog.blogType != "blog");
      setEventBlogs((_) => [
        ...copyEvents.map((blog) => {
          return {
            ...blog,
            isLike: false,
          };
        }),
      ]);
    }
  }, [blogs, dispatch]);

  useEffect(() => {
    if (user.email) {
    } else {
      const getUserProfile = () => {
        UserService.userProfile("user/me").then((value) => {
          dispatch({ type: SET_USER, payload: value.user });
        });
      };

      getUserProfile();
    }
  }, [dispatch, user]);

  const blogCards =
    recievedBlogs.length > 0 &&
    recievedBlogs.map((blog, index) => (
      <BlogCard
        blog={blog}
        user={user}
        deleteBlogHandler={deleteBlogHandler}
        key={index}
      />
    ));

  const eventCards = eventBlogs.map((event, index) => (
    <BlogCard
      blog={event}
      user={user}
      deleteBlogHandler={deleteBlogHandler}
      key={index}
    />
  ));
  // end of Mapping events
  const lang = localStorage.getItem("lang");
  return (
    <div className="blogs">
      <Link to="/blogs">{lang === "en" ? "All Blogs" : "كل المدونات"}</Link>

      <div className="">
        <div className="d-flex row justify-content-center">
          <div className="d-flex justify-content-between">
            <h1 className="display-4 ">
              {lang === "en" ? "Blogs" : "المدونات"}
            </h1>
            <Link
              to={"/adminDashboard/blogs/newBlog"}
              className="m-3"
              style={{ textDecoration: "none", color: "black" }}
            >
              <CgAddR style={{ color: "#00e600", fontSize: "45px" }} />
            </Link>
          </div>

          <div className="container">
            <div className="blogs_wraapper">{blogCards}</div>
          </div>
        </div>
        <div className="">
          <h1 className="lead fs-1">
            {lang === "en" ? "Events" : "الفعاليات"}
          </h1>
          <div className="events_wrapper">{eventCards}</div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
