import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const ServicesServices = {
  getServices: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);

    return data;
  },
  deleteService: async function (endPoint) {
    const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}`);

    return data;
  },
};

export default ServicesServices;
