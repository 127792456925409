import React from "react";
import "./InputText.scss";
const InputText = ({
  label,
  placeholder,
  type,
  name,
  value,
  handleChange,
  error,
  isTouched,
  handleBlur,
}) => {
  return (
    <div
      className={`input-text ${type === "textarea" && "textarea-style"} ${
        error & "error"
      } `}
    >
      <label className="d-block fs-5 ms-1 text-uppercase">{label}</label>

      {type === "text" && (
        <input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(event) => handleChange(event)}
          onBlur={handleBlur}
          className={error && isTouched && "error"}
        />
      )}
      {type === "textarea" && (
        <textarea
          value={value}
          onChange={handleChange}
          name={name}
          className={error && isTouched && "error"}
          onBlur={handleBlur}
        />
      )}
      {error && isTouched && <p>{error}</p>}
    </div>
  );
};

export default InputText;
