// import reservedSections from "../../../initialData/ReservedSections";

import {
  PUSH_SUB_TO_RESERVED_SECTION,
  EDIT_RESERVED_SECTION_FROM_LANDING_PAGE,
  EDIT_RESERVED_SECTION,
  REMOVE_RESERVED_SECTION,
  REMOVE_RESERVED_SUB_FROM_RESERVED_SECTION,
  EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE,
  ADD_NEW_RESERVED_SECTION,
  GET_ALL_ERS_SECTIONS,
  REMOVE_SEC_SUB_FROM_LANDING_PAGE,
} from "./resSections_types";
const reservedSections = [];
const reservedSectionsReducer = (
  state = reservedSections,
  { type, payload }
) => {
  switch (type) {
    case GET_ALL_ERS_SECTIONS:
      return [...state, ...payload];
    case REMOVE_RESERVED_SECTION:
      return state.filter((section) => section?._id !== payload._id);
    case EDIT_RESERVED_SECTION:
      return state.map((section) =>
        section?._id === payload.selectedSection?._id
          ? {
              ...payload.newSection,
              subSections: payload.newSection.subSections.map((sub) => ({
                ...sub,
                extras: sub?.extras?.length > 0 ? [...sub.extras] : [],
              })),
            }
          : section
      );
    case PUSH_SUB_TO_RESERVED_SECTION:
      return state.map((section) =>
        section?._id === payload.adminSection?._id
          ? {
              ...section,
              subSections: [...section.subSections, payload.newSub],
            }
          : section
      );

    case EDIT_RESERVED_SECTION_FROM_LANDING_PAGE:
      return state.map((section) =>
        section._id === payload.adminSection._id ? payload.newSection : section
      );

    case REMOVE_RESERVED_SUB_FROM_RESERVED_SECTION:
      return state.map((section) =>
        section._id === payload.section._id
          ? {
              ...section,
              subSections: section.subSections.filter(
                ({ _id }) => _id !== payload.sub._id
              ),
            }
          : section
      );
    case REMOVE_SEC_SUB_FROM_LANDING_PAGE:
      return state.map((section) =>
        section._id === payload.adminSection._id
          ? {
              ...section,
              subSections: section.subSections.filter(
                ({ _id }) => _id !== payload.sub._id
              ),
            }
          : section
      );

    case EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE:
      return state.map((section) =>
        section._id === payload.adminSection._id
          ? {
              ...section,
              subSections: section.subSections.map((sub) =>
                sub._id === payload.selectedSub._id ? payload.newSub : sub
              ),
            }
          : section
      );

    case ADD_NEW_RESERVED_SECTION:
      return [...state, payload];
    default:
      return state;
  }
};

export default reservedSectionsReducer;
