import React, { useEffect, useState } from "react";
import "./reservedSubs.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  GET_ALL_RESERVED_SUB,
  REMOVE_RESERVED_SUB_SECTION,
} from "../../../redux/reducers/resSubs_reducer/resSubs_types";
import {
  GET_ALL_PAGES,
  REMOVE_ALL_EXISTED_RESERVED_SUBS,
} from "../../../redux/reducers/pages_reducer/page_types";
import subSectionsService from "../../../httpServices/subSection.services";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import icon from "../../../assets/folder_icon.svg";
import AddResSubModel from "../../components/reserved/addResSubModel/AddResSubModel";
import EditReservedSubModel from "../../components/reserved/edit_sub/EditReservedSubModel";
import { toast, ToastContainer } from "react-toastify";
import PagesService from "../../../httpServices/pages.services";
const ReservedSubsPage = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const { reservedSubs, pages } = useSelector((state) => state);
  const [models, setModels] = useState({
    add: false,
    edit: { activiton: false, subId: "" },
  });
  useEffect(() => {
    if (!reservedSubs[0]) {
      subSectionsService.getAllsubSections("subSection").then((data) => {
        if (data.Subsections) {
          dispatch({
            type: GET_ALL_RESERVED_SUB,
            payload: data.Subsections.Subsections,
          });
        }
      });
    }
    if (!pages[0]) {
      PagesService.getAllPages("page").then((data) => {
        dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
      });
    }
  }, []);
  const removeSub = (sub) => {
    subSectionsService
      .deleteSub("subSection", sub._id)
      .then((res) => {
        if (res.success) {
          dispatch({ type: REMOVE_RESERVED_SUB_SECTION, payload: sub });
          dispatch({ type: REMOVE_ALL_EXISTED_RESERVED_SUBS, payload: sub });
          toast.success(res.message_en);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const showEditModel = (sub) => {
    setModels({
      ...models,
      edit: {
        activiton: true,
        subId: sub._id,
      },
    });
  };
  return (
    <section className={`sub_sections_page sub_sections_style_${lang}`}>
      <ToastContainer />
      <div className="inner_container">
        <h3 className="heading">
          {lang === "en" ? "Sub Sections" : "الاقسام الفرعية"}
        </h3>
        <div className="sub_sections_wrapper">
          {reservedSubs.map((sub) => (
            <div className="sub_section" key={sub._id}>
              <button className="delete_btn" onClick={() => removeSub(sub)}>
                <MdOutlineDeleteOutline />
              </button>
              <button className="edit_btn" onClick={() => showEditModel(sub)}>
                <BiPencil />
              </button>
              <NavLink className="route">
                <div className="route-container">
                  <img src={icon} alt="image" />
                  {lang === "en" ? (
                    <h4>
                      {sub?.title_en?.length > 18
                        ? `${sub?.title_en.slice(0, 18)}...`
                        : sub?.title_en}
                    </h4>
                  ) : (
                    <h4>
                      {sub?.title_ar?.length > 20
                        ? `${sub?.title_ar.slice(0, 20)}...`
                        : sub?.title_ar}
                    </h4>
                  )}
                </div>
              </NavLink>
              {models.edit.activiton && (
                <EditReservedSubModel
                  lang={lang}
                  setModels={setModels}
                  sub={reservedSubs.find(
                    ({ _id }) => _id === models.edit.subId
                  )}
                />
              )}
            </div>
          ))}

          <button
            className="add_btn"
            onClick={() => setModels({ ...models, add: true })}
          >
            <div>
              <FaPlus />
              <h4>{lang === "en" ? "Adding" : "إضافة"}</h4>
            </div>
          </button>
        </div>
        {models.add && (
          <AddResSubModel
            reservedSubs={reservedSubs}
            lang={lang}
            setModels={setModels}
          />
        )}
      </div>
    </section>
  );
};

export default ReservedSubsPage;
