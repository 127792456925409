import React, { useEffect, useState } from "react";
import { AiFillDelete, AiOutlineComment } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import CommentService from "../../../../httpServices/comment.service";
import {
  ADD_COMMENT,
  DELETE_COMMENT,
  DELETE_REPLY,
  SET_USER,
} from "../../../../redux/types/types";
import { useNavigate } from "react-router-dom";
import UserService from "../../../../httpServices/user.service";
import Reply from "../reply/Reply";
import "./comment.scss";
import ShowReplys from "../reply/ShowReplys";
import AddReply from "../reply/AddReply";
import Like from "../LikeComponent/Like";
import { publicUrl } from "../../../../httpServices/httpServices";
function Comment({ blog, user }) {
  const navigate = useNavigate();
  const { blogs } = useSelector((state) => state.blogs);
  const [currentBlog, setCurrentBlog] = useState(blog);
  const [userProfile, setUserProfile] = useState(null);
  const [states, setStates] = useState(Array(blog.comments.length).fill(true));
  const [replysState, setReplysState] = useState(
    Array(blog.comments.length).fill(false)
  );
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();

  const handleAddReply = (index) => {
    const copyReplysState = [...replysState];
    copyReplysState[index] = copyReplysState[index] ? false : true;
    setReplysState((_) => copyReplysState);
    // setStates(prev => [...prev].map(state => state == true ? true : state))
  };
  const handleOpenTestReply = (index) => {
    let copyStates = [...states];
    copyStates[index] = copyStates[index] ? false : true;
    setStates((_) => copyStates);
  };
  const handleAddComment = (e) => {
    e.preventDefault();
    CommentService.addComment(`comment`, {
      title,
      user: user._id,
      blog: blog._id,
    }).then((value) => {
      let copyValue = { ...value.comment };
      delete copyValue["__v"];
      dispatch({
        type: ADD_COMMENT,
        payload: copyValue,
      });
      setTitle("");
    });
  };
  const deleteComment = (commentId) => {
    CommentService.deleteComment(
      `comment/delete/${blog._id}/${commentId}`
    ).then((value) => {
      dispatch({
        type: DELETE_COMMENT,
        payload: { blog: blog._id, comment: commentId },
      });
    });
    // MANIPULATING THE CURRENT bLOG TO dELETE THE COMMENT
    // checkWhichOneShouldBeUsedToUpdateBlogAfterAddingComment();
  };

  const checkForAdminOrRoot = (comment) => {
    // console.log('user comment',comment)
    console.log(user.role)
    if (comment.user._id === user._id || user.role === "super admin") return true;
    return false;
  };
  console.log(checkForAdminOrRoot);

  const handleDeleteReply = (commentId, replyId, blogId) => {
    CommentService.deleteReply(`comment/reply/${replyId}`).then((value) => {
      // dispatch to delete the reply from the redux itself
      dispatch({
        type: DELETE_REPLY,
        payload: { comment: commentId, blog: blogId, reply: replyId },
      });
      setCurrentBlog(blogs.find((blog) => blog.id == blogId));
    });
  };

  useEffect(() => {
    setCurrentBlog((prev) =>
      blogs.find((blog) => blog._id === currentBlog._id)
    );
  }, [blogs, currentBlog]);

  useEffect(() => {
    if (user.email) {
      setUserProfile((prev) => user);
    } else {
      const getUserProfile = () => {
        UserService.userProfile("user/me").then((value) => {
          setUserProfile(value.user);
          // dispatching to save the loged in user
          dispatch({ type: SET_USER, payload: value.user });
        });
      };

      getUserProfile();
    }
    // calling to set the currentBlog
  }, [dispatch, user]);
  const lang = localStorage.getItem("lang");
  return (
    <div>
      <form className="form-group d-flex form">
        <input
          type="text"
          placeholder={lang === "en" ? "Enter Your Comment" : "ادخل تعليقك"}
          className="form-control"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <button className="btn border-0 focus-0" onClick={handleAddComment}>
          {lang === "en" ? "Add" : "اضافة"}
        </button>
      </form>

      <div className="hr my-3"></div>

      {currentBlog.comments.length > 0 &&
        currentBlog.comments.map((comment, index) => {
          console.log("comment: ", comment);
          return (
            <div className="parentWrapper " key={index}>
              {/* <Reply blog={blog} comment={comment} user={user} /> */}
              <div className=" comment_wrapper d-flex justify-content-start align-items-start justify-content-between  mt-3">
                <img
                  className="userImage"
                  src={`${
                    comment.user.image
                      ? `${publicUrl}/${comment.user.image}`
                      : "https://via.placeholder.com/150"
                  }`}
                  alt="userProfile"
                />
                <div className="comment-wrapper d-flex flex-column justify-content-between">
                  {/* <div className="user-info ">
                    <div className="info-wrapper"></div>
                  </div> */}

                  <p className="fw-bold">{comment.user.fullName_en}</p>
                  <p
                    className="description"
                    style={{
                      fontSize: "18px",
                      marginTop: "10px",
                      wordWrap: "break-word",
                    }}
                  >
                    {comment.title}
                  </p>

                  <div className="d-flex justify-content-start ">
                    <div className="commentReplyWrapper ">
                      <AiOutlineComment
                        className="commentReplyFont"
                        onClick={() => handleAddReply(index)}
                      />
                    </div>
                    {(
                      <div className="deleteWrapper">
                        <AiFillDelete
                          className=" d-flex justify-content-start deleteFont"
                          onClick={() => deleteComment(comment._id)}
                          style={{}}
                        />
                      </div>
                    )}
                  </div>
                  {/* {states[index].toString() == 'true' && <div className="reply__group form-group d-flex justify-content-start " style={{ marginLeft: '-50px' }}>
                  <input type="text" className="form-contro" placeholder="Test Reply" />
                  <button className="btn btn-lg btn-secondary ">Send</button>
                </div> */}
                </div>
              </div>
              <button
                className={`show_hide_reblies show_hide_reblies_${lang} py-0 btn`}
                style={{
                  textDecoration: "none",
                  fontSize: "16px",
                  color: !states[index] ? "blue" : "red",
                }}
                onClick={() => handleOpenTestReply(index)}
              >
                {!states[index]
                  ? lang === "en"
                    ? "Show Reblies"
                    : "إظهار الردود"
                  : lang === "en"
                  ? "Hide Reblies"
                  : "إخفاء الردود"}
              </button>
              {/* <Reply blog={blog} comment={comment} user={user} /> */}
              {/* <Reply blog={blog} comment={comment} user={user} />
               */}
              {/* this is just test to reply  */}

              {/* {states[index] && <p>This is The deal</p>} */}

              {states[index] && (
                <ShowReplys blog={blog} comment={comment} user={user} />
              )}
              {replysState[index] && (
                <AddReply commentId={comment._id} blogId={blog._id} />
              )}
            </div>
          );
        })}
    </div>
  );
}

export default Comment;
