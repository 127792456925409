import { useSelector, useDispatch } from "react-redux";
import { Link, } from "react-router-dom";

import { CgAddR } from "react-icons/cg";
import { ALL_BLOGS, SET_USER, } from "../../../redux/types/types";
import { useEffect, useState } from "react";

import BlogServices from "../../../httpServices/blogs.services";
import { AiOutlineComment } from "react-icons/ai";
import BlogCard from "../../../admins/components/Blogs/BlogCard/BlogCard";
import UserService from "../../../httpServices/user.service";
import './userBlogs.css'
const UserBlogs = () => {
    let [eventBlogs, setEventBlogs] = useState([])
    const { blogs } = useSelector((state) => state.blogs);
    let [recievedBlogs, setRecievedBlogs] = useState([]);
    const { user } = useSelector(state => state.user)



    const dispatch = useDispatch();
    let [like, setLike] = useState("");







    useEffect(() => {
        if (blogs.length == 0) {
            const getData = () => {
                BlogServices.getAllServices("blog").then((data) => {
                    if (data) {
                        let copyBlog = [...data.blogs]
                        copyBlog = copyBlog.filter(blog => blog.blogType != 'event')
                        let copyEvents = [...data.blogs]
                        copyEvents = copyEvents.filter(blog => blog.blogType != 'blog')
                        setEventBlogs((_) => [
                            ...copyEvents.map((blog) => {
                                return {
                                    ...blog,
                                    isLike: false,

                                };
                            }),
                        ])
                        setRecievedBlogs((_) => [
                            ...copyBlog.map((blog) => {
                                return {
                                    ...blog,
                                    isLike: false,

                                };
                            }),
                        ]);
                        dispatch({
                            type: ALL_BLOGS,
                            payload: [
                                ...data.blogs.map((blog) => {
                                    return {
                                        ...blog,
                                        isLike: false,

                                    };
                                }),
                            ],
                        });
                    }

                });
            };
            getData();
        } else {
            let copyBlog = [...blogs]
            copyBlog = copyBlog.filter(blog => blog.blogType != 'event')
            setRecievedBlogs((_) => [
                ...copyBlog.map((blog) => {
                    return {
                        ...blog,
                        isLike: false,

                    };
                }),
            ]);

            let copyEvents = [...blogs]
            copyEvents = copyEvents.filter(blog => blog.blogType != 'blog')
            setEventBlogs((_) => [
                ...copyEvents.map((blog) => {
                    return {
                        ...blog,
                        isLike: false,

                    };
                }),
            ])
        }
    }, [blogs, dispatch]);

    useEffect(() => {
        if (user.email) {
        } else {
            const getUserProfile = () => {
                UserService.userProfile("user/me").then((value) => {
                    dispatch({ type: SET_USER, payload: value.user });
                });
            };

            getUserProfile();
        }
        // calling to set the currentBlog
    }, [dispatch, user]);
    const blogCards =
        recievedBlogs.length > 0 &&
        recievedBlogs.map((blog, index) => (

            <BlogCard blog={blog} key={index} user={user} />
        ));



    const eventCards = eventBlogs.map((event, index) => (
        <BlogCard blog={event} key={index} user={user} />

    ));
    // end of Mapping events

    return (
        <div className="blogs">
            <Link to="/blogs">blll</Link>

            <div className="container-fluid">
                <div className="d-flex row justify-content-center">
                    <div className="d-flex justify-content-between">
                        <h1 className="display-4 ">Blogs</h1>
                        <Link
                            to={"/adminDashboard/blogs/newBlog"}
                            className="m-3"
                            style={{ textDecoration: "none", color: "black" }}
                        >
                            <CgAddR style={{ color: "#00e600", fontSize: "45px" }} />
                        </Link>
                    </div>

                    <div className="container">
                        <div className="row justify-content-around">
                            {blogCards}
                        </div>
                    </div>


                </div>
                <div className="row justify-content-around">
                    <p className="lead">Events</p>
                    {eventCards}
                </div>
            </div>
        </div>
    );
};

export default UserBlogs;
