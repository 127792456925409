export const  GET_ALL_PAGES = "GET_ALL_PAGES"


export const ADD_PAGE = "ADD_PAGE";
export const ADD_NEW_SECTION = "ADD_NEW_SECTION";
export const ADD_NEW_SECTION_FROM_FORM = "ADD_NEW_SECTION_FROM_FORM";
export const ADD_NEW_SECTION_WITH_EXTRA = "ADD_NEW_SECTION_WITH_EXTRA";
export const ADD_NEW_SUB = "ADD_NEW_SUB";
export const ADD_NEW_SUB_BY_FORM = "ADD_NEW_SUB_BY_FORM";
export const EDIT_PAGE = "EDIT_PAGE";
export const EDIT_SECTION = "EDIT_SECTION";
export const EDIT_SUB = "EDIT_SUB";

export const REMOVE_PAGE = "REMOVE_PAGE";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const REMOVE_SUB = "REMOVE_SUB";

export const EDIT_EXISTED_RESERVED_SECTION = "EDIT_EXISTED_RESERVED_SECTION";
export const EDIT_EXISTED_RESERVED_SUB = "EDIT_EXISTED_RESERVED_SUB";
export const REMOVE_ALL_EXISTED_RESERVED_SECTIONS =
  "REMOVE_ALL_EXISTED_RESERVED_SECTIONS";
export const REMOVE_ALL_EXISTED_RESERVED_SUBS =
  "REMOVE_ALL_EXISTED_RESERVED_SUBS";

export const EDIT_ALL_EXISTED_SUBS_CHILDS = "EDIT_ALL_EXISTED_SUBS_CHILDS";
