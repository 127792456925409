import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import contactsServices from '../../../httpServices/contact.services'
import { GET_COMPLAINS } from '../../../redux/types/types'

const ComplainMessage = () => {
  const { complainId } = useParams()
  const { complains } = useSelector((state) => state.complains)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!complains[0]) {
      console.log("ia here");
      contactsServices
        .getAllContacts(`contact/filter/query/?type=complain`)
        .then((data) => {
          if (data) {
            dispatch({ type: GET_COMPLAINS, payload: data.contacts })
          }
        })
    }
  }, [])

  const complainMessage = complains.find((complain) => {
    return complain._id === complainId
  })

  return (
    <div className="d-flex justify-content-center align-items-center m-5 p-5">
      {
        <div
          key={complainMessage?._id}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <h1 className="p-5">Complaint Message</h1>
          <p> {complainMessage?.message} </p>
        </div>
      }
    </div>
  )
}

export default ComplainMessage
