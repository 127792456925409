const currentLanguage =
  !localStorage.getItem("lang") && localStorage.setItem("lang", "ar");
const enLangReducer = (state = currentLanguage, { type }) => {
  if (type === "SWITCH_LANGUAGE") {
    localStorage.getItem("lang") === "en"
      ? localStorage.setItem("lang", "ar")
      : localStorage.setItem("lang", "en");
  }
  return (state = localStorage.getItem("lang"));
};
export default enLangReducer;
