import * as yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./SignUp.module.css";
import { useState } from "react";
import { MdAddPhotoAlternate } from "react-icons/md";
import UserService from "../../../httpServices/user.service";
import { useDispatch, useSelector } from "react-redux";
import { NEW_USER } from "../../../redux/types/types";
import UploadServices, { publicUrl } from "../../../httpServices/httpServices";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
const SignUp = () => {
  const dispatch = useDispatch();
  // const  {user}  = useSelector((state) => state.users);
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const [number, setNumber] = useState("");
  const [blurError, setBlurError] = useState("");
  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      fullName_en: "",
      email: "",
      // phone: number,
      image: "",
      password: "",
    },
    validationSchema: yup.object({
      fullName_en: yup
        .string()
        .required(lang === "en" ? "*Required" : "*مطلوب"),

      email: yup
        .string()
        .email(
          lang === "en" ? "Should be Valid Email" : "يجب أن يكون إيميل صحيح"
        )
        .required(lang === "en" ? "*Required" : "*مطلوب"),
      password: yup.string().required(lang === "en" ? "*Required" : "*مطلوب"),
      image: yup.string().required(lang === "en" ? "*Required" : "*مطلوب"),
    }),
    onSubmit: async (values) => {
      if (number != "") {
        Object.assign(formik.values, { phone: +number });
        UserService.createUser(
          `user/${localStorage.getItem("token") ? "" : "register"}`,
          values
        ).then((_) => {
          dispatch({ type: NEW_USER, payload: values });
          toast.success(
            lang === "en"
              ? `You've signed up successfully, You'll be directed to login page`
              : `تم التسجيل بنجاح، سيتم تحويلك لصفحة تسجيل الدخول`
          );
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        });
      }
    },
  });
  const blurHandler = (e) => {
    !e.target.value && setBlurError(lang === "en" ? "*Required" : "*مطلوب");
  };
  const checkPhone = () => {
    if (number == "") {
      setBlurError(lang === "en" ? "*Required" : "*مطلوب");
    }
  };

  const fileHandler = async (e) => {
    const formData = new FormData();

    formData.append("image", e.target.files[0]);
    setFile((_) => e.target.files[0]);
    const resImage = await UploadServices.uploadFile("upload", formData);
    formik.setFieldValue("image", resImage.filename);
  };
  return (
    <div className="d-flex justify-content-center align-items-center m-5 p-5">
      <ToastContainer />
      <form
        className="d-flex flex-column justify-content-center align-items-center w-100 h-100"
        onSubmit={formik.handleSubmit}
      >
        <label>{lang === "en" ? "Full Name" : "الأسم بالكامل"}</label>
        <input
          type="text"
          name="fullName_en"
          onChange={formik.handleChange}
          value={formik.values.fullName_en}
          onBlur={formik.handleBlur}
          className="m-3  p-2 border rounded shadow-sm"
        />
        {formik.touched.fullName_en && formik.errors.fullName_en ? (
          <p className={styles.Error}>{formik.errors.fullName_en}</p>
        ) : null}
        <label>{lang === "en" ? "Email" : "البريد الإلكتروني"}</label>
        <input
          type="text"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
          onBlur={formik.handleBlur}
          className="m-3 h-100  p-2 border rounded shadow-sm"
        />
        {formik.touched.email && formik.errors.email ? (
          <p className={styles.Error}>{formik.errors.email}</p>
        ) : null}
        <label>{lang === "en" ? "Phone" : "الجوال"}</label>
        <PhoneInput
          value={number}
          country={"sa"}
          defaultErrorMessage="test"
          containerClass={`rounded my-3 ${styles.PhoneContainer} shadow-sm`}
          buttonClass="border rounded shadow-sm"
          inputClass={`${styles.PhoneInput} m-4 w-100 p-3 border`}
          onChange={(number) => {
            setNumber(number);
            setBlurError("");
          }}
          onBlur={(e) => blurHandler(e)}
        />

        <p className={styles.Error}>{blurError}</p>

        {formik.touched.phone && formik.errors.phone ? (
          <p className={styles.Error}>{formik.errors.phone}</p>
        ) : null}

        <label>{lang === "en" ? "Image" : "صورة"}</label>
        <div
          className={`${styles.Wrapper} d-flex justify-content-center align-items-center m-3 border-opacity-50 border border-info rounded-circle shadow`}
        >
          {/* <div className={styles.Btnimg}>


            <MdAddPhotoAlternate style={{fontSize:'50px'}} />
          </div> */}
          {!file ? (
            <div className={styles.Btnimg}>
              <MdAddPhotoAlternate style={{ fontSize: "50px" }} />
            </div>
          ) : (
            // (
            //   <img
            //     src={URL.createObjectURL(file)}
            //     alt="profile"
            //     width="auto"
            //     style={{ height: "auto", borderRadius: "50%" }}
            //   />
            // )
            <img
              src={URL.createObjectURL(file)}
              alt="pic"
              style={{ width: "50px", height: "50px" }}
            />
          )}
          <input type="file" accept="image/*" onChange={fileHandler} />
        </div>

        <label>{lang === "en" ? "Password" : "كلمة المرور"}</label>
        <input
          type="password"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          className="m-3 p-2 border rounded shadow-sm"
        />
        {formik.touched.password && formik.errors.password ? (
          <p className={styles.Error}>{formik.errors.password}</p>
        ) : null}

        <button
          className="btn btn-outline-info"
          type="submit"
          onClick={checkPhone}
        >
          {lang === "en" ? "Sign Up" : "تسجيل"}
        </button>
      </form>
    </div>
  );
};

export default SignUp;
