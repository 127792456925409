import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TOGGLE_BTN,
  DELETE_OFFER,
  ALL_OFFERS,
} from "../../../redux/types/types";
// import { AiFillDelete } from "react-icons/ai";
import OfferService from "../../../httpServices/offers.services";
import "./ReceiveOffers.scss";
const ReceiveOffers = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const { offers } = useSelector((state) => state.offers);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (offers.length === 0) {
      const fetchOffers = () => {
        OfferService.getOffers("receiveOffer")
          .then((data) => {
            if (data) {
              dispatch({ type: ALL_OFFERS, payload: data.data });
            }
          })
          .catch((error) => {
            const message = error.response.data.message_en;
            setError(message);
          });
      };
      fetchOffers();
    }
  }, [offers, dispatch]);

  const toggleState = (offer, status) => {
    OfferService.updateState(`receiveOffer/${offer._id}`, status).then(
      (res) => {}
    );
    dispatch({ type: TOGGLE_BTN, payload: offer });
  };

  const deleteOffer = (id) => {
    OfferService.deleteOffer(`receiveOffer/${id}`).then((_) => {
      dispatch({ type: DELETE_OFFER, payload: id });
    });
  };

  // <button>{lang === "en" ? "Ok" : "false"}</button>
  const theOffers = offers.map((offer) => {
    return (
      <div
        key={offer._id}
        className={` rec_offers_page  bg-white m-2 p-3 d-flex flex-column justify-content-center align-items-center border`}
      >
        <h4>
          {lang === "en"
            ? `Title: ${offer.offerId.title_en}`
            : `العنوان: ${offer.offerId.title_ar}`}
        </h4>
        <h5>
          {lang === "en"
            ? `Name: ${offer.userId?.fullName_en}`
            : `الإسم: ${offer.userId?.fullName_ar}`}
        </h5>
        <h6>
          {lang === "en" ? `Phone: ` : `رقم الجوال: `} {offer.userId?.phone}
        </h6>
        <h6>
          {lang === "en" ? `Email: ` : `البريد الإلكتروني: `}{" "}
          {offer.userId?.email}
        </h6>
        <p>
          {lang === "en"
            ? `Description: ${offer.offerId?.description_en}`
            : `الوصف: ${offer.offerId?.description_ar}`}{" "}
          {offer.des}
        </p>
        <h6>
          {lang === "en" ? `Start: ` : `البداية: `} {offer.start}
        </h6>
        <h6>
          {lang === "en" ? `End: ` : `النهاية: `} {offer.end}
        </h6> <h6>
          {lang === "en" ? `Type of reservation: ` : `نوع الحجز: `} {offer.typeRec}
        </h6>
        <div className="d-flex w-100 justify-content-around">
          {offer.status ? (
            <button
              className="btn btn-success"
              onClick={() => toggleState(offer, false)}
            >
              {lang === "en" ? "Accepted" : "تمت الموافقة"}
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => toggleState(offer, true)}
            >
              {lang === "en" ? "Pending" : "معلق"}
            </button>
          )}
          <button
            className="btn btn-danger"
            onClick={() => deleteOffer(offer._id)}
          >
            {lang === "en" ? "Cancel" : "إلغاء"}
          </button>
        </div>
      </div>
    );
  });

  return (
    <div className="rec_offers_page">
      <div className="px-3 mt-2">{error ? <h1>{error}</h1> : theOffers}</div>
    </div>
  );
};

export default ReceiveOffers;
