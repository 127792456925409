import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const ReceverService = {
  takeOffer: async function (endPoint,id, reqsData) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}/${id}`,
      reqsData
    );
    return data;
  },
  takeService: async function (endPoint,id, reqsData) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}/${id}`,
      reqsData
    );
    return data;
  },
};

export default ReceverService;
