import styles from "./UsersPage.module.css";
import { AiOutlineSearch, AiFillDelete } from "react-icons/ai";
import UserService from "../../../httpServices/user.service";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALL_USERS } from "../../../redux/types/types";
import { useState } from "react";
const UsersPage = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [fetchedUsers, setFetchedUsers] = useState();
  const [usersCopy, setUsersCopy] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const deleteAll = () => {
    if (true) {
      console.log(users);
      const all = fetchedUsers.map((user) => user._id);
      UserService.deleteAll("user", { ids: all })
        .then((res) => {
          dispatch({ type: ALL_USERS, payload: res.users });
          setFetchedUsers([]);
          setAllSelected(!allSelected);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    UserService.getAllUsers(`user`).then((res) => {
      if (res) {
        dispatch({ type: ALL_USERS, payload: res.users });

        setFetchedUsers(res.users);
      }
    });
  }, [dispatch, users]);

  const lang = localStorage.getItem("lang");

  const selectHandler = (id, e) => {
    if (e.target.checked) {
      setSelectedUsers((prev) => [...prev, id]);
    } else {
      setSelectedUsers((prev) => [...prev].filter((data) => data !== id));
    }
    console.log(selectedUsers);
  };
  const deleteSelected = () => {
    UserService.deleteAll("user", { ids: selectedUsers, option: "user" })
      .then((res) => {
        dispatch({ type: ALL_USERS, payload: res.users });
        setFetchedUsers(res.users);
        setSelectedUsers([]);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
    let filteredData = fetchedUsers.filter((usr) =>
      usr.fullName_en.includes(e.target.value)
    );
    setUsersCopy(filteredData);
  };
  return (
    <div className="container-fluid">
      <h1 className="m-5">{lang === "en" ? "Users" : "المستخدمين"}</h1>
      {fetchedUsers && fetchedUsers.length > 0 ? (
        <div className={`m-5  ${styles.Container}`}>
          <div className="d-flex justify-content-between">
            <span className={styles.Text}>
              <input
                type="checkbox"
                checked={allSelected}
                onChange={() => setAllSelected(!allSelected)}
                className={styles.CheckBox}
              />
              {lang === "en" ? `Select All` : `تحديد الكل`}
              {allSelected || selectedUsers.length > 0 ? (
                <button
                  className="btn btn-danger mx-5"
                  onClick={() => {
                    deleteAll();
                  }}
                >
                  {" "}
                  delete all{" "}
                </button>
              ) : null}
              {selectedUsers.length > 0 ? (
                <button
                  className="btn btn-danger mx-5"
                  onClick={deleteSelected}
                >
                  Delete Selected
                </button>
              ) : null}
            </span>
            <span className={styles.Search}>
              <AiOutlineSearch />
              <input
                type="search"
                // value={search}
                onChange={(e) => searchHandler(e)}
                placeholder={lang === "en" ? `Search` : `بحث`}
                aria-label="Search"
                className={styles.SearchInput}
              />
            </span>
          </div>
          <div className={`m-4 row`}>
            <p className="col">{/* PlaceHolder */}</p>
            <h6 className={`col ${styles.Text}`}>
              {lang === "en" ? `Name` : `الإسم`}
            </h6>
            <h6 className={`col ${styles.Text}`}>
              {lang === "en" ? `Email` : `البريد الإلكتروني`}
            </h6>
            <h6 className={`col ${styles.Text}`}>
              {lang === "en" ? `Phone` : `رقم الجوال`}
            </h6>
            <p className="col">{/* PlaceHolder */}</p>
          </div>
          <div className={`${styles.TableWrapper}`}>
            {search != ""
              ? usersCopy.length > 0 &&
                usersCopy?.map((user) => (
                  <div className={`row`} key={user._id}>
                    <input
                      type="checkbox"
                      checked={allSelected ? true : false || null}
                      onChange={(e) => selectHandler(user._id, e)}
                      className={`col ${styles.CheckBox}`}
                    />
                    <p className={`col ${styles.Text}`}>
                      {lang === "en" ? user.fullName_en : user.fullName_ar}
                    </p>
                    <p className={`col ${styles.Text}`}>{user.email}</p>
                    <p className={`col ${styles.Text}`}>{user.phone}</p>
                    {
                      <span className={`col`}>
                        <AiFillDelete className={styles.Delete} />
                      </span>
                    }
                  </div>
                ))
              : fetchedUsers.map((user) => (
                  <div className={`row`} key={user._id}>
                    <input
                      type="checkbox"
                      checked={allSelected ? true : false || null}
                      onChange={(e) => selectHandler(user._id, e)}
                      className={`col ${styles.CheckBox}`}
                    />
                    <p className={`col ${styles.Text}`}>
                      {lang === "en" ? user.fullName_en : user.fullName_ar}
                    </p>
                    <p className={`col ${styles.Text}`}>{user.email}</p>
                    <p className={`col ${styles.Text}`}>{user.phone}</p>
                    {
                      <span className={`col`}>
                        <AiFillDelete className={styles.Delete} />
                      </span>
                    }
                  </div>
                ))}
          </div>
        </div>
      ) : (
        <h1>There is no users yet</h1>
      )}
    </div>
  );
};

export default UsersPage;
