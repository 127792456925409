import styles from "./SubAdmins.module.css";
import { FaTrashAlt } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AdminsServices from "../../../httpServices/Admins.services";
import { ALL_ADMINS } from "../../../redux/types/types";

const Admins = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch()
  const {admins} = useSelector((state) => state.admins)
  useEffect(() => {
    if (admins.length === 0) {
      const fetchedAdmins = () => {
        AdminsServices.getAllAdmins("user/manger/admin").then(data => {
          if (data) {
            dispatch({type:ALL_ADMINS, payload: data.users})
          }
        });
      }
      fetchedAdmins()
    }
  
  }, [dispatch, admins.length])
    return (
    <div className="container-fluid p-5">
      <h1>{lang === 'en'? 'Sub Admins' : 'المشرفين الفرعيين'}</h1>
      <div
        className={`${styles.HeaderContainer} d-flex justify-content-between my-5 py-3 px-5 bg-white`}
      >
        <span className={`${styles.Text} mt-2`}>
          <input type="checkbox" className={`${styles.Checkbox} mx-3`} />
          {lang === "en" ? "Select All" : "تحديد الكل"}
        </span>
        <Link
        to='/adminDashboard/addAdmin'
          className={`${styles.AddBtn} btn btn-primary`}
          style={{ color: "white" }}
        >
          {lang === "en" ? "Add Admin" : "إضافة مشرف"} <span>+</span>
        </Link>
      </div>
      <div className="row ">
      <span className="col"></span>
        <h6 className={`${styles.Text} col`}>#</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Name' : 'الإسم'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Email' : 'البريد الإلكتروني'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'User Name' : 'اسم المستخدم'}</h6>
        <span className="col"></span>
      </div>
      { admins.map((admin, index) => (
        <div className={`${lang === 'en' ? styles.RowLeftBorder : styles.RowRightBorder} row m-3 d-flex align-items-center`}>
        <input type='checkbox' className={`${styles.Checkbox} col`}/>
        <p className={` col`}>{index +1}</p>
        <p className={` col`}>{lang === 'en' ? admin.fullName_en : admin.fullName_ar}</p>
        <p className={` col`}>{admin.email}</p>
        <p className={` col`}>{lang === 'en' ? admin.userName_en : admin.userName_ar}</p>
        <div className={` col`}>
        <span style={{backgroundColor:'#fbdddd', width:'3vw', height:'3vh', borderRadius:'50%', textAlign:'center'}} className='m-1 py-1 px-2'>
        <FaTrashAlt style={{color:'#f18b8b', fontSize:'15px'}}/>
        </span>
        {/* <span style={{backgroundColor:'#e1e9fc', width:'2vw', height:'3vh', borderRadius:'50%', textAlign:'center'}}  className='m-1 py-1 px-2'>
        <MdOutlineModeEditOutline style={{color:'#92aef5', fontSize:'17px'}}/>
        </span> */}
        </div>
      </div>
      ))}
      
    </div>
  );
};

export default Admins;
