import React, { useEffect, useState } from "react";
import "./AdminMainPage.scss";
import { useParams, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import AdminNav from "../../components/landing/admin_nav/AdminNav";
import AdminPageIntro from "../../components/landing/admin_pageIntro/AdminPageIntro";
import AdminSection from "../../components/landing/admin_section/AdminSection";
import AddSectionModel from "../../components/landing/add_sections_model/AddSectionModel";
import {
  GET_ALL_PAGES,
  REMOVE_PAGE,
} from "../../../redux/reducers/pages_reducer/page_types";
import EditPage from "../../components/landing/edit_page_model/EditPageModel";
import PagesService from "../../../httpServices/pages.services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import icon from "../../../assets/folder_icon.svg";
import EditPageModel from "../../components/landing/edit_page_model/EditPageModel";
import AddPageModel from "../../components/landing/add_page_model/AddPageModel";
const AdminMainPage = () => {
  const lang = localStorage.getItem("lang");
  const { pages } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageId } = useParams();
  let adminCurrentPage = pages[0]
    ? pages.find(({ _id }) => _id === pageId)
    : {};
  const [modelAdd, setModelAdd] = useState(false);
  const [modelEdit, setModelEdit] = useState(false);
  const [newPage, setNewPage] = useState({});
  // remove page from pages
  const removePage = () => {
    PagesService.deletePage("page", pageId)
      .then((res) => {
        if (res) {
          dispatch({ type: REMOVE_PAGE, payload: adminCurrentPage });
          toast.success(res[`message_${lang}`]);
          setTimeout(() => {
            navigate("/adminDashboard");
          }, 3000);
        }
      })
      .catch((err) => {
        if (err.response.data) {
          err.response.data[`message_${lang}`]
            ? toast.error(err.response.data[`message_${lang}`])
            : toast.error(err.response.data);
        }
      });
  };
  useEffect(() => {
    // get all page if are not foun
    if (!pages[0]) {
      PagesService.getAllPages("page").then((data) => {
        dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
      });
    }
    // set details page in model
    PagesService.getPageById("page", pageId).then((data) => {
      adminCurrentPage = data.page;
      setNewPage({
        _id: data.page?._id,
        title_en: data.page.title_en,
        title_ar: data.page.title_ar,
        image: data.page?.image,
        description_en: data.page.description_en,
        description_ar: data.page.description_ar,
        keywordDescription: data.page.keywordDescription,
        keywords: data.page.keywords,
        sections: data.page?.sections,
        default: data.page.default,
        isActive: data.page.isActive,
      });
    });
  }, [dispatch, pageId]);
  return (
    <>
      {/* Old Code */}
      <div className={`admin-mainPage admin-mainPage_${lang}`}>
        <ToastContainer />
        <div className="inner_container">
          <div className="d-flex justify-content-end gap-4 mt-4 px-5">
            <button
              className="btn fw-bold"
              style={{ backgroundColor: "#FBDEDE", color: "#EF7C7C" }}
              onClick={() => removePage()}
            >
              {lang === "en" ? "Remove Page" : "حذف الصفحة"}
            </button>
          </div>
          <h2>{lang === "en" ? "Page's Sections" : "اقسام الصفحة"}</h2>

          <div className="sections_wrapper">
            {adminCurrentPage?.sections?.map((adminSection, index) => (
              <div className="section" key={index}>
                <AdminSection
                  adminSection={adminSection}
                  adminCurrentPage={adminCurrentPage}
                  lang={lang}
                />
              </div>
            ))}
            <button className="add_btn" onClick={() => setModelAdd(true)}>
              <div>
                <FaPlus />
                <h4>{lang === "en" ? "Add Section" : "أضف قسم"}</h4>
              </div>
            </button>
          </div>
          {modelAdd && (
            <AddSectionModel
              model={modelAdd}
              setModel={setModelAdd}
              adminCurrentPage={adminCurrentPage}
              lang={lang}
            />
          )}
        </div>
      </div>
      {/* Old Code */}

      {/* <section className={`admin_main_page admin_main_page_${lang}`}>
        <div className="inner_container">
          <h3 className="heading fw-bold">
          {lang === "en"
              ? `${adminCurrentPage.title_en} Page's Sections`
              : `اقسام صفحة ${adminCurrentPage.title_ar}`}
              </h3>
              
          <div className="pages_wrapper">
          {[...Array(5)].map((section) => (
            <div className="page" key={section._id}>
            <button
            className="delete_btn"
            onClick={() => removePage(section)}
            >
                  <MdOutlineDeleteOutline />
                  </button>
                  <button
                  className="edit_btn" onClick={() => showEditModel(page)}
                  >
                  <BiPencil />
                  </button>
                  <NavLink
                  className="route"
                  to={`/adminDashboard/${section._id}`}
                  >
                  <div className="route-container">
                  <img
                  src={icon}
                  alt={page[`title_${lang}`]}   alt="a"
                    />
                    {lang === "en" ? (
                      <h4>
                      {section?.title_en?.length > 18
                        ? `${section.title_en.slice(0, 18)}...`
                        : section.title_en}
                        </h4>
                        ) : (
                          <h4>
                          {section?.title_ar?.length > 20
                            ? `${section.title_ar.slice(0, 18)}...`
                            : section.title_ar}
                            عنوان القسم
                            </h4>
                            )}
                            </div>
                            </NavLink>
                            {{models.edit.activiton &&
                              section._id === models.edit.pageId && (
                    <EditPageModel
                      setModels={setModels}
                      lang={lang}
                      page={pages.find(
                        ({ _id }) => _id === section.edit.pageId
                        )}
                        />
                        )}}
                        </div>
                        ))}
                        <button
                        className="add_btn"
                        onClick={() => setModels({ ...models, add: true })}
            >
            <div>
                <FaPlus />
                <h4>{lang === "en" ? "Adding" : "إضافة"}</h4>
              </div>
            </button>
          </div>
        </div>
        {models.add && <AddPageModel setModels={setModels} />}
      </section> */}
    </>
  );
};

export default AdminMainPage;
