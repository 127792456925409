import React, { useEffect, useState } from "react";
import styles from "./Visitors.module.css";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker,
} from "react-simple-maps";
// import map from "./map.json";
import map from "./copy.map.json";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ReactTooltip from "react-tooltip";
import visitorsService from "../../../httpServices/visitors.services";

ChartJS.register(ArcElement, Tooltip, Legend);

const Visitors = () => {
  const lang = localStorage.getItem("lang");
  const [users, setUsers] = useState({});

  const geoUrl = map;
  const [content, setContent] = useState("");

  useEffect(() => {
    visitorsService.getAllVisitors("visitors").then((res) => {
      setUsers(res.visitors);
    });
  }, []);

  //to get vistores with country
  const visitors = {};
  for (let index = 0; index < users.length; index++) {
    let visitor = users[index];
    visitors[visitor.country_name]
      ? (visitors[visitor.country_name] = {
          count: visitors[visitor.country_name].count + 1,
          lat: visitor.latitude,
          country_ar: map.objects.world.geometries.map((geo) =>
            geo.properties.name_en === visitor.country_name
              ? geo.properties.name_ar
              : ""
          ),
          long: visitor.longitude,
        })
      : (visitors[visitor.country_name] = {
          count: 1,
          country_ar: map.objects.world.geometries.map((geo) =>
            geo.properties.name_en === visitor.country_name
              ? geo.properties.name_ar
              : ""
          ),
          lat: visitor.latitude,
          long: visitor.longitude,
        });
  }
  return (
    <div
      className={`${styles.Container} container-fluid`}
      style={{ paddingTop: "6 0px" }}
    >
      <div className={`${styles.Test} row`}>
        <div className={`${styles.Row1} col-lg-3 col-md-12`}>
          <div className={`${styles.Row2a} row`}>
            <div className="p-5">
              <h1>{users.length}</h1>
              <p style={{ color: "#91a68d" }}>
                {lang === "en" ? `Visitors` : `الزوار`}
              </p>
              <hr />
              {Object.keys(visitors).map((vis, index) => {
                return (
                  <div key={index}>
                    <span className="d-flex justify-content-between">
                      <span>
                        {lang === "en"
                          ? vis
                          : map.objects.world.geometries.map((geo) =>
                              geo.properties.name_en === vis
                                ? geo.properties.name_ar
                                : ""
                            )}
                        :
                        <span className="lead" style={{ fontWeight: "bold" }}>
                          {visitors[vis].count}
                        </span>
                      </span>
                    </span>
                    <hr />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div
          className={`${styles.MapContainer} col-lg-8 col-md-12 d-flex flex-column justify-content-center align-items-center`}
        >
          {/* <h2>{lang === "en" ? "Visitor statistics" : "إحصائيات الزوار"}</h2> */}
          <ReactTooltip className={styles.Tooltip}>{content}</ReactTooltip>
          <ComposableMap data-tip="" className={`${styles.Map}`}>
            <ZoomableGroup zoom={1} center={[20, 5]}>
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      fill="#D7E1FC"
                      key={geo.rsmKey}
                      geography={geo}
                      strokeWidth={0.1}
                      stroke="#D7E1FC"
                      onMouseEnter={() => {
                        // setContent(geo.properties.name_${lang});
                        setContent(`${geo.properties[`name_${lang}`]}`);
                      }}
                      onMouseLeave={() => {
                        setContent("");
                      }}
                      style={{
                        hover: {
                          fill: "#97A1BA",
                          outline: "none",
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
              {Object.keys(visitors).map((vis, index) => {
                return (
                  <Marker
                    key={index}
                    coordinates={[visitors[vis].long, visitors[vis].lat]}
                    style={{ textAlign: "center" }}
                  >
                    {visitors[vis].count >= 0 && visitors[vis].count <= 100 && (
                      <circle r={14} fill="#E31C1C" />
                    )}
                    {visitors[vis].count > 100 &&
                      visitors[vis].count <= 200 && (
                        <circle r={14} fill="#EBAC00" E31C1C />
                      )}
                    {visitors[vis].count > 200 &&
                      visitors[vis].count <= 300 && (
                        <circle r={14} fill="#2D62ED" />
                      )}
                    {visitors[vis].count >= 300 && (
                      <circle r={18} fill="#48B10D" />
                    )}
                    <text
                      className={`${styles.Pointer}`}
                      y="5"
                      textAnchor="middle"
                      fill="white"
                    >
                      {`${visitors[vis].count}`}
                    </text>
                  </Marker>
                );
              })}
            </ZoomableGroup>
          </ComposableMap>
        </div>
      </div>
    </div>
  );
};

export default Visitors;
