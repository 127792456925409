import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./AdminSection.scss";
import AddSubModel from "../add_sub_model/AddSubModel";
import { useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import { BiEdit, BiPencil } from "react-icons/bi";
import EditSection from "../edit_section/EditSection";
import icon from "../../../../assets/folder_icon.svg";
import {
  REMOVE_SECTION,
  REMOVE_SUB,
} from "../../../../redux/reducers/pages_reducer/page_types";
import EditSubModel from "../edit_sub/EditSubModel";
import PagesService from "../../../../httpServices/pages.services";
import SectionService from "../../../../httpServices/sections.services";
import { REMOVE_SEC_SUB_FROM_LANDING_PAGE } from "../../../../redux/reducers/resSections_reducer/resSections_types";
import { publicUrl } from "../../../../httpServices/httpServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDeleteOutline } from "react-icons/md";
const AdminSection = ({ adminSection, adminCurrentPage, lang }) => {
  const [modelEditSection, setModelEditSection] = useState(false);

  const dispatch = useDispatch();
  const deleteSection = () => {
    PagesService.deleteSectionFromPage(
      "section",
      adminCurrentPage._id,
      adminSection._id
    ).then((data) => {
      if (data.success) {
        toast.success(data[`message_${lang}`]);
        dispatch({
          type: REMOVE_SECTION,
          payload: { adminSection, adminCurrentPage },
        });
      }
    });
  };
  const showEditModel = () => {
    setModelEditSection((model) => !model);
  };
  return (
    // <section className="text-center section" style={{ marginTop: "100px" }}>
    //   <ToastContainer />

    //   <div className="d-flex justify-content-end w-75 mx-auto">
    //     <button
    //       className="btn text-white"
    //       style={{ backgroundColor: "#5BA0CC" }}
    //       onClick={() => setAddSubModel(true)}
    //     >
    //       {lang === "en" ? "Add Sub Sections" : "اضافة اقسام فرعية"}
    //     </button>
    //     <button
    //       className="px-2 py-1 rounded border-0  outline-0 mx-3 text-white"
    //       style={{
    //         backgroundColor: "#00B526",
    //       }}
    //       onClick={showEditModel}
    //     >
    //       {lang === "en" ? "Edit Section" : "تعديل القسم"}
    //     </button>
    //     <button
    //       className="outline-0 border-0 text-white rounded  px-2 py-1 ms-3"
    //       style={{ backgroundColor: "#FB8400" }}
    //       onClick={deleteSection}
    //     >
    //       {lang === "en" ? "Remove Section" : "حذف القسم"}
    //     </button>
    //   </div>
    //   <header className="mt-5">
    //     <h3 className="fw-semibold">{adminSection[`title_${lang}`]}</h3>
    //     <h4 className="w-50 mx-auto mt-4">
    //       {adminSection[`description_${lang}`]}
    //     </h4>
    //   </header>
    //   <img
    //     className="image-section"
    //     src={`${publicUrl}/${adminSection.image}`}
    //     alt={adminSection[`title_${lang}`]}
    //   />
    //   <div className="row m-0 p-0 w-75 mx-auto pt-5">
    //     {/* Sub Sections */}
    //     {adminSection?.subSections?.map((sub, index) => (
    //       <div
    //         className="col-lg-4 d-flex align-items-center justify-content-center  mt-5 position-relative"
    //         key={index}
    //       >
    //         <button className="edit-sub-btn" onClick={() => showModel(sub)}>
    //           <BiEdit />
    //         </button>
    //         <button className="delete-sub-btn" onClick={() => deleteSub(sub)}>
    //           <AiFillDelete />
    //         </button>
    //         <Link
    //           className="w-75"
    //           to={`/adminDashboard/${adminCurrentPage?._id}/${adminSection?._id}/${sub?._id}`}
    //           style={{
    //             color: "#000",
    //             textDecoration: "none",
    //             height: "50vh",
    //             overflowY: matchingTitles ? "scroll" : "auto",
    //           }}
    //         >
    //           <div
    //             className="h-100 w-100 rounded position-relative"
    //             style={{
    //               width: "80%",
    //               boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    //             }}
    //           >
    //             <img
    //               src={`${publicUrl}/${sub.image}`}
    //               alt="img"
    //               style={{
    //                 height: "17vh",
    //                 width: "50%",
    //                 borderRadius: "50%",
    //                 border: "7px solid rgb(198 192 192 / 90%)",
    //                 marginTop: "30px",
    //               }}
    //             />
    //             <div className="mt-5">
    //               <h5 className="fw-bold">{sub[`title_${lang}`]}</h5>
    //               <p
    //                 style={{
    //                   fontSize: "15px",
    //                   width: "90%",
    //                   margin: "0 auto",
    //                 }}
    //               >
    //                 {sub[`description_${lang}`]}
    //               </p>
    //               {matchingTitles && (
    //                 <div className="position-relative w-75 mx-auto py-4">
    //                   <h5 className="my-3">
    //                     {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
    //                   </h5>
    //                   {sub?.extras?.map((ex, index) => (
    //                     <div
    //                       key={index}
    //                       className="mx-auto mt-3"
    //                       style={{ border: "1px solid #888" }}
    //                     >
    //                       <p>
    //                         <span className="fw-bold">
    //                           {lang === "en"
    //                             ? "english key"
    //                             : "المفتاح الانجليزي"}
    //                         </span>
    //                         {lang === "en" ? ex?.key_en : ex?.key_ar}
    //                       </p>
    //                       <p>
    //                         <span className="fw-bold">
    //                           {lang === "en"
    //                             ? "english value "
    //                             : "القيمة العربية"}
    //                         </span>
    //                         {lang === "en" ? ex?.value_en : ex?.value_ar}
    //                       </p>
    //                     </div>
    //                   ))}
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         </Link>
    //         {modelEditSub.showing && (
    //           <EditSubModel
    //             lang={lang}
    //             adminCurrentPage={adminCurrentPage}
    //             adminSection={adminSection}
    //             selectedSub={adminSection.subSections.find(
    //               ({ _id }) => _id === modelEditSub.subId
    //             )}
    //             setModel={setModelEditSub}
    //           />
    //         )}
    //       </div>
    //     ))}
    //     {addSubModel && (
    //       <AddSubModel
    //         subModel={addSubModel}
    //         setSubModel={setAddSubModel}
    //         adminCurrentPage={adminCurrentPage}
    //         adminSection={adminSection}
    //         lang={lang}
    //       />
    //     )}
    //     {modelEditSection && (
    //       <EditSection
    //         model={modelEditSection}
    //         setModel={setModelEditSection}
    //         adminCurrentPage={adminCurrentPage}
    //         adminSection={adminSection}
    //         lang={lang}
    //       />
    //     )}
    //   </div>
    // </section>
    <>
      <button
        className="delete_btn"
        onClick={() => deleteSection(adminSection)}
      >
        <MdOutlineDeleteOutline />
      </button>
      <button className="edit_btn" onClick={showEditModel}>
        <BiPencil />
      </button>
      <NavLink
        className="route"
        to={`/adminDashboard/pages/${adminCurrentPage._id}/${adminSection._id}`}
      >
        <div className="route-container">
          <img src={icon} alt={adminSection[`title_${lang}`]} />
          {lang === "en" ? (
            <h4>
              {adminSection?.title_en?.length > 18
                ? `${adminSection.title_en.slice(0, 18)}...`
                : adminSection.title_en}
            </h4>
          ) : (
            <h4>
              {adminSection?.title_ar?.length > 18
                ? `${adminSection.title_ar.slice(0, 18)}...`
                : adminSection.title_ar}
            </h4>
          )}
        </div>
      </NavLink>
      {modelEditSection && (
        <EditSection
          model={modelEditSection}
          setModel={setModelEditSection}
          adminCurrentPage={adminCurrentPage}
          adminSection={adminSection}
          lang={lang}
        />
      )}
    </>
  );
};

export default AdminSection;
