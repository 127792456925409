import React, { useEffect, useState } from "react";
import { AiOutlineComment } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BlogServices from "../../../../httpServices/blogs.services";
import { DELETE_BLOG, SET_USER } from "../../../../redux/types/types";
import moment from "moment";
import UserService from "../../../../httpServices/user.service";
import { AiFillHeart } from "react-icons/ai";
import "./blogCard.css";
import { FiEdit } from "react-icons/fi";
import { publicUrl } from "../../../../httpServices/httpServices";

function BlogCard({ blog, deleteBlogHandler, user }) {
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  const dispatch = useDispatch();

  const CheckForAdmin = () => {
    if (
      user.role === "super admin" ||
      user.role == "admin" ||
      blog.user._id === user._id
    ) {
      return true;
    }
    return false;
  };

  const handleNavigate = (blog) => {
    if (user.role == "admin" || user.role == "super admin") {
      navigate(`/adminDashboard/blogs/${blog._id}`);
    } else {
      navigate(`/blogs/${blog._id}`);
    }
  };
  const lang = localStorage.getItem("lang");
  return (
    // <div className="blog col-md-3 ">
    //     <div
    //         id={blog._id}
    //         key={blog._id}
    //         className="m-3 p-2 card d-flex"
    //         style={{

    //             boxShadow: "7px 5px 5px darkgrey",
    //         }}
    //     >
    //         <div className="d-flex justify-content-end">
    //             <Link
    //                 to={`/adminDashboard/blogs/editblog/${blog._id}`}
    //                 style={{ textDecoration: "none", color: "black" }}
    //             >

    //             </Link>
    //             {/* {CheckForAdmin() && <TiDelete
    //                 style={{ fontSize: "26px", cursor: "pointer" }}
    //                 onClick={() => deleteBlogHandler(blog._id)}
    //             />} */}
    //         </div>
    //         <Link
    //             to={`/adminDashboard/blogs/${blog._id}`}
    //             style={{ textDecoration: "none" }}
    //             className="d-flex flex-column justify-content-center align-items-center "
    //         >
    //             <div className='imgWrapper'
    //             // className="card-body d-flex flex-column justify-content-end align-items-center"
    //             >
    //                 <img
    //                     src={blog.image}
    //                     className=" blogImage"
    //                     alt={blog.title}
    //                     style={{ height: "30vh" }}
    //                 />

    //                 <h5
    //                     className="card-title"
    //                     style={{ color: "black", height: "5vh" }}
    //                 >
    //                     {blog.title}
    //                 </h5>

    //                 <p
    //                     className="card-text"
    //                     style={{ color: "#404040", height: "5vh", }}
    //                 >
    //                     {blog.description.slice(0, 50) + '...'}
    //                 </p>

    //                 {
    //                     blog.startDate && <div>
    //                         <p>{moment(blog.startDate).format('YYYY-MM-DD')}</p>
    //                         <p>{moment(blog.endDate).format('YYYY-MM-DD')}</p>
    //                     </div>
    //                 }
    //             </div>
    //         </Link>
    //         <div
    //             className='clicks'
    //         >

    //             {/* div should goes here */}
    //             <div className="clicksWraper mx-2">
    //                 {CheckForAdmin() &&

    //                     <Link
    //                         to={`/adminDashboard/blogs/editblog/${blog._id}`}
    //                         style={{ textDecoration: "none", }}
    //                     >

    //                         <div className="editWrapper mx-2">

    //                             <FaEdit style={{ fontSize: "20px" }}

    //                                 className="edit" />
    //                         </div>

    //                     </Link>
    //                 }
    //                 {CheckForAdmin() &&
    //                     <Link className=""
    //                         to={`/adminDashboard/blogs/editblog/${blog._id}`}
    //                         style={{ textDecoration: "none", color: "black" }}
    //                     >

    //                         <div className="deleteWrapper mx-2">
    //                             <TiDelete />
    //                         </div>

    //                     </Link>
    //                 }

    //             </div>
    //             <div
    //                 className="btn-wrapper react"

    //             >

    //                 <button
    //                     className=""
    //                     style={{

    //                         borderRadius: "5px",

    //                     }}
    //                 >
    //                     <div className='likes mx-2' >
    //                         <span >{blog.likes.length}</span>
    //                         <AiFillHeart className='likes-love' />
    //                     </div>

    //                 </button>
    //                 <button
    //                     className=""
    //                     style={{

    //                         borderRadius: "5px",

    //                     }}
    //                 >
    //                     <div className="comment mx-2">

    //                         <AiOutlineComment className='comment-font' />
    //                         <span className='commentValue'>{blog.comments.length}</span>
    //                     </div>
    //                 </button>
    //             </div>
    //         </div>

    //     </div>

    //     {/* They Shou */}
    // </div>
    // {`/adminDashboard/blogs/${blog._id}
    <div
      className={`blogCard blogCard_${lang}`}
      style={{
        cursor: "pointer",
      }}
    >
      <div className="bg-white">
        <div className="imageWrapper" onClick={() => handleNavigate(blog)}>
          <img src={`${publicUrl}/${blog.image}`} alt="" />
        </div>
        <div className="blogCard__content my-3">
          <p className="title">{blog.title}</p>
          <p
            className="description lead"
            style={{
              wordWrap: "break-word",
              maxLines: 1,
              maxInlineSize: "50ch",
            }}
          >
            {blog.description?.slice(0, 20) + "..."}
          </p>

          {blog.blogType == "event" ? (
            <p>{moment(blog.startDate).format("LL")}</p>
          ) : (
            ""
          )}
        </div>
        <div className="blogReaction">
          {CheckForAdmin() && (
            <div className="blogReaction__action">
              <div className="iconWrapper mx-2 delete">
                <TiDelete
                  className="deleteFont"
                  onClick={() => deleteBlogHandler(blog._id)}
                />
              </div>
              <div className="iconWrapper edit mx-2">
                <FiEdit
                  className="editFont"
                  onClick={() =>
                    navigate(`/adminDashboard/blogs/editblog/${blog._id}`)
                  }
                />
              </div>
            </div>
          )}
          <div className="blogReaction__likes">
            <div className="iconWrapper mx-2 comment">
              <AiOutlineComment className="commentFont" />
              <span>{blog.comments.length}</span>
            </div>
            <div className="iconWrapper mx-2 like">
              <span>{blog.likes.length}</span>
              <AiFillHeart className="likeFont" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
