import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./SingleResSecPage.scss";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { BiPencil } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import icon from "../../../assets/folder_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import AddSubModel from "../../components/landing/add_sub_model/AddSubModel";
import SectionService from "../../../httpServices/sections.services";
import {
  GET_ALL_ERS_SECTIONS,
  REMOVE_SEC_SUB_FROM_LANDING_PAGE,
} from "../../../redux/reducers/resSections_reducer/resSections_types";
import {
  GET_ALL_PAGES,
  REMOVE_SUB,
} from "../../../redux/reducers/pages_reducer/page_types";
import { toast, ToastContainer } from "react-toastify";
import EditPageModel from "../../components/landing/edit_page_model/EditPageModel";
import EditSubModel from "../../components/landing/edit_sub/EditSubModel";
import PagesService from "../../../httpServices/pages.services";

const SingleResSecPage = () => {
  const { pageId, sectionId } = useParams();
  const { reservedSections, pages } = useSelector((state) => state);
  const lang = localStorage.getItem("lang");
  const [addSubModel, setAddSubModel] = useState(false);
  const adminCurrentPage = pages.find(({ _id }) => _id === pageId);
  const adminSection = reservedSections.find(({ _id }) => _id === sectionId);
  const [modelEditSub, setModelEditSub] = useState({
    showing: false,
    subId: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (!reservedSections[0]) {
      SectionService.getAllSections("section").then((res) => {
        if (res.sections) {
          dispatch({
            type: GET_ALL_ERS_SECTIONS,
            payload: res.sections.sections,
          });
        }
      });
    }
  
    // get all page if are not foun
    if (!pages[0]) {
      console.log("dasdasdsad");
      PagesService.getAllPages("page").then((data) => {
        dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
      });
    }
  }, [dispatch, pages]);
  const deleteSub = (sub) => {
    SectionService.deleteSubSectionFromSection(
      "subSection",
      sub._id,
      adminSection._id
    ).then((data) => {
      if (data.success) {
        dispatch({
          type: REMOVE_SUB,
          payload: { sub, adminCurrentPage, adminSection },
        });
        dispatch({
          type: REMOVE_SEC_SUB_FROM_LANDING_PAGE,
          payload: { adminSection, sub },
        });
        toast.success(data[`message_${lang}`]);
      }
    });
  };

  const showEditModel = (sub) => {
    setModelEditSub({
      ...modelEditSub,
      showing: true,
      subId: sub._id,
    });
  };
  console.log("adminSection :", adminSection);
  return (
    <section className={`single_section_page single_section_page_${lang}`}>
      <ToastContainer />
      <div className="inner_container">
        <h3 className="heading fw-bold">
          {lang === "en"
            ? "Section's Subs Sections"
            : `الاقسام الفرعية لدي القسم`}
        </h3>

        <div className="pages_wrapper">
          {adminSection?.subSections.map((sub) => (
            <div className="page" key={sub?._id}>
              <button className="delete_btn" onClick={() => deleteSub(sub)}>
                <MdOutlineDeleteOutline />
              </button>
              <button className="edit_btn" onClick={() => showEditModel(sub)}>
                <BiPencil />
              </button>
              <NavLink
                className="route" /* to={`/adminDashboard/${page._id}`} */
              >
                <div className="route-container">
                  <img src={icon} alt={sub[`title_${lang}`]} />
                  {lang === "en" ? (
                    <h4>
                      {sub?.title_en?.length > 18
                        ? `${sub.title_en.slice(0, 18)}...`
                        : sub.title_en}
                    </h4>
                  ) : (
                    <h4>
                      {sub?.title_ar?.length > 20
                        ? `${sub.title_ar.slice(0, 18)}...`
                        : sub.title_ar}
                    </h4>
                  )}
                </div>
              </NavLink>
              {modelEditSub.showing && sub._id === modelEditSub.subId && (
                <EditSubModel
                  setModelEditSub={setModelEditSub}
                  lang={lang}
                  adminCurrentPage={adminCurrentPage}
                  adminSection={adminSection}
                  selectedSub={adminSection.subSections.find(
                    ({ _id }) => _id === modelEditSub.subId
                  )}
                />
              )}
            </div>
          ))}
          <button className="add_btn" onClick={() => setAddSubModel(true)}>
            <div>
              <FaPlus />
              <h4>{lang === "en" ? "Add Sub Section" : "إضافة قسم فرعي"}</h4>
            </div>
          </button>
        </div>
      </div>
      {addSubModel && (
        <AddSubModel
          adminCurrentPage={adminCurrentPage}
          adminSection={adminSection}
          addSubModel={addSubModel}
          setAddSubModel={setAddSubModel}
          lang={lang}
        />
      )}
    </section>
  );
};

export default SingleResSecPage;
