import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import UserService from "../../../../httpServices/user.service";
import CommentService from "../../../../httpServices/comment.service";
import { ADD_REPLY } from "../../../../redux/types/types";
import BlogServices from "../../../../httpServices/blogs.services";
import "./reply.scss";
function AddReply({ commentId, blogId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reply, setReply] = useState("");
  const { blogs } = useSelector((state) => state.blogs);
  const [currentBlog, setCurrentBlog] = useState(null);
  const handleAddReply = async (e) => {
    e.preventDefault();
    // calling add comment
    const value = await UserService.userProfile(`user/me`);
    if (value) {
      CommentService.addReply(`comment/reply/${commentId}`, {
        user: value.user._id,
        blog: blogId,
        title: reply,
      }).then((value) => {
        setReply("");
        // dispatching the reply
        dispatch({
          type: ADD_REPLY,
          payload: { ...value.reply, comment: commentId },
        });

        // navigate(`/adminDashboard/blogs/${blogId}`)
      });
    }
  };
  const lang = localStorage.getItem("lang");
  // useEffect(() => {
  //     if (blogs[0]) {
  //         let res = (blogs.find(blog => blog._id == blogId))
  //         console.log('hello whati is data; ', res)
  //         res = { ...res, comments: [...res.comments].filter(comment => comment._id == commentId) }
  //         setCurrentBlog((prev) => res)
  //     }
  //     else {
  //         // making request to the backEnd
  //         BlogServices.getAllServices('blog').then(value => {
  //             let res = (value.blogs.find(blog => blog._id == blogId))
  //             res = { ...res, comments: [...res.comments].filter(comment => comment._id == commentId) }
  //             console.log('commentID', commentId)
  //             setCurrentBlog((prev) => res)

  //             console.log(res)

  //         })

  //     }

  // }, [blogId, blogs])

  return (
    // <div className="container">
    //     <div className="row" style={{ padding: '0px -16px !important' }}>
    //         <div className="col-xs-8 mx-auto my-5"
    //             style={{
    //                 background: '#f1f1f1', borderRadius: '10px',
    //                 textAlign: 'left',
    //                 paddingBottom: '50px'
    //             }}>
    //             {
    //                 currentBlog && (
    //                     <div className="card "  >
    //                         <div className="card-img  " style={{ width: '200px', height: '300px' }}>
    //                             <img src={currentBlog.image} alt="Blog" className='' style={{ width: '100%', objectFit: 'cover' }} />
    //                         </div>
    //                         <div className='card-body' >
    //                             <p className="lead card-text">{currentBlog.title}</p>
    //                             <p className='lead'> {currentBlog.description} </p>
    //                         </div>

    //                         <div className="replys d-flex flex-column m-3">
    //                             <h5>Reply</h5>
    //                             {/* the loop for getting all the comments  */}
    //                             {currentBlog.comments.length > 0 && currentBlog.comments.map((comment, index) => {
    //                                 return (
    //                                     <div className="comment-wrapper d-flex flex-column justify-content-between " key={index}>
    //                                         <div className="user-info d-flex justify-content-between">

    //                                             <div className="info-wrapper">
    //                                                 <img src={comment.user?.image || 'https://via.placeholder.com/150'} alt="userProfile" style={{ height: '30px', width: '30px', borderRadius: '50%' }} />
    //                                                 {comment.user && <p className='lead '>{comment.user.fullName_en}</p>}
    //                                             </div>

    //                                         </div>
    //                                         <p className=' float-right' style={{ fontSize: '30px', fontWeight: 'bold' }}>{comment.title}</p>
    //                                         <div className="btn-wrapper d-flex justify-content-start">

    //                                         </div>
    //                                     </div>
    //                                 )
    //                             })}

    //                         </div>
    //                     </div>
    //                 )
    //             }
    //         </div>
    //     </div>
    // </div>
    <div className="form-size addReply">
      <form className="form-group d-flex form">
        <input
          type="text"
          placeholder="Enter Your Reply"
          className="form-control"
          value={reply}
          onChange={(e) => setReply(e.target.value)}
        />
        <button className="btn border-0 focus-0" onClick={handleAddReply}>
          {lang === "en" ? "Add" : "إضافة"}
        </button>
      </form>
    </div>
  );
}

{
  /*  */
}

export default AddReply;
