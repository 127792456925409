import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import PagesService from "../httpServices/pages.services";
import UserService from "../httpServices/user.service";
import { GET_ALL_PAGES } from "../redux/reducers/pages_reducer/page_types";

function PublicRoutes(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pages } = useSelector((state) => state);
  const [userProfile, setUser] = useState({});
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  let currentDefaultPage = pages.find((page) => page.default);
  useEffect(() => {
    if (token) {
      UserService.getUser(`user/me`)
        .then((value) => {
          setUser(value.user);
        })
        .catch((e) => {
          console.log("error: tomen:", e.message);
        });
      if (
        (!pages[0] && userProfile && userProfile.role == "admin") ||
        userProfile.role == "super admin"
      ) {
        PagesService.getAllPages(`page`).then((value) => {
          // dispatching pagges
          dispatch({ type: GET_ALL_PAGES, payload: value.pages.pages });
        });
      } else {
        if (!pages[0]) {
          PagesService.getAllPages(`page/user`).then((value) => {
            // dispatching pagges
            currentDefaultPage = value.pages.pages.find((page) => page.default);
            dispatch({ type: GET_ALL_PAGES, payload: value.pages.pages });
          });
        }
      }
    } else {
      if (!pages[0]) {
        PagesService.getAllPages(`page/user`).then((value) => {
          // dispatching pagges
          currentDefaultPage = value.pages.pages.find((page) => page.default);
          dispatch({ type: GET_ALL_PAGES, payload: value.pages.pages });
        });
      }
    }
  }, [pages, token, currentDefaultPage]);
  if (token) {
    if (userProfile) {
      if (userProfile.role == "admin" || userProfile.role == "super admin") {
        // const adminPath = window.location.href.includes('adminDashboard') ? window.location.pathname : '/adminDashboard'
        return <Navigate to={`/adminDashboard`} />;
      } else if (userProfile.role == "user" && currentDefaultPage) {
        return <Navigate to={`/${currentDefaultPage?._id}`} />;
      }
    }
  }
  if (!token && currentDefaultPage) {
    return <Navigate to={`/${currentDefaultPage?._id}`} />;
  }
}

export default PublicRoutes;
