import { AxiosInstance, baseUrl } from "../App";
const resTypesServices = {
  fetching: async () => {
    let { data } = await AxiosInstance.get(`${baseUrl}/typerecer`);
    if (data) {
      return data;
    }
  },
  adding: async (payload) => {
    let { data } = await AxiosInstance.post(`${baseUrl}/typerecer`, payload);
    return data;
  },
  deleting: async (payload) => {
    let { data } = await AxiosInstance.delete(
      `${baseUrl}/typerecer/${payload._id}`
    );
    return data;
  },
};
export default resTypesServices;
