// import offers from "../../../initialData/offers";
import {
  TOGGLE_BTN,
  DELETE_OFFER,
  ALL_OFFERS,
  ADD_OFFER,
} from "../../types/types";
const initialState = {
  offers: [],
};

const offersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ALL_OFFERS:
      return { ...state, offers: [...payload] };
    case ADD_OFFER:
      return { ...state, offers: [...state.offers, payload] };
    case TOGGLE_BTN:
      return {
        ...state,
        offers: [...state.offers].map((offer) =>
          offer._id == payload._id ? { ...offer, status: !offer.status } : offer
        ),
      };
    // return state.offers.map((offer) => (offer._id === payload.id ? {...offer , status :payload.data } : offer));
    case DELETE_OFFER:
      return {
        ...state,
        offers: [...state.offers].filter((offer) => offer._id !== payload),
      };

    default:
      return state;
  }
};
export default offersReducer;
