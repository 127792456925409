import { GET_SERVICES, DELETE_SERVICE } from "../../types/types";

const initialState = {
  services: [],
};

const servicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SERVICES:
      return { ...state, services: [...payload] };
    case DELETE_SERVICE:
      return {
        ...state,
        services: [...state.services].filter(
          (service) => service._id !== payload
        ),
      };

    default:
      return state;
  }
};

export default servicesReducer;
