import React from 'react'
import { BiUser } from 'react-icons/bi'
import { MdWavingHand } from 'react-icons/md'
import { BsCircleFill } from 'react-icons/bs'
import { SiVirustotal } from 'react-icons/si'
import icon from '../../../../assets/person.png'
import './FirstRow.scss'
const FirstRow = ({ user, lang, countUsers ,countVis}) => {
  return (
    <div className="first_row">
      <div className="first">
        <div>
          <div className="text">
            <h3>
              <span>{lang === 'en' ? 'Welcome Back' : 'أهلا بعودتك'}</span>
              <MdWavingHand />
            </h3>
            <h2>{user[`fullName_${lang}`]}</h2>
          </div>
          <div className= {`img img_${lang}`}>
            <img src={icon} alt="Image" />
          </div>
        </div>
      </div>
      <div className="last ">
        <div className="first_inner">
          <div className="visitors">
            <div>
              <div className="icon_container">
                <BiUser />
              </div>
            </div>
            <div>
              <div className="text_container">
                <h4>{lang === 'en' ? 'Visitors Number' : 'عدد الزوار'}</h4>
                <h4>{countVis}</h4>
                <p>
                  {lang === 'en'
                    ? 'last visitor date in last year'
                    : ' تاريخ اخر زائر بالعام الاخير'}
                </p>
              </div>
            </div>
          </div>
          <div className="users_total">
            <div>
              <div className="icon_container">
                <SiVirustotal />
              </div>
            </div>
            <div>
              <div className="text_container">
                <h4>
                  {lang === 'en'
                    ? 'Users Number Total'
                    : 'إجمالي عدد المستخدمين'}
                </h4>
                <h4>{countUsers }</h4>
                <p>
                  {lang === 'en'
                    ? 'last visitor date in last year'
                    : ' تاريخ اخر زائر بالعام الاخير'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="last_inner">
          {/* <div>
            <div className="notifications_and_seeing">
              <h4>{lang === "en" ? "Notifications" : "الاشعارات"}</h4>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noreferrer"
              >
                {lang === "en" ? "see all" : "مشاهدة الجميع"}
              </a>
            </div>
            <div className="notifications_wrapper">
              {[...Array(3)].map(() => (
                <div className="notification">
                  <div>
                    <BsCircleFill />
                    <img
                      src="https://tse1.mm.bing.net/th?id=OIP.LhVslxFERPDfoKYCm-3A_QFWC2&pid=Api&P=0"
                      alt="ma"
                    />
                    <div>
                      <h6 className="fw-bolder">
                        {lang === "en" ? "Mohamed Salah" : "محمد صلاح"}
                      </h6>
                      {lang === "en" ? (
                        <p>Lorem ipsum dolor sit amet consec</p>
                      ) : (
                        <span>لوريم لوريم لوريم لوريم</span>
                      )}
                    </div>
                  </div>
                  <span>{lang === "en" ? "2:00 am" : "2:00 صباحاً"}</span>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default FirstRow
