import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const SectionService = {
  getAllSections: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    return data;
  },
  getSectionById: async function (endPoint, id) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}/${id}`);
    return data;
  },
  raletedsubSectionWithSection: async function (
    endPoint,
    idSubSection,
    idSection
  ) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}/${idSection}/${idSubSection}`
    );
    return data;
  },
  updateSection: async function (endPoint, id, res) {
    
    const { data } = await AxiosInstance.put(
      `${baseUrl}/${endPoint}/${id}`,
      res
    );
    return data;
  },
  addNewSection :async function (
    endPoint,
    REQDATA
  ) {
    const { data } = await AxiosInstance.post(`${baseUrl}/${endPoint}`,REQDATA);
    return data;
  },
  DeleteSection :async function (
    endPoint,
    idSection
  ) {
    const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}/${idSection}`);
    return data;
  },

  deleteSubSectionFromSection: async function (
    endPoint,
    idSection,
    idSubSection
  ) {
    const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}/${idSubSection}/${idSection}`);
    return data;
  },

};

export default SectionService;
