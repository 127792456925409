import { Box } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { publicUrl } from "../../../httpServices/httpServices";
import "./UserSection.scss";
const UserSection = ({ userCurrentPage, userSection, lang, sectionIndex }) => {
  return (
    <section
      className={`landing_page_section ${
        userCurrentPage?.title_en !== "About Us" && "other_styles"
      } `}
    >
      <div className="sec_inner_head_a">
        <header className="section_header">
          <h1 className="fw-semibold">{userSection[`title_${lang}`]}</h1>
          <h2 className=" mt-4">
            {userCurrentPage?.title_en === "About Us" ? (
              <pre style={{ width: "max-content" }}>
                {userSection[`description_${lang}`]}
              </pre>
            ) : (
              <span>{userSection[`description_${lang}`]}</span>
            )}
          </h2>
        </header>
        {/* <ul className="m-0 p-0 subs_wrapper">
          {userSection.subSections.map(
            (sub) =>
              sub.isActive && (
                <div key={sub._id}>
                  <div>
                    <li>
                      <p
                        className="sub_description"
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {sub[`description_${lang}`]}
                      </p>
                    </li>

                    {sub.extras?.length > 0 && (
                      <>
                        <h4>
                          {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
                        </h4>
                        {sub.extras.map((item, index) => (
                          <div key={index}>
                            <h5>
                              {" "}
                              {item?.key_en ||
                                (item?.key_ar && item[`key_${lang}`])}{" "}
                            </h5>
                            <h5>
                              {" "}
                              {item?.value_en ||
                                (item?.value_ar && item[`value_${lang}`])}{" "}
                            </h5>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )
          )}
        </ul> */}
        <ul className="m-0 p-0 row mt-5">
          {userSection.subSections.map(
            (sub) =>
              sub.isActive && (
                <div
                  key={sub._id}
                  className="col-lg-4 col-md-6 col-sm-12 p-0 mt-4 pb-5 d-sm-block d-flex justify-content-center"
                >
                  <div
                    class="card rounded pb-4  text-center"
                    style={{
                      width: "75%",
                      height: "570px",
                      paddingTop: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "auto",
                        mx: "auto",
                        width: {
                          md: 0.75,
                          sm: 1,
                        },
                      }}
                    >
                      <img
                        src={`${publicUrl}/${sub.image}`}
                        alt={sub[`title_${lang}`]}
                        style={{
                          height: "300px",
                          width: "100%",
                          margin: "0 auto",
                        }}
                      />
                    </Box>
                    <div class="card-body">
                      <h5 class="card-title fw-bold">{sub[`title_${lang}`]}</h5>
                      <p class="card-text">{sub[`description_${lang}`]}</p>
                      <ul class="list-group list-group-flush m-0 p-0 w-100">
                        {sub?.extras?.length > 0 &&
                          sub?.extras.slice(0, 2).map((extraItem, idx) => (
                            <li key={idx} class="list-group-item  p-2">
                              <h6 className="m-0 fw-bold">
                                {extraItem[`key_${lang}`]}
                              </h6>
                              <p className="m-0">
                                {extraItem[`value_${lang}`]}
                              </p>
                            </li>
                          ))}
                      </ul>
                      <NavLink
                        className="read-more-link"
                        to={`/${userCurrentPage?._id}/${userSection?._id}/${sub?._id}`}
                      >
                        {lang === "en" ? "Read More" : "أقرأ المزيد"}
                      </NavLink>
                    </div>
                  </div>
                </div>
              )
          )}
        </ul>
        {sectionIndex === 1 && (
          <a
            className="book_link"
            onClick={() => {
              window.innerWidth <= 768
                ? window.scrollTo(
                    0,
                    document.querySelector(".book_form").offsetTop - 72
                  )
                : window.scrollTo(
                    0,
                    document.querySelector(".book_form").offsetTop - 60
                  );
            }}
          >
            {lang === "en" ? "Book now" : "احجز الآن"}
          </a>
        )}
      </div>
    </section>
  );
};

export default UserSection;
