import React from "react";
import { publicUrl } from "../../../httpServices/httpServices";
import "./BookForm.scss";
const BookForm = ({ userCurrentPage, lang }) => {
  return (
    <div className="book_form">
      <form>
        <h1>{lang === "en" ? "Book your appointment" : "احجز موعدك"}</h1>
        <label>{lang === "en" ? "Name" : "الاسم"}</label>
        <input
          type="text"
          placeholder={lang === "en" ? "Fullname" : "الاسم كاملاً"}
          name="name"
          //   value={name}
          //   onChange={(e) => setName(e.target.value)}
          required
        />
        <label>{lang === "en" ? "Email Address" : "البريد الالكتروني"}</label>
        <input
          type="text"
          placeholder={lang === "en" ? "Email Address" : "البريد الالكتروني"}
          name="email"
          //   value={email}
          //   onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label>{lang === "en" ? "Contact Number" : "رقم التواصل"}</label>
        <input
          type="text"
          placeholder={
            lang === "en" ? "Your number to contact you" : "رقمك لكي نتواصل معك"
          }
          name="phoneNumber"
          //   value={phoneNumber}
          //   onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />

        <label htmlFor="message">
          {lang === "en" ? "your message" : "رسالتك"}
        </label>
        <textarea
          rows={6}
          placeholder={
            lang === "en" ? "type your message here" : "أكتب رسالتك هنا"
          }
          id="message"
          name="message"
          required
          defaultValue={""}
        />
        <h5 className="price-insdieOffer">
          {/* {location.pathname === "/" ? "" : offers.price + " SAR"} */}
        </h5>
        <div className="d-flex justify-content-center">
          <button
            type="submit"
            id="submit"
            name="submit" /* onClick={onSubmit} */
          >
            {lang === "en" ? "Send" : "ارسال"}
          </button>
        </div>
      </form>
      {/* <img
        className="form_img  d-md-block  d-none"
       not using =>   src={location?.pathname === "/" ? "/22.png" : PF + offers.image}
      using =>  src={`${publicUrl}/${userCurrentPage?.image}`}
        alt=""
      /> */}
    </div>
  );
};

export default BookForm;
