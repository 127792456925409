import styles from "./ComplaintsPage.module.css";
import { FaTrashAlt } from "react-icons/fa";
import {useEffect, useState} from'react'
import { Link } from "react-router-dom";
import contactsServices from "../../../httpServices/contact.services";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_COMPLAINS, GET_COMPLAINS } from "../../../redux/types/types";

const ComplaintsPage = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch()
  const {complains} = useSelector((state) => state.complains)

  useEffect(() => {
    if (complains.length === 0) {
      const fetchedComplains = () => {
        contactsServices.getAllContacts(`contact/filter/query/?type=complain`).then((data) => {
          if (data) {
            dispatch({type:GET_COMPLAINS, payload: data.contacts})
          }
        })
      }
    fetchedComplains()
    }
    
  }, [complains.length, complains, dispatch])
  
  const deleteComplain = (id) => {
    contactsServices.deleteContacts(`contact/${id}`).then(data => {
      dispatch({type: DELETE_COMPLAINS, payload:id})
    })
  }
  console.log(complains)

  return (
    <div className="container-fluid p-5">
      <div
        className={`${styles.HeaderContainer} my-5 py-3 px-5 bg-white`}
      >
      <h1>{lang === 'en'? 'Complaints' : 'الشكاوي'}</h1>
      </div>
       
      <div className="row">
      <span className="col"></span>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Complain No.' : 'رقم الشكوى'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Name' : 'الإسم'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Phone' : 'رقم الجوال'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Email' : 'البريد الإلكتروني'}</h6>
        <span className="col"></span>
      </div>
     { complains.map((complain, index) => (
      <div key={complain._id} className={`${lang === 'en' ? styles.RowLeftBorder : styles.RowRightBorder} row m-3 d-flex align-items-center`}>
      <input type='checkbox' className={`${styles.Checkbox} col`}/>
      <p className={` col`}>{index +1}</p>
      <p className={` col`}>{complain.name}</p>
      <p className={` col`}>{complain.phone}</p>
      <p className={` col`}>{complain.email}</p>
      <Link to={`/adminDashboard/requests/complaints/${complain._id}`} className="btn btn-primary w-auto text-white">{lang === 'en' ? 'Open' : 'افتح'}</Link>
      <div className={` col`}>
      <span style={{backgroundColor:'#fbdddd', width:'3vw', height:'3vh', borderRadius:'50%', textAlign:'center'}} className='m-1 py-1 px-2'>
      <FaTrashAlt style={{color:'#f18b8b', fontSize:'15px', cursor:'pointer'}} onClick={()=>deleteComplain(complain._id)}/>
      </span>
      
      </div>
    </div>
     )) 
      }
      
    </div>

  );
};

export default ComplaintsPage;
