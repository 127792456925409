import { combineReducers } from "redux";
import pagesReducer from "../reducers/pages_reducer/pageReducer";
import loginValuesReducer from "../reducers/loginValues_reducer/loginValuesReducer";
import userReducer from "../reducers/user_reducer/userReducer";
import blogsReducer from "../reducers/blogs_reducer/blogsReducer";
import reservedSectionsReducer from "../reducers/resSections_reducer/reservedSectionsReducer";
import reservedSubsReducer from "../reducers/resSubs_reducer/reservedSubsReducer";
import offersReducer from "./offers_reducer/offersReducer";
import contactUsReducer from "./contact_reducer/contactUsReducer";
import servicesReducer from "./services_reducer/servicesReducer";
import enLangReducer from "./enLang_reducer/appLanguageReducer";
import adminsReducer from "./adminsReducer";
import contactReducer from "./contactReducer";
import resTypesReducer from "./resTypes_reducer/resTypesReducer";
const rootReducers = combineReducers({
  pages: pagesReducer,
  blogs: blogsReducer,
  user: userReducer,
  loginValues: loginValuesReducer,
  reservedSections: reservedSectionsReducer,
  reservedSubs: reservedSubsReducer,
  offers: offersReducer,
  contact: contactUsReducer,
  services: servicesReducer,
  currentLanguage: enLangReducer,
  admins: adminsReducer,
  complains: contactReducer,
  resTypes: resTypesReducer,
});
export default rootReducers;
