import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import RoutesPages from "./Router/Routes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import PagesService from "./httpServices/pages.services";
import { GET_ALL_PAGES } from "./redux/reducers/pages_reducer/page_types";
export const baseUrl = `https://drserafi.com:8080/profile/api/v1`;
// export const baseUrl = `http://localhost:5000/profile/api/v1`;
export const AxiosInstance = axios.create();
const token =localStorage.getItem("token") ;
if (token) {
  AxiosInstance.defaults.headers.common["Authorization"] = token;
  // console.log('axios interceptor working')
  // AxiosInstance.interceptors.request.use(config => {
  //     config.headers = {
  //         'Authurization': token
  //     }
  //     return config;
  // })
}

function App() {
  const { currentLanguage, pages } = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log(pages);
  // if (!pages[0]) {
  //   PagesService.getAllPages("page").then((data) => {
  //     dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
  //   });
  // }
  return (
    <BrowserRouter>
      <div
        className={`App d-flex flex-column ${
          currentLanguage === "en" ? "en_App" : "ar_App"
        }`}
      >
        <RoutesPages />
        {/* <div
          style={{
            position: "relative",
            bottom: "0",
            width: "100%",
            height: "50px",
            marginTop: "5px",
            textAlign: "center",
          }}
          className=" d-flex justify-content-center align-items-center"
        >
          <h6 style={{ fontSize: '20px' }}>
            Developed by{" "}
            <a
              href="https://www.sarri.sa/"
              style={{ textDecoration: "none", color: "black", fontWeight: 'bolder' }}
            >
              Sarri
            </a>
          </h6>
        </div> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
