import React from "react";
import "./SelectTag.scss";
const SelectTag = ({ selectedActivity, handleActivition, lang, label }) => {
  return (
    <div className="mt-2">
      <label className="fs-5">{label}</label>
      <select
        class="form-select"
        aria-label="Default select example"
        value={selectedActivity}
        onChange={handleActivition}
      >
        <option hidden selected>
          {lang === "en" ? "select activity" : "اختار التنشيط"}
        </option>
        <option value="active">{lang === "en" ? "active" : "فعال"}</option>
        <option value="not_active">
          {lang === "en" ? "not active" : "غير فعال"}
        </option>
      </select>
    </div>
  );
};

export default SelectTag;
