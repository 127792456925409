import { baseUrl } from "../App";
import { AxiosInstance } from "../App";

const BlogServices = {
    getAllServices: async function (endPoint) {

        const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`)
        if (data) {

            return data;
        }

    },
    addBlog: async function (endPoint, blogInfo) {

        const { data } = await AxiosInstance.post(`${baseUrl}/${endPoint}`, blogInfo)
        if (data)
            return data;


    },
    editBlog: async function (endPoint, blogInfo) {

        const { data } = await AxiosInstance.put(`${baseUrl}/${endPoint}`, blogInfo)
        if (data)
            return data;


    },
    getBlogById: async function (endPoint) {
        try {
            const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`)
            if (data) {
                return data;
            }
        } catch (e) {
        }
    },
    deleteBlog: async function (endPoint) {
        try {
            const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}`)
            if (data)
                return data
        } catch (e) {
        }
    },
    addLike: async function (endPoint, likeType) {
        try {
            const { data } = await AxiosInstance.put(`${baseUrl}/${endPoint}`, { likeType })
            if (data)
                return data
        } catch (e) {
        }
    }



}


export default BlogServices;