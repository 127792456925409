import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const subSectionsService = {
  getAllsubSections: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    return data;
  },
  getSubSectionById: async function (endPoint, id) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}/${id}`);
    return data;
  },
  updateSupSection: async function (endPoint, id, res) {
    delete res["_id"];
    const { data } = await AxiosInstance.put(
      `${baseUrl}/${endPoint}/${id}`,
      res
    );
    return data;
  },
  addNewSub: async function (endPoint, reqsData) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}`,
      reqsData
    );
    return data;
  },
  deleteSub: async function (endPoint, id) {
    try {
      const { data } = await AxiosInstance.delete(
        `${baseUrl}/${endPoint}/${id}`
      );
      return data;
    } catch (error) {
      console.log(error.message);
      return error;
    }
  },
};

export default subSectionsService;
