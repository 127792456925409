import {
  ADD_RESERVED_SUB_TO_SUBS,
  EDIT_RESERVED_SUB_SECTION,
  GET_ALL_RESERVED_SUB,
  REMOVE_RESERVED_SUB_SECTION,
} from "./resSubs_types";
const reservedSubs = [];
const reservedSubsReducer = (state = reservedSubs, { type, payload }) => {
  switch (type) {
    case GET_ALL_RESERVED_SUB:
      return [...state, ...payload];
    case REMOVE_RESERVED_SUB_SECTION:
      return state.filter((selectedSub) => selectedSub._id !== payload._id);
    case EDIT_RESERVED_SUB_SECTION:
      return state.map((oldSub) =>
        oldSub._id === payload.sub._id ? payload.selectedSub : oldSub
      );
    case ADD_RESERVED_SUB_TO_SUBS:
      return [...state, payload];
    default:
      return state;
  }
};

export default reservedSubsReducer;
