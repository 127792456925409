import { ALL_ADMINS, ALL_SUPER_ADMINS } from "../types/types";
const initialState = {
  superAdmins: [],
  admins: []
};
const adminsReducer = (state = initialState, { type, payload }) => {
  
    switch (type) {
      case ALL_SUPER_ADMINS:
        return {...state, superAdmins: [...payload]}

        case ALL_ADMINS:
          return {...state, admins: [...payload]}
      
      default:
        return state
    } 
};
export default adminsReducer;
