import React, { useState } from "react";
import InputText from "../../components/forms/Input_text/InputText";
import "./AddSectionForm.scss";
import { IoIosAddCircle } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import {
  ADD_NEW_SECTION,
  ADD_NEW_SECTION_WITH_EXTRA,
} from "../../../redux/reducers/pages_reducer/page_types";
import { useNavigate, useParams } from "react-router";
// import { ADD_NEW_RESERVED_SECTION } from "../../../redux/reducers/resSections_reducer/resSections_types";
import PagesService from "../../../httpServices/pages.services";
import { ADD_NEW_RESERVED_SECTION } from "../../../redux/reducers/resSections_reducer/resSections_types";
import UploadServices from "../../../httpServices/httpServices";
import { RiCloseFill } from "react-icons/ri";
import InputFile from "../../components/forms/input_file/InputFile";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiOutlineRollback } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

const AddSectionForm = ({ setModel, setForm }) => {
  const lang = localStorage.getItem("lang");
  const { pageId } = useParams();
  // const [errorMessage, setErrorMessage] = useState();
  // // const adminCurrentPage = pages.find(({ _id }) => _id === pageId);
  // const adminCurrentPage = pages.find(({ _id }) => _id === pageId);
  // const navigate = useNavigate();
  // const [newSection, setNewSection] = useState({
  //   title_en: "",
  //   title_ar: "",
  //   description_en: "",
  //   description_ar: "",
  //   image: "",
  //   keywordDescription: "",
  //   keywords: [],
  //   subSections: [],
  // });
  // const [sectionImage, setSectionImage] = useState({});
  // const [sectionKeyword, setSectionKeyword] = useState("");
  // const handleChange = (event) => {
  //   setNewSection({
  //     ...newSection,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // const keywordIsExisted = newSection.keywords.find(
  //   (item) => item === sectionKeyword
  // );

  // const sectionIsExisted = adminCurrentPage.sections.find(
  //   ({ title_en }) => title_en === newSection.title_en
  // );
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     title_en: newSection.title_en,
  //     title_ar: newSection.title_ar,
  //     description_en: newSection.description_en,
  //     description_ar: newSection.description_ar,
  //     image: newSection.image,
  //     keywordDescription: newSection.keywordDescription,
  //     keywords: newSection.keywords,
  //     subSections: newSection.subSections,
  //   };
  //   if (sectionImage) {
  //     const date = new FormData();
  //     date.append("image", sectionImage);
  //     const resImage = await UploadServices.uploadFile("upload", date);
  //     data.image = resImage.filename;
  //   }
  //   PagesService.addSectionWithPage("section", pageId, data)
  //     .then((data) => {
  //       if (data.section) {
  //         dispatch({
  //           type: ADD_NEW_SECTION,
  //           payload: { newSection: data.section, adminCurrentPage: data.page },
  //         });
  //         dispatch({
  //           type: ADD_NEW_RESERVED_SECTION,
  //           payload: data.section,
  //         });
  //         navigate(`/adminDashboard/pages/${data.page._id}`);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.data) {
  //         error.response.data[`message_${lang}`]
  //           ? setErrorMessage(error.response.data)
  //           : setErrorMessage(error.response.data[`message_${lang}`]);
  //       }
  //     });
  // };
  const [errorMessage, setErrorMessage] = useState();
  const [sectionImage, setSectionImage] = useState(null);
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      image: "",
      keywordDescription: "",
      keywords: [],
      subSections: [],
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image: Yup.string().required(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
    onSubmit: async () => {
      const data = {
        title_en: newSection.title_en,
        title_ar: newSection.title_ar,
        description_en: newSection.description_en,
        description_ar: newSection.description_ar,
        image: newSection.image,
        keywordDescription: newSection.keywordDescription,
        keywords: newSection.keywords,
        subSections: newSection.subSections,
      };
      if (sectionImage) {
        const date = new FormData();
        date.append("image", sectionImage);
        const resImage = await UploadServices.uploadFile("upload", date);
        data.image = resImage.filename;
      }
      PagesService.addSectionWithPage("section", pageId, data)
        .then((data) => {
          if (data.section) {
            dispatch({
              type: ADD_NEW_SECTION,
              payload: {
                newSection: data.section,
                adminCurrentPage: data.page,
              },
            });
            dispatch({
              type: ADD_NEW_RESERVED_SECTION,
              payload: data.section,
            });
            removeAddFormModel();
            toast.success(data[`message_${lang}`]);
          }
        })
        .catch((error) => toast.error(error.response.data.message_en));
    },
  });
  const {
    values: newSection,
    setValues: setNewSection,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  const keywordIsExisted = newSection.keywords?.find(
    (item) => item === keyword
  );
  const addNewKeyword = () => {
    if (!keywordIsExisted && keyword.length > 0) {
      setNewSection({
        ...newSection,
        keywords: [...newSection.keywords, keyword],
      });
      setKeyword("");
    }
  };
  const deleteKeyword = (item) => {
    setNewSection({
      ...newSection,
      keywords: newSection.keywords.filter((keyword) => keyword !== item),
    });
  };
  const uploadPageImg = (e) => {
    setSectionImage(e.target.files[0]);
    setNewSection({
      ...newSection,
      image: e.target.files[0].name,
    });
  };
  const removeUploadedImg = () => {
    setSectionImage(null);
    setNewSection({
      ...newSection,
      image: "",
    });
  };
  const removeAddFormModel = () => {
    setModel(false);
    setForm(false);
  };
  return (
    // <div className="add-section-form">
    //   <form onSubmit={handleSubmit}>
    //     {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
    //     <h4 className="text-center">
    //       {lang === "en" ? "Add New Section" : "اضافة قسم جديد"}
    //     </h4>

    //     <InputText
    //       label={lang === "en" ? "english title" : "اللقب الانجليزي"}
    //       placeholder={lang === "en" ? "english title" : "اللقب الانجليزي"}
    //       type="text"
    //       name="title_en"
    //       value={newSection.title_en}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "arabic title" : "اللقب العربي"}
    //       placeholder={lang === "en" ? "arabic title" : "اللقب العربي"}
    //       type="text"
    //       name="title_ar"
    //       value={newSection.title_ar}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "english description" : "الوصف الانجليزي"}
    //       placeholder={
    //         lang === "en" ? "english page description" : "وصف الصفحة الانجليزي"
    //       }
    //       type="text"
    //       name="description_en"
    //       value={newSection.description_en}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "arabic description" : "الوصف العربي"}
    //       placeholder={
    //         lang === "en" ? "arabic page description" : "وصف الصفحة العربي"
    //       }
    //       type="text"
    //       name="description_ar"
    //       value={newSection.description_ar}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={
    //         lang === "en" ? "arabic keyword description" : "وصف الكلمة الرئيسية"
    //       }
    //       placeholder={
    //         lang === "en" ? "arabic keyword description" : "وصف الكلمة الرئيسية"
    //       }
    //       type="text"
    //       name="keywordDescription"
    //       value={newSection.keywordDescription}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "image link" : "رابط الصورة"}
    //       placeholder={lang === "en" ? "image link" : "رابط الصورة"}
    //       type="file"
    //       name="image"
    //       handleChange={(e) => setSectionImage(e.target.files[0])}
    //     />
    //     <div className="mt-4">
    //       <label className="fs-5">
    //         {lang === "en" ? "keywords" : "الكلمات"}
    //       </label>
    //       <div className="d-flex align-items-center justify-content-between mt-2">
    //         <input
    //           type="text"
    //           style={{ width: "90%" }}
    //           className="fs-5 py-2 px-3 rounded border border-dark"
    //           placeholder="Section Keyword"
    //           value={sectionKeyword}
    //           onChange={(e) => setSectionKeyword(e.target.value)}
    //         />
    //         <div
    //           style={{ width: "10%" }}
    //           className="d-flex align-items-center justify-content-center "
    //         >
    //           <button
    //             className="add-button"
    //             type="button"
    //             onClick={addNewKeyword}
    //           >
    //             <IoIosAddCircle />
    //           </button>
    //         </div>
    //       </div>
    //       {newSection.keywords.length > 0 && (
    //         <div className="mt-1  h-auto border border-dark">
    //           {newSection.keywords.map((keyword, index) => (
    //             <span
    //               className="bg-info p-1 d-block w-75 mx-auto px-3 text-white mt-2 rounded position-relative"
    //               key={index}
    //             >
    //               {keyword}
    //               <button
    //                 className="cancel-model btn btn-danger position-absolite position-absolute pt-3 d-flex align-items-center justify-content-center"
    //                 style={{
    //                   height: "5px",
    //                   width: "5px",
    //                   top: "-10px",
    //                   right: "-10px",
    //                 }}
    //                 type="button"
    //                 onClick={() => deleteKeyword(keyword)}
    //               >
    //                 <p>🗙</p>
    //               </button>
    //             </span>
    //           ))}
    //         </div>
    //       )}
    //       <button
    //         className="mt-5 w-100 py-2 px-2 fw-bold  btn btn-secondary"
    //         type="submit"
    //       >
    //         {lang === "en" ? "Add Section" : "اضافة قسم"}
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <div className="add-section-form">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button
            className={`back_btn ${lang === "en" ? "me-2" : "ms-2"} `}
            onClick={() => {
              setForm(false);
            }}
          >
            <AiOutlineRollback
              style={{ transform: lang === "ar" && "rotateY(180deg)" }}
            />
          </button>
          <button className="cancel-model" onClick={removeAddFormModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={newSection.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={newSection.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={newSection.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={newSection.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />

        {sectionImage !== null ? (
          <div className="img-container">
            <img
              src={sectionImage && URL.createObjectURL(sectionImage)}
              alt="aa"
            />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={touched.image}
            handleChange={uploadPageImg}
          />
        )}
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={newSection.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border:
                  errors.keywords && touched?.keyword
                    ? "1px solid red"
                    : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onBlur={() => {
                formik.setTouched({ ...touched, keyword: true });
                if (newSection.keywords.length < 1) {
                  formik.setErrors({
                    ...errors,
                    keywords:
                      lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
                  });
                }
              }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && touched?.keyword && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {newSection.keywords?.length > 0 && (
            <Box
            component="div"
            sx={{
              height: "100px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: "#aac1fe",
                  transition: "0.5s all",
                  borderRadius: "15px",
                },

                " &::-webkit-scrollbar-thumb:hover": {
                  background: "#78a3e4",
                },
              }}
              className={` keywords_wrapper keywords_wrapper_${lang} `}
            >
              {newSection.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}

          <button
            className="mt-5 w-100 py-2 px-2 fw-bold  add_page_btn"
            type="submit"
          >
            {lang === "en" ? "Add Section" : "اضافة قسم"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSectionForm;
