import React, { useState } from "react";
import InputText from "../../forms/Input_text/InputText";
import { useDispatch } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillDelete,
  AiFillMinusSquare,
} from "react-icons/ai";
import "./EditReservedSubModel.scss";
import { EDIT_RESERVED_SUB_SECTION } from "../../../../redux/reducers/resSubs_reducer/resSubs_types";
import { EDIT_EXISTED_RESERVED_SUB } from "../../../../redux/reducers/pages_reducer/page_types";
import { BsPlusSquareFill } from "react-icons/bs";
import subSectionsService from "../../../../httpServices/subSection.services";
import UploadServices, {
  publicUrl,
} from "../../../../httpServices/httpServices";
import { useFormik } from "formik";
import InputFile from "../../forms/input_file/InputFile";
import { RiCloseFill } from "react-icons/ri";
import * as Yup from "yup";
import SelectTag from "../../forms/select_tag/SelectTag";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
const EditReservedSubModel = ({ sub, setModels, lang }) => {
  // const dispatch = useDispatch();
  // const [selectedSub, setNewSub] = useState({
  //   title_en: sub?.title_en,
  //   title_ar: sub?.title_ar,
  //   description_en: sub?.description_en,
  //   description_ar: sub?.description_ar,
  //   image: sub?.image,
  //   keywords: sub?.keywords,
  //   keywordDescription: sub?.keywordDescription,
  //   extras: sub?.extras,
  // });
  // const [keyword, setKeyword] = useState("");
  // const handleChange = (event) => {
  //   setNewSub({
  //     ...selectedSub,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // const keywordIsExisted = selectedSub?.keywords?.find((item) => item === keyword);

  // const addKeyword = () => {
  //   if (!keywordIsExisted && keyword.length > 0) {
  //     setNewSub({
  //       ...selectedSub,
  //       keywords: [...selectedSub.keywords, keyword],
  //     });
  //     setKeyword("");
  //   }
  // };
  // const deleteKeyword = (parameter) => {
  //   setNewSub(
  //     Object.assign({}, selectedSub, {
  //       keywords: selectedSub.keywords.filter((key) => key !== parameter),
  //     })
  //   );
  // };
  // const deleteExtraItem = (selectedItem) => {
  //   setNewSub({
  //     ...selectedSub,
  //     extras: selectedSub.extras.filter(
  //       ({ key_en }) => key_en !== selectedItem.key_en
  //     ),
  //   });
  // };
  // const [modelEditExtraItem, setEditModelExtraItem] = useState({
  //   showing: false,
  //   selectItem: "",
  // });
  // const [errorMessage, setErrorMessage] = useState("");
  // const openModel = (item) => {
  //   setEditModelExtraItem((model) => ({
  //     ...model,
  //     showing: true,
  //     selectItem: item.key_en,
  //   }));
  // };
  // const [editResSubImage, setEditResSubImage] = useState({});

  // const [editItem, setEditItem] = useState({
  //   key_en: "",
  //   value_en: "",
  //   key_ar: "",
  //   value_ar: "",
  // });
  // const handleEditItem = (e) => {
  //   setEditItem((item) => ({
  //     ...item,
  //     [e.target.name]: e.target.value,
  //   }));
  // };
  // const [newExtraItem, setNewExtraItem] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });

  // const [formAdd, setFormAdd] = useState(false);
  // const handleChangeNewExtra = (e) => {
  //   setNewExtraItem({
  //     ...newExtraItem,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const addNewExtraItem = () => {
  //   setNewSub({
  //     ...selectedSub,
  //     extra: selectedSub?.extras && [...selectedSub?.extras, newExtraItem],
  //   });
  //   setNewExtraItem({
  //     key_en: "",
  //     value_en: "",
  //     key_ar: "",
  //     value_ar: "",
  //   });
  // };
  // const changeSectionData = async() => {
  //   if (editResSubImage) {
  //     const date = new FormData();
  //     date.append("image", editResSubImage);
  //     const resImage = await UploadServices.uploadFile("upload", date);
  //     selectedSub.image = resImage.filename;
  //   }
  //   subSectionsService
  //     .updateSupSection("subSection", sub._id, selectedSub)
  //     .then((data) => {
  //       if (data.subsub) {
  //         dispatch({
  //           type: EDIT_RESERVED_SUB_SECTION,
  //           payload: { sub, selectedSub: data.subsub },
  //         });
  //         dispatch({
  //           type: EDIT_EXISTED_RESERVED_SUB,
  //           payload: { sub, selectedSub: data.subsub },
  //         });
  //         setModel({ ...model, boolean: false });
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.data) {
  //         if (error.response.data["message_" + lang]) {
  //           setErrorMessage(error.response.data["message_" + lang]);
  //         } else {
  //           setErrorMessage(error.response.data);
  //         }
  //       }
  //     });

  //   // setModel({ ...model, boolean: false });
  // };
  // const [extraFields, setExtraFields] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });
  // const handleChangeExtraFields = (e) => {
  //   setExtraFields({
  //     ...extraFields,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // return (
  //   <div className="edit-reserved-sub-model">
  //     <form
  //       style={{
  //         height: "80vh",
  //         overflowY: "scroll",
  //       }}
  //     >
  //       {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
  //       <h4 className="text-center">
  //         {lang === "en" ? "Edit Sub Section" : "تعديل القسم الفرعي"}
  //       </h4>
  //       <button
  //         className="cancel-model btn btn-danger"
  //         type="button"
  //         onClick={() => setModel({ ...model, boolean: false })}
  //       >
  //         <span>🗙</span>
  //       </button>

  //       <InputText
  //         label={lang === "en" ? "title" : "اللقب"}
  //         value={selectedSub[`title_${lang}`]}
  //         handleChange={(event) => {
  //           setNewSub({
  //             ...selectedSub,
  //             [`title_${lang}`]: event.target.value,
  //           });
  //         }}
  //       />
  //       <InputText
  //         label={
  //           lang === "en" ? "sub sub image link" : "رابط صورة القسم الفرعي"
  //         }
  //         name="image"
  //         type="file"
  //         handleChange={(e) => setEditResSubImage(e.target.files[0])}
  //       />
  //       <InputText
  //         label="description"
  //         value={selectedSub[`description_${lang}`]}
  //         handleChange={(event) => {
  //           setNewSub({
  //             ...selectedSub,
  //             [`description_${lang}`]: event.target.value,
  //           });
  //         }}
  //       />
  //       <InputText
  //         label="keyword description"
  //         name="keywordDescription"
  //         value={selectedSub.keywordDescription}
  //         handleChange={handleChange}
  //       />
  //       <div className="row mt-1">
  //         <div className="col-10">
  //           <InputText
  //             label="keyword"
  //             name="keyword"
  //             value={keyword}
  //             handleChange={(e) => setKeyword(e.target.value)}
  //           />
  //         </div>
  //         <div className="col-2 d-flex align-items-end justify-content-center  pb-2">
  //           <button
  //             type="button"
  //             style={{ border: 0, outline: 0, backgroundColor: "transparent" }}
  //             onClick={addKeyword}
  //           >
  //             <IoIosAddCircle style={{ height: "28px", width: "28px" }} />
  //           </button>
  //         </div>

  //         {selectedSub.keywords?.length > 0 && (
  //           <div className="keywords">
  //             {selectedSub.keywords.map((keyword, idx) => (
  //               <p key={idx}>
  //                 {keyword}
  //                 <button type="button" onClick={() => deleteKeyword(keyword)}>
  //                   <AiFillCloseCircle />
  //                 </button>
  //               </p>
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //       {selectedSub?.extras?.length > 0 && (
  //         <>
  //           <h4 className="mt-2">
  //             {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}{" "}
  //           </h4>
  //           <button
  //             type="button"
  //             className="btn ms-auto"
  //             style={{ backgroundColor: "transparent" }}
  //             onClick={() => setFormAdd((form) => !form)}
  //           >
  //             {!formAdd ? (
  //               <BsPlusSquareFill style={{ height: "20px", width: "20px" }} />
  //             ) : (
  //               <AiFillMinusSquare style={{ height: "20px", width: "20px" }} />
  //             )}
  //           </button>
  //           {formAdd && (
  //             <div className="form">
  //               <InputText
  //                 label={lang === "en" ? "english key" : "المفتاح الانجليزي"}
  //                 name="key_en"
  //                 value={newExtraItem.key_en}
  //                 handleChange={handleChangeNewExtra}
  //               />
  //               <InputText
  //                 label={lang === "en" ? "english value" : "القيمة الانجليزية"}
  //                 name="value_en"
  //                 value={newExtraItem.value_en}
  //                 handleChange={handleChangeNewExtra}
  //               />
  //               <InputText
  //                 label={lang === "en" ? "arabic key" : "المفتاح العربي"}
  //                 name="key_ar"
  //                 value={newExtraItem.key_ar}
  //                 handleChange={handleChangeNewExtra}
  //               />
  //               <InputText
  //                 label={lang === "en" ? "arabic value" : "القيمة العربية"}
  //                 name="value_ar"
  //                 value={newExtraItem.value_ar}
  //                 handleChange={handleChangeNewExtra}
  //               />

  //               <button
  //                 className="btn bg-info text-white mx-auto d-block w-50 my-4"
  //                 type="button"
  //                 onClick={addNewExtraItem}
  //               >
  //                 {lang === "en" ? "add items" : "اضف عناصر"}
  //               </button>
  //             </div>
  //           )}
  //           {selectedSub.extras.map((item, idx) => (
  //             <div
  //               key={idx}
  //               className="border border-dark position-relative mt-4 w-75 mx-auto"
  //             >
  //               <h4>{item[`key_${lang}`]}</h4>
  //               <h5>{item[`value_${lang}`]}</h5>
  //               <button
  //                 className="position-absolute btn text-white d-flex align-items-center justify-content-center"
  //                 type="button"
  //                 style={{
  //                   top: "-10px",
  //                   right: "-10px",
  //                   height: "30px",
  //                   width: "15px",
  //                   backgroundColor: "#DC3545",
  //                 }}
  //                 onClick={() => deleteExtraItem(item)}
  //               >
  //                 <span>X</span>
  //               </button>
  //               <button
  //                 className="position-absolute btn text-white d-flex align-items-center justify-content-center"
  //                 type="button"
  //                 style={{
  //                   top: "-10px",
  //                   right: "20px",
  //                   height: "30px",
  //                   width: "15px",
  //                   backgroundColor: "#00B526",
  //                 }}
  //                 onClick={() => openModel(item)}
  //               >
  //                 <span>&#10049;</span>
  //               </button>
  //               {modelEditExtraItem.showing &&
  //                 modelEditExtraItem.selectItem === item.key_en && (
  //                   <div
  //                     className="position-absolute top-0 start-0 bg-danger "
  //                     style={{ zIndex: 4 }}
  //                   >
  //                     <InputText
  //                       label={
  //                         lang === "en" ? "english key" : "المفتاح الانجليزي"
  //                       }
  //                       name={`key_${lang}`}
  //                       value={editItem[`key_${lang}`]}
  //                       handleChange={handleEditItem}
  //                     />
  //                     <InputText
  //                       label={
  //                         lang === "en" ? "english value" : "القيمة الانجليزية"
  //                       }
  //                       name={`value_${lang}`}
  //                       value={editItem[`value_${lang}`]}
  //                       handleChange={handleEditItem}
  //                     />
  //                     <button
  //                       type="button"
  //                       onClick={() => {
  //                         setNewSub({
  //                           ...selectedSub,
  //                           extra: selectedSub.extra.map((current) =>
  //                             current.key_en === item.key_en
  //                               ? editItem
  //                               : current
  //                           ),
  //                         });
  //                         setEditItem({
  //                           ...editItem,
  //                           key_en: "",
  //                           key_ar: "",
  //                           value_en: "",
  //                           value_ar: "",
  //                         });
  //                       }}
  //                     >
  //                       {lang === "en" ? "Upddate item" : "تحديث العنصر"}
  //                     </button>
  //                     <button
  //                       className="position-absolute btn text-white d-flex align-items-center justify-content-center"
  //                       type="button"
  //                       style={{
  //                         top: "-10px",
  //                         right: "-10px",
  //                         height: "30px",
  //                         width: "15px",
  //                         backgroundColor: "black",
  //                         zIndex: "10",
  //                       }}
  //                       onClick={() =>
  //                         setEditModelExtraItem((model) => !model.showing)
  //                       }
  //                     >
  //                       <span>X</span>
  //                     </button>
  //                   </div>
  //                 )}
  //             </div>
  //           ))}
  //         </>
  //       )}
  //       <h4 className="mt-3">
  //         {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
  //       </h4>
  //       <InputText
  //         label={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
  //         placeholder={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
  //         type="text"
  //         name="key_en"
  //         value={extraFields.key_en}
  //         handleChange={handleChangeExtraFields}
  //       />
  //       <InputText
  //         label={lang === "en" ? "english value" : "القيمة الانجليزية"}
  //         placeholder={lang === "en" ? "english value" : "القيمة الانجليزية"}
  //         type="text"
  //         name="value_en"
  //         value={extraFields.value_en}
  //         handleChange={handleChangeExtraFields}
  //       />
  //       <InputText
  //         label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
  //         placeholder={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
  //         type="text"
  //         name="key_ar"
  //         value={extraFields.key_ar}
  //         handleChange={handleChangeExtraFields}
  //       />
  //       <InputText
  //         label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
  //         placeholder={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
  //         type="text"
  //         name="value_ar"
  //         value={extraFields.value_ar}
  //         handleChange={handleChangeExtraFields}
  //       />
  //       <button
  //         type="button"
  //         className="btn w-100 bg-info text-white fw-bold mt-3"
  //         onClick={addNewExtraItem}
  //       >
  //         {lang === "en" ? "add item" : "اضف عنصر"}
  //       </button>
  //       {selectedSub?.extras?.map((item, index) => (
  //         <div
  //           key={index}
  //           className="position-relative border border-dark mt-4"
  //         >
  //           <h4>{item[`key_${lang}`]}</h4>
  //           <h6>{item[`value_${lang}`]}</h6>
  //           <button
  //             className="position-absolute btn text-white d-flex align-items-center justify-content-center"
  //             type="button"
  //             style={{
  //               top: "-10px",
  //               right: "-10px",
  //               height: "30px",
  //               width: "15px",
  //               backgroundColor: "#DC3545",
  //             }}
  //             onClick={() => deleteExtraItem(item)}
  //           >
  //             <span>X</span>
  //           </button>
  //           <button
  //             className="position-absolute btn text-white d-flex align-items-center justify-content-center"
  //             type="button"
  //             style={{
  //               top: "-10px",
  //               right: "20px",
  //               height: "30px",
  //               width: "15px",
  //               backgroundColor: "#00B526",
  //             }}
  //             onClick={() => openModel(item)}
  //           >
  //             <span>&#10049;</span>
  //           </button>
  //           {modelEditExtraItem.showing &&
  //             modelEditExtraItem.selectItem === item.key_en && (
  //               <div
  //                 className="position-absolute top-0 start-0 bg-danger "
  //                 style={{ zIndex: 4 }}
  //               >
  //                 <InputText
  //                   label={lang === "en" ? "english key" : "المفتاح الانجليزي"}
  //                   name={`key_${lang}`}
  //                   value={editItem[`key_${lang}`]}
  //                   handleChange={handleEditItem}
  //                 />
  //                 <InputText
  //                   label={
  //                     lang === "en" ? "english value" : "القيمة الانجليزية"
  //                   }
  //                   name={`value_${lang}`}
  //                   value={editItem[`value_${lang}`]}
  //                   handleChange={handleEditItem}
  //                 />
  //                 <button
  //                   type="button"
  //                   onClick={() => {
  //                     setNewSub({
  //                       ...selectedSub,
  //                       extras: selectedSub.extras.map((current) =>
  //                         current.key_en === item.key_en ? editItem : current
  //                       ),
  //                     });
  //                     setEditItem({
  //                       ...editItem,
  //                       key_en: "",
  //                       key_ar: "",
  //                       value_en: "",
  //                       value_ar: "",
  //                     });
  //                   }}
  //                 >
  //                   {lang === "en" ? "Upddate item" : "تحديث العنصر"}
  //                 </button>
  //                 <button
  //                   className="position-absolute btn text-white d-flex align-items-center justify-content-center"
  //                   type="button"
  //                   style={{
  //                     top: "-10px",
  //                     right: "-10px",
  //                     height: "30px",
  //                     width: "15px",
  //                     backgroundColor: "black",
  //                     zIndex: "10",
  //                   }}
  //                   onClick={() =>
  //                     setEditModelExtraItem((model) => !model.showing)
  //                   }
  //                 >
  //                   <span>X</span>
  //                 </button>
  //               </div>
  //             )}
  //         </div>
  //       ))}
  //       <div className="update-btn-container">
  //         <button type="button" onClick={changeSectionData}>
  //           {lang === "en" ? "Update" : "تحديث"}
  //         </button>
  //       </div>
  //     </form>
  //   </div>

  // );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      _id: sub._id,
      title_en: sub.title_en,
      title_ar: sub.title_ar,
      description_en: sub.description_en,
      description_ar: sub.description_ar,
      image: sub?.image,
      keywords: sub.keywords,
      keywordDescription: sub?.keywordDescription,
      extras: sub?.extras,
      isActive: sub?.isActive,
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image: Yup.string().required(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
    onSubmit: async () => {
      if (pageImage) {
        const date = new FormData();
        date.append("image", pageImage);
        const resImage = await UploadServices.uploadFile("upload", date);
        selectedSub.image = resImage.filename;
      }
      subSectionsService
        .updateSupSection("subSection", sub._id, selectedSub)
        .then((data) => {
          if (data.subsection) {
            dispatch({
              type: EDIT_RESERVED_SUB_SECTION,
              payload: { sub, selectedSub: data.subsection },
            });
            dispatch({
              type: EDIT_EXISTED_RESERVED_SUB,
              payload: { sub, selectedSub: data.subsection },
            });
            toast.success(data.message_en);
          }
        })
        .catch((error) => {
          if (error.response.data) {
            if (error.response.data["message_" + lang]) {
              setErrorMessage(error.response.data["message_" + lang]);
            } else {
              setErrorMessage(error.response.data);
            }
          }
        });

      removeEditSubModel();
    },
  });
  const {
    values: selectedSub,
    setValues: setSelectedSub,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
  } = formik;
  const [errorMessage, setErrorMessage] = useState("");
  const [pageImage, setPageImage] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [editResSubImage, setEditResSubImage] = useState({});

  const uploadPageImg = (e) => {
    setPageImage(e.target.files[0]);
    setSelectedSub({
      ...selectedSub,
      image: e.target.files[0].name,
    });
  };
  const addNewKeyword = () => {
    if (keyword.length > 0) {
      if (selectedSub.keywords.indexOf(keyword) === -1) {
        setSelectedSub({
          ...selectedSub,
          keywords: [...selectedSub.keywords, keyword],
        });
        setKeyword("");
      } else {
        formik.setErrors({
          ...errors,
          keywords:
            lang === "en"
              ? "this keyword is existed before"
              : "هذه الكلمة المفتاحية موجودة من قبل",
        });
      }
    }
  };
  const deleteKeyword = (item) => {
    setSelectedSub({
      ...selectedSub,
      keywords: selectedSub.keywords.filter((keyword) => keyword !== item),
    });
  };
  const removeUploadedImg = () => {
    setPageImage(null);
    setSelectedSub({
      ...selectedSub,
      image: "",
    });
  };
  const removeEditSubModel = () => {
    setModels((models) => ({
      ...models,
      edit: {
        activition: false,
        subId: "",
      },
    }));
  };
  const [extraFields, setExtraFields] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const [editItem, setEditItem] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const handleChangeExtraFields = (e) => {
    setExtraFields({
      ...extraFields,
      [e.target.name]: e.target.value,
    });
  };
  const valuesCompleted = Object.values(extraFields).every(
    (value) => value.length > 0
  );
  const itemExisted = selectedSub.extras.find(
    (item) =>
      item.key_en === extraFields?.key_en || item.key_en === editItem?.key_en
  );
  const addNewExtraItem = () => {
    if (valuesCompleted && itemExisted === undefined) {
      setSelectedSub({
        ...selectedSub,
        extras: [...selectedSub?.extras, extraFields],
      });
      setExtraFields({
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  const deleteExtraItem = (selectItem) => {
    setSelectedSub({
      ...selectedSub,
      extras: selectedSub.extras.filter(
        ({ key_en }) => key_en !== selectItem.key_en
      ),
    });
  };
  const [modelEditExtraItem, setEditModelExtraItem] = useState({
    showing: false,
    selectItem: "",
  });
  const openModel = (item) => {
    setEditModelExtraItem((model) => ({
      ...model,
      showing: true,
      selectItem: item.key_en,
    }));
  };
  const removeModel = () => {
    setEditModelExtraItem((model) => !model.showing);
    setEditItem({
      key_en: "",
      key_ar: "",
      value_en: "",
      value_ar: "",
    });
  };
  const handleEditItem = (e) => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.value,
    });
  };
  const changeExtrasItemData = (item) => {
    if (itemExisted === undefined) {
      setSelectedSub({
        ...selectedSub,
        extras: selectedSub.extras.map((current) =>
          current.key_en === item.key_en ? editItem : current
        ),
      });
      setEditItem({
        ...editItem,
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  const [selectedActivity, setSelectActivity] = useState(
    selectedSub.isActive ? "active" : "not_active"
  );
  const handleActivition = (e) => {
    const { value } = e.target;
    setSelectActivity(value);
    setSelectedSub({
      ...selectedSub,
      isActive: value === "active" ? true : false,
    });
  };
  console.log("error keywords :", errors.keywords);
  return (
    <div className="edit_res_sub_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeEditSubModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={selectedSub.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={selectedSub.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={selectedSub.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={selectedSub.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />
        <SelectTag
          lang={lang}
          label={lang === "en" ? "Activity" : "الفعالية"}
          selectedActivity={selectedActivity}
          activedValue={selectedSub.isActive}
          handleActivition={handleActivition}
        />
        {selectedSub.image || pageImage ? (
          <div className="img-container">
            <img
              src={
                !pageImage
                  ? `${publicUrl}/${selectedSub.image}`
                  : URL.createObjectURL(pageImage)
              }
              alt="aa"
            />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={pageImage === null}
            handleChange={uploadPageImg}
          />
        )}

        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={selectedSub.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              name="keyword"
              style={{
                width: "90%",
                border: errors.keywords ? "1px solid red" : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {selectedSub.keywords?.length > 0 && (
            <Box
            component="div"
            sx={{
              height: "100px",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "10px",
                borderRadius:
                  lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
              },

              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1",
                borderRadius:
                  lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
              },

              "&::-webkit-scrollbar-thumb": {
                background: "#aac1fe",
                transition: "0.5s all",
                borderRadius: "15px",
              },

              " &::-webkit-scrollbar-thumb:hover": {
                background: "#78a3e4",
              },
            }}
            className={` keywords_wrapper keywords_wrapper_${lang} `}>
              {selectedSub.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}
          <h5 className="mt-4 fw-bold  text-center">
            {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
          </h5>
          <InputText
            label={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
            type="text"
            name="key_en"
            value={extraFields.key_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "english value" : "القيمة الانجليزية"}
            type="text"
            name="value_en"
            value={extraFields.value_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="key_ar"
            value={extraFields.key_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="value_ar"
            value={extraFields.value_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <button
            type="button"
            className="add_extras_item_btn"
            onClick={addNewExtraItem}
          >
            {lang === "en" ? "Add Item" : "اضف عنصر"}
          </button>
          {selectedSub.extras?.map((item, index) => (
            <div key={index} className="extras_item">
              {modelEditExtraItem.showing &&
              modelEditExtraItem.selectItem === item.key_en ? (
                <div className="edit_extra_item_model">
                  <InputText
                    label={
                      lang === "en" ? "english key" : "مفتاح اللغة العربية"
                    }
                    type="text"
                    name={`key_${lang}`}
                    value={lang === "en" ? editItem.key_en : editItem.key_ar}
                    placeholder={
                      lang === "en" ? "New english key" : "مفتاح عربي جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <InputText
                    label={lang === "en" ? "english value" : "القيمة العربية"}
                    type="text"
                    name={`value_${lang}`}
                    value={
                      lang === "en" ? editItem.value_en : editItem.value_ar
                    }
                    placeholder={
                      lang === "en"
                        ? "New english value"
                        : "قيمة اللغة الانجليزية جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <div className="edit-extras-item-model-btns">
                    <button
                      type="button"
                      onClick={() => changeExtrasItemData(item)}
                    >
                      <AiFillCheckCircle />
                    </button>
                    <button className="" type="button" onClick={removeModel}>
                      <AiFillCloseCircle />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h4>{item[`key_${lang}`]}</h4>
                  <h6>{item[`value_${lang}`]}</h6>
                  <button
                    className=" del-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => deleteExtraItem(item)}
                  >
                    <span>X</span>
                  </button>
                  <button
                    className=" edi-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => openModel(item)}
                  >
                    <span>&#10049;</span>
                  </button>
                </>
              )}
            </div>
          ))}
          <button
            className={`mt-5 w-100 py-2 px-2 fw-bold  add_page_btn ${
              Object.keys(formik.errors).length > 0 && "disabled_btn"
            }`}
            type="submit"
          >
            {lang === "en" ? "Update Sub Section" : "تحديث القسم الفرعي"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditReservedSubModel;
