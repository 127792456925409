// import pages from "../../../initialData/pages";

import {
  ADD_PAGE,
  ADD_NEW_SECTION,
  ADD_NEW_SUB,
  REMOVE_PAGE,
  EDIT_SECTION,
  EDIT_PAGE,
  EDIT_SUB,
  REMOVE_SECTION,
  REMOVE_SUB,
  EDIT_EXISTED_RESERVED_SECTION,
  EDIT_EXISTED_RESERVED_SUB,
  REMOVE_ALL_EXISTED_RESERVED_SECTIONS,
  REMOVE_ALL_EXISTED_RESERVED_SUBS,
  EDIT_ALL_EXISTED_SUBS_CHILDS,
  GET_ALL_PAGES,
  ADD_NEW_SUB_BY_FORM,
} from "./page_types";
const pages = [];
const pagesReducer = (state = pages, { type, payload }) => {
  // Adding
  switch (type) {
    case ADD_PAGE:
      return [...state, payload];
    case GET_ALL_PAGES:
      return [...state, ...payload];
    case ADD_NEW_SECTION:
      return state.map((page) => {
        return page._id === payload.adminCurrentPage._id
          ? {
              ...page,
              sections: [...page.sections, payload.newSection],
            }
          : page;
      });
    case ADD_NEW_SUB:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) =>
          section._id === payload.adminSection._id
            ? {
                ...section,
                subSections: [
                  ...section.subSections,
                  { ...payload.newSub, extras: [...payload.newSub.extras] },
                ],
              }
            : section
        ),
      }));
    case ADD_NEW_SUB_BY_FORM:
      // const matchingTitles = ["offer", "service", "team"]
      //   .map((title) => payload.adminSection.title_en.includes(title))
      //   .includes(true);
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) =>
          section._id === payload.adminSection._id
            ? // ? {
              //     ...section,
              //     subSections: matchingTitles
              //       ? [
              //           ...section.subSections,
              //           {
              //             ...payload.newSub,
              //             extras: [...payload.newSub.extras],
              //           },
              //         ]
              //       : [...section.subSections, payload.newSub],
              //   }
              {
                ...section,
                subSections: [
                  ...section.subSections,
                  { ...payload.newSub, extras: [...payload.newSub.extras] },
                ],
              }
            : section
        ),
      }));

    case EDIT_PAGE:
      return state.map((page) =>
        page._id === payload.page._id ? payload.selectedPage : page
      );
    case EDIT_SECTION:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) =>
          section._id === payload.adminSection._id
            ? {
                ...payload.newSection,
                subSection: payload.newSection.subSections.map((sub) =>
                  sub.extras?.length > 0
                    ? { ...sub, extras: [...sub.extras] }
                    : { ...sub, extras: [] }
                ),
              }
            : section
        ),
      }));
    case EDIT_SUB:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) =>
          section?._id === payload?.adminSection._id
            ? {
                ...section,
                subSections: section.subSections.map((subSection) =>
                  subSection?._id === payload.selectedSub._id
                    ? payload.newSub
                    : subSection
                ),
              }
            : section
        ),
      }));

    case EDIT_EXISTED_RESERVED_SECTION:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) =>
          section?._id === payload.selectedSection?._id
            ? payload.newSection
            : section
        ),
      }));
    case EDIT_EXISTED_RESERVED_SUB:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) => ({
          ...section,
          subSections: section.subSections.map((oldSub) =>
            oldSub._id === payload.sub._id
              ? payload.selectedSub
              : // ? {
                //     ...payload.selectedSub,
                //     extras: [...payload.selectedSub.extras],
                //   }
                oldSub
          ),
        })),
      }));

    /* Removing */
    case REMOVE_PAGE:
      return state.filter((page) => page._id !== payload._id);
    case REMOVE_SECTION:
      return state.map((page) =>
        page._id === payload.adminCurrentPage._id
          ? {
              ...page,
              sections: page.sections.filter(
                ({ _id }) => _id !== payload.adminSection._id
              ),
            }
          : page
      );
    case REMOVE_SUB:
      return state.map((page) =>
        page._id === payload.adminCurrentPage._id
          ? {
              ...page,
              sections: page.sections.map((section) =>
                section._id === payload.adminSection._id
                  ? {
                      ...section,
                      subSections: section.subSections.filter(
                        ({ _id }) => _id !== payload.sub._id
                      ),
                    }
                  : section
              ),
            }
          : page
      );
    case REMOVE_ALL_EXISTED_RESERVED_SECTIONS:
      return state.map((page) => ({
        ...page,
        sections: page.sections.filter(
          (section) => section._id !== payload._id
        ),
      }));
    case REMOVE_ALL_EXISTED_RESERVED_SUBS:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) => ({
          ...section,
          subSections: section.subSections.filter(
            ({ _id }) => _id !== payload._id
          ),
        })),
      }));
    case EDIT_ALL_EXISTED_SUBS_CHILDS:
      return state.map((page) => ({
        ...page,
        sections: page.sections.map((section) =>
          section?._id === payload.selectedSection?._id
            ? {
                ...section,
                subSections: section.subSections.map((sub) =>
                  sub?._id === payload.selectedSub._id ? payload.newSub : sub
                ),
              }
            : section
        ),
      }));

    default:
      return state;
  }
};
export default pagesReducer;
