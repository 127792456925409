import React, { useState } from "react";
import InputText from "../../forms/Input_text/InputText";
import { useDispatch } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import { EDIT_PAGE } from "../../../../redux/reducers/pages_reducer/page_types";
import PagesService from "../../../../httpServices/pages.services";
import UploadServices, {
  publicUrl,
} from "../../../../httpServices/httpServices";
import InputFile from "../../forms/input_file/InputFile";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RiCloseFill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import "./EditPageModel.scss";
import { useEffect } from "react";
import SelectTag from "../../forms/select_tag/SelectTag";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
const EditPageModel = ({ setModels, page, lang }) => {
  const dispatch = useDispatch();

  // const keywordIsExisted = selectedPage.keywords?.find((item) => item === keyword);

  const formik = useFormik({
    initialValues: {
      _id: page?._id,
      title_en: page?.title_en,
      title_ar: page?.title_ar,
      description_en: page?.description_en,
      description_ar: page?.description_ar,
      image_en: page?.image_en,
      image_ar: page?.image_ar,
      keywordDescription: page?.keywordDescription,
      keywords: page?.keywords,
      sections: [],
      isActive: page?.isActive,
      default: page?.default,
    },
    onSubmit: async () => {
      let data = {
        title_en: selectedPage.title_en,
        title_ar: selectedPage.title_ar,
        description_en: selectedPage.description_en,
        description_ar: selectedPage.description_ar,
        keywords: selectedPage.keywords,
        keywordDescription: selectedPage.keywordDescription,
        image_en: selectedPage.image_en,
        image_ar: selectedPage.image_ar,
        isActive: selectedPage.isActive,
        default: selectedPage.default,
      };
      if (pageImage_en) {
        const date = new FormData();
        date.append("image", pageImage_en);
        const resImage_en = await UploadServices.uploadFile("upload", date);
        data.image_en = resImage_en.filename;
        selectedPage.image_en = resImage_en.filename;
      }
      if (pageImage_ar) {
        const date = new FormData();
        date.append("image", pageImage_ar);
        const resImage_ar = await UploadServices.uploadFile("upload", date);
        data.image_ar = resImage_ar.filename;
        selectedPage.image_ar = resImage_ar.filename;
      }
      PagesService.updatePage("page", page._id, data)
        .then((data) => {
          if (data.success) {
            selectedPage.image_en = data.page.image_en;
            dispatch({
              type: EDIT_PAGE,
              payload: { page, selectedPage: data.page },
            });
            removeEditPageModel();
            toast.success(data[`message_${lang}`]);
          }
        })
        .catch((err) => {
          if (err.response.data) {
            err.response.data[`message_${lang}`]
              ? setErrorMessage(err.response.data[`message_${lang}`])
              : setErrorMessage(err.response.data);
            console.log("err :", err);
          }
        });
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image_en: Yup.string().required(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [pageImage_en, setPageImage_en] = useState(null);
  const [pageImage_ar, setPageImage_ar] = useState(null);
  const [keyword, setKeyword] = useState("");
  const {
    values: selectedPage,
    setValues: setSelectedPage,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  const keywordIsExisted = selectedPage?.keywords?.find(
    (item) => item === keyword
  );
    console.log("selectedPage",selectedPage);
  const addNewKeyword = () => {
    if (keyword.length > 0) {
      if (selectedPage.keywords.indexOf(keyword) === -1) {
        setSelectedPage({
          ...selectedPage,
          keywords: [...selectedPage.keywords, keyword],
        });
        setKeyword("");
      } else {
        formik.setErrors({
          ...errors,
          keywords:
            lang === "en"
              ? "this keyword is existed before"
              : "هذه الكلمة المفتاحية موجودة من قبل",
        });
      }
    }
  };
  const deleteKeyword = (item) => {
    setSelectedPage({
      ...selectedPage,
      keywords: selectedPage.keywords.filter((keyword) => keyword !== item),
    });
  };
  const uploadPageImg_en = (e) => {
    setPageImage_en(e.target.files[0]);
    setSelectedPage({
      ...selectedPage,
      image_en: e.target.files[0].name,
    });
  };
  const removeUploadedImg_en = () => {
    setPageImage_en(null);
    setSelectedPage({
      ...selectedPage,
      image_en: "",
    });
  };
  const uploadPageImg_ar = (e) => {
    setPageImage_ar(e.target.files[0]);
    setSelectedPage({
      ...selectedPage,
      image_ar: e.target.files[0].name,
    });
  };
  const removeUploadedImg_ar = () => {
    setPageImage_ar(null);
    setSelectedPage({
      ...selectedPage,
      image_ar: "",
    });
  };
  const removeEditPageModel = () => {
    setModels((models) => ({
      ...models,
      edit: {
        activition: false,
        pageId: "",
      },
    }));
  };
  useEffect(() => {
    formik.setTouched({
      ...touched,
      keyword: true,
    });
  }, [errors.keywords]);
  const [selectedActivity, setSelectActivity] = useState(
    selectedPage.isActive ? "active" : "not_active"
  );
  const handleActivition = (e) => {
    const { value } = e.target;
    setSelectActivity(value);
    setSelectedPage({
      ...selectedPage,
      isActive: value === "active" ? true : false,
    });
  };
  const [selectedDefault, setSelectedDefault] = useState(
    selectedPage.default ? "active" : "not_active"
  );
  const handleDefault = (e) => {
    const { value } = e.target;
    setSelectedDefault(value);
    setSelectedPage({
      ...selectedPage,
      default: value === "active" ? true : false,
    });
  };
  return (
    <div className="add_page_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeEditPageModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={selectedPage.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={selectedPage.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={selectedPage.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={selectedPage.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />
        <SelectTag
          lang={lang}
          label={lang === "en" ? "Activity" : "الفعالية"}
          selectedActivity={selectedActivity}
          activedValue={selectedPage.isActive}
          handleActivition={handleActivition}
        />
        <br />
        <SelectTag
          lang={lang}
          label={lang === "en" ? "Default Page" : "صقحة افتراضية"}
          selectedActivity={selectedDefault}
          activedValue={selectedPage.default}
          handleActivition={handleDefault}
        />
        {/* English Image */}
        <Box mt="50px">
          <Box
            sx={{
              position: "relative",
              mt: "30px",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                left: lang === "en" ? 0 : undefined,
                right: lang === "ar" ? 0 : undefined,
                bottom: 0,
                textTransform: "uppercase",
                fontSize: "18px",
                fontFamily: '"Tajawal", sans-serif',
              }}
            >
              {lang === "en"
                ? "image while English"
                : "الصورة أثناء اللغة الإنجليزية"}
            </Typography>
          </Box>
          {selectedPage.image_en || pageImage_en ? (
            <div className="img-container">
              <img
                src={
                  !pageImage_en
                    ? `${publicUrl}/${selectedPage.image_en}`
                    : URL.createObjectURL(pageImage_en)
                }
                alt="aa"
              />
              <div className="delete">
                <button type="button" onClick={removeUploadedImg_en}>
                  <AiFillDelete />
                </button>
              </div>
            </div>
          ) : (
            <InputFile
              lang={lang}
              name="image_en"
              error={errors.image_en}
              isTouched={pageImage_en === null}
              handleChange={uploadPageImg_en}
            />
          )}
        </Box>


        {/* Arabic image */}
        <Box mt="40px">
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                left: lang === "en" ? 0 : undefined,
                right: lang === "ar" ? 0 : undefined,
                bottom: 0,
                textTransform: "uppercase",
                fontSize: "18px",
                fontFamily: '"Tajawal", sans-serif',
              }}
            >
              {lang === "en"
                ? "image while Arabic"
                : "الصورة أثناء اللغة العربية"}
            </Typography>
          </Box>
          {selectedPage.image_ar || pageImage_ar ? (
            <div className="img-container">
              <img
                src={
                  !pageImage_ar
                    ? `${publicUrl}/${selectedPage.image_ar}`
                    : URL.createObjectURL(pageImage_ar)
                }
                alt="aa"
              />
              <div className="delete">
                <button type="button" onClick={removeUploadedImg_ar}>
                  <AiFillDelete />
                </button>
              </div>
            </div>
          ) : (
            <InputFile
              lang={lang}
              name="image_ar"
              error={errors.image_ar}
              isTouched={pageImage_ar === null}
              handleChange={uploadPageImg_ar}
            />
          )}
        </Box>
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={selectedPage.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border:
                  errors.keywords && touched?.keyword
                    ? "1px solid red"
                    : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onBlur={() => {
                formik.setTouched({ ...touched, keyword: true });
                if (selectedPage.keywords.length < 1) {
                  formik.setErrors({
                    ...errors,
                    keywords:
                      lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
                  });
                }
              }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && touched?.keyword && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {selectedPage.keywords?.length > 0 && (
            <Box
              component="div"
              className={` keywords_wrapper keywords_wrapper_${lang} `}
              sx={{
                height: "100px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: "#aac1fe",
                  transition: "0.5s all",
                  borderRadius: "15px",
                },

                " &::-webkit-scrollbar-thumb:hover": {
                  background: "#78a3e4",
                },
              }}
            >
              {selectedPage.keywords.map((keyword, index) => {
                return (
                  <span
                    key={index}
                    style={{
                      height: "auto",
                    }}
                  >
                    {keyword.length > 60
                      ? `${keyword.slice(0, 60)}...`
                      : keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                      style={{
                        padding: 0,
                      }}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}

          <button
            className={`mt-5 w-100 py-2 px-2 fw-bold  add_page_btn ${
              Object.keys(formik.errors).length > 0 && "disabled_btn"
            }`}
            type="submit"
          >
            {lang === "en" ? "Update Page" : "تحديث صفحة"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPageModel;
