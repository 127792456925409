import React, { useEffect } from "react";
import "./SecondRow.scss";
import { AiFillDelete } from "react-icons/ai";
import { publicUrl } from "../../../../httpServices/httpServices";
import { NavLink } from "react-router-dom";
const SecondRow = ({ lang, blogs, users }) => {
  return (
    <section className="second_row">
      <div className="row_container">
        <div className="first">
          <div>
            <h4 className="head_title">
              {lang === "en" ? "Users" : "المستخدمين"}
            </h4>
            <div className="select_all">
              {/* <input type="checkbox" id="select_all" />
              <label htmlFor="select_all">
                {lang === 'en' ? 'select all' : 'تحديد الكل'}
              </label> */}
            </div>
            <div className="table">
              <div className="head">
                <div></div>
                <div className={lang === "en" ? "ps-2" : "pe-2"}>#</div>
                <div>{lang === "en" ? "Name" : "الاسم"}</div>
                <div className="email">
                  {lang === "en" ? "Email" : "البريد الالكتروني"}
                </div>
                <div>{lang === "en" ? "Phone number" : "رقم الجوال"}</div>
                <div>{lang === "en" ? "Action" : "الاجراء"}</div>
              </div>
              <div className="body">
                {users.map((user) => (
                  <div key={user._id} className="body_tr">
                    <div>
                      <input type="checkbox" />
                    </div>
                    <div>{user._id}</div>
                    <div>{user[`fullName_${lang}`]}</div>
                    <div className="email">{user.email}</div>
                    <div>{user.phone}</div>
                    <div>
                      <button className="del_btn">
                        <AiFillDelete fill="#EB5F5F" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="last">
          <div>
            <div className="blogs_seeing">
              <h4>{lang === "en" ? "Blogs" : "المدونات"}</h4>
              <NavLink to="/adminDashboard/blogs">
                {lang === "en" ? "See All" : "مشاهدة الكل"}
              </NavLink>
            </div>
            <div className="blogs_wrapper">
              {blogs.map((blog, index) => (
                <div key={index} className="blog">
                  <img src={`${publicUrl}/${blog.image}`} alt="blog" />
                  <div className="date">
                    <p>{blog.createdAt}</p>
                    <p>{blog.title}</p>
                  </div>
                  <p>
                    {blog.description.length > 100
                      ? lang === "en"
                        ? "..." + blog.description.slice(0, 100)
                        : blog.description.slice(0, 100) + "..."
                      : blog.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default SecondRow;
