import styles from "./SuggestionsPage.module.css";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import contactsServices from "../../../httpServices/contact.services";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_SUGGESTIONS, GET_SUGGESTIONS } from "../../../redux/types/types";
import { useEffect, useState } from "react";


const SuggestionsPage = () => {
  const dispatch = useDispatch()
  const {suggestions} = useSelector((state) => state.complains)

  useEffect(() => {
    if (suggestions.length === 0) {
      const fetchedSuggestions = () => {
        contactsServices.getAllContacts(`contact/filter/query/?type=suggestion`).then((data) => {
          if (data) {
            dispatch({type:GET_SUGGESTIONS, payload: data.contacts})
          }
        })
      }
      fetchedSuggestions()
    }
    
  }, [dispatch, suggestions, suggestions.length])

  const deleteSuggetion = (id) => {
    contactsServices.deleteContacts(`contact/${id}`).then(data => {
      dispatch({type: DELETE_SUGGESTIONS, payload:id})
    })
  }
  
  console.log(suggestions)
  const lang = localStorage.getItem("lang");
  return (
    <div className="container-fluid p-5">
      <div
        className={`${styles.HeaderContainer} my-5 py-3 px-5 bg-white`}
      >
      <h1>{lang === 'en'? 'Suggestions' : 'المقترحات'}</h1>
      </div>
       
      <div className="row ">
      <span className="col"></span>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Suggestion No.' : 'رقم المقترح'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Name' : 'الإسم'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Phone' : 'رقم الجوال'}</h6>
        <h6 className={`${styles.Text} col`}>{lang === 'en' ? 'Email' : 'البريد الإلكتروني'}</h6>
        <span className="col"></span>
      </div>
      { suggestions.map((suggestion, index) => (
      <div key={suggestion._id} className={`${lang === 'en' ? styles.RowLeftBorder : styles.RowRightBorder} row m-3 d-flex align-items-center`}>
      <input type='checkbox' className={`${styles.Checkbox} col`}/>
      <p className={` col`}>{index +1}</p>
      <p className={` col`}>{suggestion.name}</p>
      <p className={` col`}>{suggestion.phone}</p>
      <p className={` col`}>{suggestion.email}</p>
      <Link to={`/adminDashboard/requests/suggestions/${suggestion._id}`} className="btn btn-primary w-auto text-white">{lang === 'en' ? 'Open' : 'افتح'}</Link>
      <div className={` col`}>
      <span style={{backgroundColor:'#fbdddd', width:'3vw', height:'3vh', borderRadius:'50%', textAlign:'center'}} className='m-1 py-1 px-2'>
      <FaTrashAlt style={{color:'#f18b8b', fontSize:'15px', cursor:'pointer'}} onClick={()=>deleteSuggetion(suggestion._id)}/>
      </span>
      
      </div>
    </div>
     )) 
      }
     
    </div>

  );
};

export default SuggestionsPage;
