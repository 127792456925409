import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import UserMainPage from "../users/pages/user_mainPage/UserMainPage";
import UserSubSingle from "../users/pages/user_subSection/UserSubSingle";
import Dashboard from "../admins/pages/dashboard/Dashboard";
import LoginPage from "../users/pages/loginPage/LoginPage";
import AdminMainPage from "../admins/pages/admin_mainPage/AdminMainPage";
import AdminRoutes from "./AdminRoutes";
import Blogs from "../admins/components/Blogs/AllBlogs/Blogs";
import SingleBlog from "../admins/components/Blogs/SingleBlog";
import Modal from "../admins/components/Blogs/Modal";
import AdminAddPage from "../admins/components/landing/add_page_model/AddPageModel";
import EditBlog from "../admins/components/Blogs/AddNewBlog/EditBog";
import AddSubForm from "../admins/pages/adding_subForm/AddSubForm";
import AdminSubSingle from "../admins/pages/admin_subSingle/AdminSubSingle";
import ReservedSectionsPage from "../admins/pages/reservedSections/ReservedSectionsPage";
import ReservedSubsPage from "../admins/pages/reservedSubs/ReservedSubsPage";
import { useLocation } from "react-router-dom";
import ContactUs from "../admins/components/ContactUs/ContactUs";
import ReceiveOffers from "../admins/components/ReceiveOffers/ReceiveOffers";
import ReceiveServices from "../admins/components/ReceiveServices/ReceiveServices";
import Profile from "../users/pages/Profile/Profile";
import Visitors from "../admins/components/Visitors/Visitors";
import Email from "../users/pages/Profile/Email";
import Password from "../users/pages/Profile/Password";
import UserBlogs from "../users/pages/userBlogs/UserBlogs";
import BlogDetails from "../admins/components/Blogs/BlogDetails";
import AddAdmin from "../admins/components/AddAdmin/AddAdmin";
import CommnetReply from "../admins/components/Blogs/CommentReply";
import ProtectedRoute from "../protected/ProtectedRoute";
import UserService from "../httpServices/user.service";
import { SET_USER } from "../redux/types/types";
import PublicRoutes from "../protected/PublicRoutes";
import Login from "../users/pages/LoginDesign/Login";
import LayoutScreen from "../admins/new_pages/layoutScreen/LayoutScreen";
import HomePage from "../admins/new_pages/home/HomePage";
import PagesPage from "../admins/new_pages/pages_page/PagesPage";
import SubSectionsPage from "../admins/new_pages/sub_sections/SubSectionsPage";
import SectionsPage from "../admins/new_pages/sections/SectionsPage";
import NewBlogDesign from "./../admins/components/Blogs/AllBlogs/newBlogDesign/NewBlogDesign";
import AddNewBlog from "../admins/components/Blogs/AddNewBlog/AddNewBlog";
import ReservedServicesPage from "../admins/new_pages/reserved/ReservedServicesPage";
import ReservedOffersPage from "../admins/new_pages/reserved/ReservedOffersPage";
import SubAdminsPage from "../admins/new_pages/admins/SubAdminsPage";
// import SuperAdminsPage from "../admins/new_pages/admins/SuperAdminsPage";
import VisitorsPage from "../admins/new_pages/statistics/VisitorsPage";
import UsersPage from "../admins/new_pages/statistics/UsersPage";
import ContactSariPage from "../admins/new_pages/contactSari/ContactSariPage";
import ComplaintsPage from "../admins/new_pages/comm_requests/ComplaintsPage";
import SuggestionsPage from "../admins/new_pages/comm_requests/SuggestionsPage";
import SuperAdmins from "../admins/components/SuperAdmins/SuperAdmins";
import SingleResSecPage from "../admins/new_pages/single_res_section_page/SingleResSecPage";
import SignUp from "../users/pages/SignUp/SignUp";
import SubAdmins from "../admins/components/SubAdmins/SubAdmins";
import AddSectionForm from "../admins/pages/adding_sectionForm/AddSectionForm";
import ComplainMessage from "../admins/new_pages/comm_requests/ComplainMessage";
import SuggestionMessage from "../admins/new_pages/comm_requests/SuggestionMessage";
import ReservationTypePage from "../admins/new_pages/reservationType/ReservationTypePage";
const RoutesPages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pages } = useSelector((state) => state);
  let { user } = useSelector((state) => state.user);
  const currentDefaultPage = pages.find((page) => page.default)?._id;

  const navigate = useNavigate();
  // const userRoleChecked =
  //  !user || user.role === "user"
  //  userRoleChecked && navigate(`${currentDefaultPage}`)
  return (
    <Routes>
      <Route path="/:pageId" element={<UserMainPage />} />
      {/* <Route path="/login" element={<PublicRoutes><LoginPage /></PublicRoutes>} /> */}
      {/* Test Blogs  */}

      <Route path="/login" element={<Login />} />
      <Route path="/signUp" element={<SignUp />} />
      <Route path="/" element={<PublicRoutes></PublicRoutes>} />
      <Route
        path="/adminDashboard"
        element={
          <LayoutScreen>
            <HomePage />
          </LayoutScreen>
        }
      />

      <Route
        path="/adminDashboard/pages"
        element={
          <LayoutScreen>
            <PagesPage />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/sections"
        element={
          <LayoutScreen>
            <ReservedSectionsPage />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/subSections"
        element={
          <LayoutScreen>
            <ReservedSubsPage />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/reservations/services"
        element={
          <LayoutScreen>
            <ReceiveServices />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/reservations/reservationsType"
        element={
          <LayoutScreen>
            <ReservationTypePage />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/reservations/offers"
        element={
          <LayoutScreen>
            <ReceiveOffers />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/admins"
        element={
          <LayoutScreen>
            <SuperAdmins />
          </LayoutScreen>
        }
      />

      <Route
        path="/adminDashboard/supAdmins"
        element={
          <LayoutScreen>
            <SubAdmins />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/requests/complaints"
        element={
          <LayoutScreen>
            <ComplaintsPage />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/requests/suggestions"
        element={
          <LayoutScreen>
            <SuggestionsPage />
          </LayoutScreen>
        }
      />

      <Route
        path="/adminDashboard/statistics/visitors"
        element={
          <LayoutScreen>
            <Visitors />
          </LayoutScreen>
        }
      />
      <Route
        path="/adminDashboard/statistics/users"
        element={
          <LayoutScreen>
            <UsersPage />
          </LayoutScreen>
        }
      />
      {/* <Route
        path="/adminDashboard/contactSari"
        element={
          <LayoutScreen>
            <ContactSariPage />
          </LayoutScreen>
        }
      /> */}
      <Route
        path="/adminDashboard/requests/suggestions/:suggestionId"
        element={<SuggestionMessage />}
      />
      <Route
        path="/adminDashboard/requests/complaints/:complainId"
        element={<ComplainMessage />}
      />
      <Route path="/:pageId/:sectionId/:subId" element={<UserSubSingle />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="profile/update-email" element={<Email />} />
      <Route path="profile/update-password" element={<Password />} />
      {/* Routes Special For Admin Only */}
      <Route element={<AdminRoutes />}>
        {/* <Route path="/adminDashboard" element={<Dashboard />} /> */}

        <Route
          path="/adminDashboard/pages/:pageId"
          element={
            <LayoutScreen>
              <AdminMainPage />
            </LayoutScreen>
          }
        />
        <Route
          path="/adminDashboard/pages/:pageId/:sectionId"
          element={
            <LayoutScreen>
              <SingleResSecPage />
            </LayoutScreen>
          }
        />
        {/* <Route path="/adminDashboard/addPage" element={<AdminAddPage />} /> */}
        <Route
          path="/adminDashboard/blogs"
          element={
            <LayoutScreen>
              <Blogs />
            </LayoutScreen>
          }
        />

        <Route path="/adminDashboard/addAdmin" element={<AddAdmin />} />
        <Route
          path="/adminDashboard/commentReply/:commentId/:blogId"
          element={<CommnetReply />}
        />
        <Route
          path="/blogs"
          element={
            <PublicRoutes>
              <UserBlogs />
            </PublicRoutes>
          }
        />
        <Route
          path="/adminDashboard/:pageId/addSection"
          element={<AddSectionForm />}
        />
        {/* <Route
          path="/adminDashboard/:pageId/:sectionId/addSub"
          element={< />}
        /> */}
        <Route
          path="/adminDashboard/:pageId/:sectionId/:subId"
          element={<AdminSubSingle />}
        />
        {/* <Route path="/adminDashboard/SuperAdmins" element={<LayoutScreen><SuperAdmins/></LayoutScreen>}/> */}
        {/* <Route path="/adminDashboard/blogs/:blogId" element={<SingleBlog />} /> */}
        <Route
          path="/adminDashboard/blogs/:blogId"
          element={<NewBlogDesign />}
        />
        <Route path="blogs/:blogId" element={<NewBlogDesign />} />

        {/* <Route path="/adminDashboard/blogs/newBlog" element={<Modal />} /> */}
        <Route path="/adminDashboard/blogs/newBlog" element={<AddNewBlog />} />
        <Route path="/adminDashboard/contact-us" element={<ContactUs />} />
        {/* <Route
          path="/adminDashboard/receive-offers"
          element={<ReceiveOffers />}
        /> */}
        {/* <Route
          path="/adminDashboard/receive-services"
          element={<ReceiveServices />}
        /> */}
        <Route
          path="/adminDashboard/blogs/editBlog/:blogId"
          element={<EditBlog />}
        />
        <Route
          path="/adminDashboard/blogDetails/:id"
          element={<BlogDetails />}
        />

        {/*  
          Old Routes
          "/adminDashboard/sections" =>  <ReservedSectionsPage />
          "/adminDashboard/reservedSubs" => element={<ReservedSubsPage /> 
        */}
      </Route>
    </Routes>
  );
};

export default RoutesPages;
