import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./UserMainPage.scss";
import UserNav from "../../components/user_nav/UserNav";
import UserPageIntro from "../../components/user_pageIntro/UserPageIntro";
import UserSection from "../../components/user_section/UserSection";
import { useEffect } from "react";
import PagesService from "../../../httpServices/pages.services";
import { GET_ALL_PAGES } from "../../../redux/reducers/pages_reducer/page_types";
import UserFooter from "../../components/footer/UserFooter";
import BookForm from "../../components/userSection_form/BookForm";
import SectionsOffers from "../../components/sections_offers/SectionsOffers";
import { publicUrl } from "../../../httpServices/httpServices";
import { Box } from "@mui/material";
const UserMainPage = () => {
  const { pages } = useSelector((state) => state);
  const { pageId } = useParams();
  const userCurrentPage = pages.find((page) => page._id === pageId);
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  useEffect(() => {
    if (pages.length === 0) {
      PagesService.getAllPagesForUser("page/user").then((data) => {
        dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
      });
    }
  }, []);

  return (
    <div>
      <UserNav userCurrentPage={userCurrentPage} pages={pages} lang={lang} />
      <UserPageIntro userCurrentPage={userCurrentPage} lang={lang} />
      <div
        className={`py-5 user_page ${
          userCurrentPage?.title_en === "About Us" && `about_us_style_${lang}`
        } `}
      >
        <div>
          {userCurrentPage?.sections.map(
            (userSection, sectionIndex) =>
              userSection.isActive === true &&
              !userSection.title_en.includes("offer") && (
                <div key={userSection._id}>
                  <UserSection
                    lang={lang}
                    userSection={userSection}
                    userCurrentPage={userCurrentPage}
                    sectionIndex={sectionIndex}
                  />
                </div>
              )
          )}
        </div>
        {userCurrentPage?.title_en === "About Us" && (
          <Box
            sx={{
              height: {
                md: "400px",
                sm: "250px",
                xs: "200px",
              },
              width: {
                md: "400px",
                sm: "250px",
                xs: "200px",
              },
              margin: {
                md: 0,
                xs: "auto",
              },
              borderRadius: "50%",
            }}
          >
            <img
              src={`${publicUrl}/${userCurrentPage?.image_en}`}
              alt="Dr.Rehab"
              style = {{
                width : "100%",
                height : "100%"
              }}

            />
          </Box>
        )}
      </div>
      {userCurrentPage?.sections.map(
        (userSection) =>
          userSection.isActive === true &&
          userSection.title_en.includes("offer") && (
            <div key={userSection._id}>
              <SectionsOffers
                lang={lang}
                userCurrentPage={userCurrentPage}
                userSection={userSection}
              />
            </div>
          )
      )}
      <BookForm userCurrentPage={userCurrentPage} lang={lang} />
      <UserFooter pages={pages} lang={lang} userCurrentPage={userCurrentPage} />
    </div>
  );
};

export default UserMainPage;
