import { SET_USER, NEW_USER, ALL_USERS, UPDATE_USER } from "../../types/types";
const initialState = {
  users: [],
  user: {},
};
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      return { ...state, user: payload };
    case NEW_USER:
      return { ...state, users: [...state.users, payload] };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;

    case ALL_USERS:
      return { ...state, users: [...payload] };
  }
};
export default userReducer;
