import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillDelete,
} from "react-icons/ai";
import "./EditSub.scss";
import InputText from "../../forms/Input_text/InputText";
import { BsPlusSquareFill } from "react-icons/bs";
import { AiFillMinusSquare } from "react-icons/ai";
import { EDIT_RESERVED_SUB_SECTION } from "../../../../redux/reducers/resSubs_reducer/resSubs_types";
import { EDIT_SUB } from "../../../../redux/reducers/pages_reducer/page_types";
import { EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE } from "../../../../redux/reducers/resSections_reducer/resSections_types";
import subSectionsService from "../../../../httpServices/subSection.services";
import UploadServices, {
  publicUrl,
} from "../../../../httpServices/httpServices";
import * as Yup from "yup";
import { useFormik } from "formik";
import SelectTag from "../../forms/select_tag/SelectTag";
import InputFile from "../../forms/input_file/InputFile";
import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";
const EditSubModel = ({
  // adminCurrentPage,
  adminSection,
  selectedSub,
  setModelEditSub,
  lang,
}) => {
  // const dispatch = useDispatch();
  // const [errorMessage, setErrorMessage] = useState("");
  // const matchingTitles = ["offer", "service", "team"]
  //   .map((word) => adminSection.title_en.includes(word))
  //   .includes(true);
  // const [newSub, setNewSub] = useState({
  //   title_en: selectedSub?.title_en,
  //   title_ar: selectedSub?.title_ar,
  //   keywords: selectedSub?.keywords,
  //   image: selectedSub?.image,
  //   keywordDescription: selectedSub?.keywordDescription,
  //   description_en: selectedSub?.description_en,
  //   description_ar: selectedSub?.description_ar,
  //   extras: matchingTitles ? selectedSub.extras : [],
  //   isActive:selectedSub?.isActive
  // });
  // const [editSubImage, setEditSubImage] = useState({});
  // const [keyword, setKeyword] = useState("");
  // const handleChange = (event) => {
  //   setNewSub({
  //     ...newSub,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // const keywordIsExisted = newSub.keywords?.find((item) => item === keyword);
  // const addKeyword = () => {
  //   if (!keywordIsExisted && keyword.length > 0) {
  //     setNewSub({
  //       ...newSub,
  //       keywords: [...newSub.keywords, keyword],
  //     });
  //     setKeyword("");
  //   }
  // };
  // const deleteKeyword = (parameter) => {
  //   setNewSub(
  //     Object.assign({}, newSub, {
  //       keywords: newSub.keywords.filter((key) => key !== parameter),
  //     })
  //   );
  // };
  // const changeSubData =async () => {
  //   if (editSubImage) {
  //     const date = new FormData();
  //     date.append("image", editSubImage);
  //     const resImage = await UploadServices.uploadFile("upload", date);
  //     newSub.image = resImage.filename;
  //   }
  //   subSectionsService
  //     .updateSupSection("subSection", selectedSub._id, newSub)
  //     .then((data) => {
  //       if (data.subsection) {
  //         dispatch({
  //           type: EDIT_SUB,
  //           payload: {
  //             adminCurrentPage,
  //             adminSection,
  //             selectedSub,
  //             newSub: data.subsection,
  //           },
  //         });
  //         dispatch({
  //           type: EDIT_RESERVED_SUB_SECTION,
  //           payload: { selectedSub, newSub: data.subsection },
  //         });
  //         dispatch({
  //           type: EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE,
  //           payload: { adminSection, selectedSub, newSub: data.subsection },
  //         });

  //         setModel((model) => ({
  //           ...model,
  //           showing: false,
  //         }));
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.data) {
  //         if (error.response.data["message_" + lang]) {
  //           setErrorMessage(error.response.data["message_" + lang]);
  //         } else {
  //           setErrorMessage(error.response.data);
  //         }
  //       }
  //     });
  // };

  // const [newExtraItem, setNewExtraItem] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });
  // const handleChangeNewExtra = (e) => {
  //   setNewExtraItem({
  //     ...newExtraItem,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const itemExisted = newSub?.extras?.find(
  //   ({ key_en, key_ar }) =>
  //     key_en === newExtraItem.key_en || key_ar === newExtraItem.key_ar
  // );

  // const addNewExtraItem = () => {
  //   if (!itemExisted) {
  //     if (
  //       newExtraItem.key_en.length !== 0 &&
  //       !newExtraItem.key_ar.length !== 0 &&
  //       !newExtraItem.value_en.length !== 0 &&
  //       !newExtraItem.value_ar.length !== 0
  //     ) {
  //       setNewSub({
  //         ...newSub,
  //         extras: [...newSub?.extras, newExtraItem],
  //       });
  //       setEditModelExtraItem((model) => ({
  //         ...model,
  //         showing: false,
  //         selectItem: "",
  //       }));
  //       setNewExtraItem({
  //         key_en: "",
  //         key_ar: "",
  //         value_en: "",
  //         value_ar: "",
  //       });
  //     }
  //   }
  // };
  // const deleteExtraItem = (selectedItem) => {
  //   setNewSub({
  //     ...newSub,
  //     extras: newSub.extras.filter(
  //       ({ key_en }) => key_en !== selectedItem.key_en
  //     ),
  //   });
  // };
  // const [modelEditExtraItem, setEditModelExtraItem] = useState({
  //   showing: false,
  //   selectItem: "",
  // });
  // const openModel = (item) => {
  //   setEditModelExtraItem((model) => ({
  //     ...model,
  //     showing: true,
  //     selectItem: item.key_en,
  //   }));
  // };

  // const selectedItem = newSub?.extras?.find(
  //   (item) => item?.key_en === modelEditExtraItem.selectItem
  // );
  // const [editItem, setEditItem] = useState({
  //   key_en: selectedItem?.key_en,
  //   key_ar: selectedItem?.key_ar,
  //   value_en: selectedItem?.value_en,
  //   value_ar: selectedItem?.value_ar,
  // });
  // const handleEditItem = (e) => {
  //   setEditItem({
  //     ...editItem,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const [formAdding, setFormAdding] = useState(false);
  const [editSubImage, setEditSubImage] = useState(null);
  
  const formik = useFormik({
    initialValues: {
      _id: selectedSub?._id,
      title_en: selectedSub?.title_en,
      title_ar: selectedSub?.title_ar,
      description_en: selectedSub?.description_en,
      description_ar: selectedSub?.description_ar,
      image: selectedSub?.image,
      keywords: selectedSub?.keywords,
      keywordDescription: selectedSub?.keywordDescription,
      extras: selectedSub?.extras,
      isActive: selectedSub?.isActive,
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image: Yup.string(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
    onSubmit: async () => {
      if (editSubImage) {
        const date = new FormData();
        date.append("image", editSubImage);
        const resImage = await UploadServices.uploadFile("upload", date);
        newSub.image = resImage.filename;
      }
      subSectionsService
        .updateSupSection("subSection", selectedSub._id, newSub)
        .then((data) => {
          if (data.subsection) {
            dispatch({
              type: EDIT_SUB,
              payload: {
                adminSection,
                selectedSub,
                newSub: data.subsection,
              },
            });
            dispatch({
              type: EDIT_RESERVED_SUB_SECTION,
              payload: { selectedSub, newSub: data.subsection },
            });
            dispatch({
              type: EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE,
              payload: { adminSection, selectedSub, newSub: data.subsection },
            });
            removeEditSubModel();
            toast.success(data.message_en);
          }
        })
        .catch((error) => {
          if (error?.response?.data) {
            if (error.response?.data["message_" + lang]) {
              setErrorMessage(error.response.data["message_" + lang]);
            } else {
              setErrorMessage(error.response.data);
            }
          }
        });
    },
  });
  const {
    values: newSub,
    setValues: setNewSub,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
  } = formik;
  const [keyword, setKeyword] = useState("");
  const uploadPageImg = (e) => {
    setEditSubImage(e.target.files[0]);
  };
  const addNewKeyword = () => {
    if (keyword.length > 0) {
      if (selectedSub.keywords.indexOf(keyword) === -1) {
        setNewSub({
          ...newSub,
          keywords: [...selectedSub.keywords, keyword],
        });
        setKeyword("");
      } else {
        formik.setErrors({
          ...errors,
          keywords:
            lang === "en"
              ? "this keyword is existed before"
              : "هذه الكلمة المفتاحية موجودة من قبل",
        });
      }
    }
  };
  const deleteKeyword = (item) => {
    setNewSub({
      ...newSub,
      keywords: selectedSub.keywords.filter((keyword) => keyword !== item),
    });
  };
  const removeUploadedImg = () => {
    setEditSubImage(null);
    setNewSub({
      ...newSub,
      image: "",
    });
  };

  const [extraFields, setExtraFields] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const [editItem, setEditItem] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const handleChangeExtraFields = (e) => {
    setExtraFields({
      ...extraFields,
      [e.target.name]: e.target.value,
    });
  };
  const valuesCompleted = Object.values(extraFields).every(
    (value) => value.length > 0
  );
  const itemExisted = newSub.extras?.find(
    (item) =>
      item.key_en === extraFields?.key_en || item.key_en === editItem?.key_en
  );
  const addNewExtraItem = () => {
    if (valuesCompleted && itemExisted === undefined) {
      setNewSub({
        ...newSub,
        extras: [...selectedSub?.extras, extraFields],
      });
      setExtraFields({
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  const deleteExtraItem = (selectItem) => {
    setNewSub({
      ...newSub,
      extras: selectedSub.extras.filter(
        ({ key_en }) => key_en !== selectItem.key_en
      ),
    });
  };
  const [modelEditExtraItem, setEditModelExtraItem] = useState({
    showing: false,
    selectItem: "",
  });
  const openModel = (item) => {
    setEditModelExtraItem((model) => ({
      ...model,
      showing: true,
      selectItem: item.key_en,
    }));
  };
  const removeModel = () => {
    setEditModelExtraItem((model) => !model.showing);
    setEditItem({
      key_en: "",
      key_ar: "",
      value_en: "",
      value_ar: "",
    });
  };
  const handleEditItem = (e) => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.value,
    });
  };
  console.log("edit sub => ", newSub);
  const changeExtrasItemData = (item) => {
    if (itemExisted === undefined) {
      setNewSub({
        ...selectedSub,
        extras: selectedSub.extras.map((current) =>
          current.key_en === item.key_en ? editItem : current
        ),
      });
      setEditItem({
        ...editItem,
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  const [selectedActivity, setSelectActivity] = useState(
    selectedSub?.isActive ? "active" : "not_active"
  );
  const handleActivition = (e) => {
    const { value } = e.target;
    setSelectActivity(value);
    setNewSub({
      ...newSub,
      isActive: value === "active" ? true : false,
    });
  };
  const removeEditSubModel = () => {
    setModelEditSub({
      showing: false,
      subId: "",
    });
  };
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  console.log(selectedSub.image);
  return (
    <div className="edit_res_sub_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeEditSubModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={newSub.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={newSub.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={newSub.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={newSub.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />
        <SelectTag
          lang={lang}
          label={lang === "en" ? "Activity" : "الفعالية"}
          selectedActivity={selectedActivity}
          activedValue={selectedSub?.isActive}
          handleActivition={handleActivition}
        />
        {newSub.image || editSubImage ? (
          <div className="img-container">
            <img
              src={
                !editSubImage
                  ? `${publicUrl}/${selectedSub.image}`
                  : URL.createObjectURL(editSubImage)
              }
              alt="aa"
            />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={editSubImage === null}
            handleChange={uploadPageImg}
          />
        )}

        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={newSub.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              name="keyword"
              style={{
                width: "90%",
                border: errors.keywords ? "1px solid red" : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {newSub.keywords?.length > 0 && (
            <div className={` keywords_wrapper keywords_wrapper_${lang} `}>
              {newSub.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </div>
          )}
          <h5 className="mt-4 fw-bold  text-center">
            {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
          </h5>
          <InputText
            label={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
            type="text"
            name="key_en"
            value={extraFields.key_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "english value" : "القيمة الانجليزية"}
            type="text"
            name="value_en"
            value={extraFields.value_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="key_ar"
            value={extraFields.key_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="value_ar"
            value={extraFields.value_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <button
            type="button"
            className="add_extras_item_btn"
            onClick={addNewExtraItem}
          >
            {lang === "en" ? "Add Item" : "اضف عنصر"}
          </button>
          {newSub.extras?.map((item, index) => (
            <div key={index} className="extras_item">
              {modelEditExtraItem.showing &&
              modelEditExtraItem.selectItem === item.key_en ? (
                <div className="edit_extra_item_model">
                  <InputText
                    label={
                      lang === "en" ? "english key" : "مفتاح اللغة العربية"
                    }
                    type="text"
                    name={`key_${lang}`}
                    value={lang === "en" ? editItem.key_en : editItem.key_ar}
                    placeholder={
                      lang === "en" ? "New english key" : "مفتاح عربي جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <InputText
                    label={lang === "en" ? "english value" : "القيمة العربية"}
                    type="text"
                    name={`value_${lang}`}
                    value={
                      lang === "en" ? editItem.value_en : editItem.value_ar
                    }
                    placeholder={
                      lang === "en"
                        ? "New english value"
                        : "قيمة اللغة الانجليزية جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <div className="edit-extras-item-model-btns">
                    <button
                      type="button"
                      onClick={() => changeExtrasItemData(item)}
                    >
                      <AiFillCheckCircle />
                    </button>
                    <button className="" type="button" onClick={removeModel}>
                      <AiFillCloseCircle />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h4>{item[`key_${lang}`]}</h4>
                  <h6>{item[`value_${lang}`]}</h6>
                  <button
                    className=" del-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => deleteExtraItem(item)}
                  >
                    <span>X</span>
                  </button>
                  <button
                    className=" edi-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => openModel(item)}
                  >
                    <span>&#10049;</span>
                  </button>
                </>
              )}
            </div>
          ))}
          <button
            className={`mt-5 w-100 py-2 px-2 fw-bold  add_page_btn ${
              Object.keys(formik.errors).length > 0 && "disabled_btn"
            }`}
            type="submit"
          >
            {lang === "en" ? "Update Sub Section" : "تحديث القسم الفرعي"}
          </button>
        </div>
      </form>
    </div>
    // <div className="edit-sub-model" style={{ zIndex: "4" }}>
    //   <form>
    //     {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
    //     <h4 className="text-center mt-5">
    //       {lang === "en" ? "Edit Sub Section" : "تعديل القسم الفرعي"}
    //     </h4>
    //     <button
    //       type="button"
    //       className="cancel-model btn btn-danger"
    //       onClick={() =>
    //         setModel((model) => ({ ...model, showing: false, selectItem: "" }))
    //       }
    //     >
    //       <span>🗙</span>
    //     </button>
    //     <InputText
    //       label={lang === "en" ? "title" : "اللقب"}
    //       name={`title_${lang}`}
    //       value={newSub[`title_${lang}`]}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "sub image link" : "رابط الصورة الفرعية"}
    //       name="image"
    //       type="file"
    //       handleChange={(e) => setEditSubImage(e.target.files[0])}
    //     />
    //             <select
    //       name="isActive"
    //       value={newSub.isActive}
    //       // handleChange={handleChange}
    //       onChange = {handleChange}
    //     >
    //       <option selected hidden>
    //       {lang === "en" ? " Choose Activate Sub Section" : " اختر نشاط القسم الفرعي " }
    //       </option>
    //       <option value={false}>
    //         {lang === "en" ? "Non Displayed" : "غير معروضه"}
    //       </option>
    //       <option value={true}>
    //       {lang === "en" ? " Displayed" : " معروضه"}
    //       </option>
    //     </select>

    //     <InputText
    //       label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
    //       name="keywordDescription"
    //       value={newSub.keywordDescription}
    //       handleChange={handleChange}
    //     />
    //     <div className="mt-4 input-container">
    //       <label className="d-block fs-5 ms-1 text-uppercase">
    //         {lang === "en" ? "description" : "الوصف"}
    //       </label>
    //       <textarea
    //         style={{ height: "120px" }}
    //         type="text"
    //         className="d-block w-100 mt-1 fs-5 py-1 px-2 rounded border border-dark"
    //         value={newSub[`description_${lang}`]}
    //         onChange={(event) =>
    //           setNewSub({
    //             ...newSub,
    //             [`description_${lang}`]: event.target.value,
    //           })
    //         }
    //       />
    //     </div>
    //     <div className="row mt-1">
    //       <div className="col-10">
    //         <InputText
    //           label={lang === "en" ? "keyword" : "الكلمة"}
    //           value={keyword}
    //           handleChange={(e) => setKeyword(e.target.value)}
    //         />
    //       </div>
    //       <div className="col-2 d-flex align-items-end justify-content-center  pb-2">
    //         <button
    //           type="button"
    //           style={{ border: 0, outline: 0, backgroundColor: "transparent" }}
    //           onClick={addKeyword}
    //         >
    //           <IoIosAddCircle style={{ height: "28px", width: "28px" }} />
    //         </button>
    //       </div>
    //       {newSub.keywords?.length > 0 && (
    //         <div className="keywords">
    //           {newSub.keywords.map((keyword, idx) => (
    //             <p key={idx}>
    //               {keyword}
    //               <button type="button" onClick={() => deleteKeyword(keyword)}>
    //                 <AiFillCloseCircle />
    //               </button>
    //             </p>
    //           ))}
    //         </div>
    //       )}
    //     </div>
    //     {matchingTitles && (
    //       <div className="w-75 mx-auto h-auto mt-5">
    //         <h5 className="mx-auto">
    //           {lang === "en" ? "ُExtra Items" : "عناصر الاكسترا"}
    //         </h5>
    //         <button
    //           type="button"
    //           className="ms-auto d-block btn"
    //           style={{ backgroundColor: "transparent" }}
    //           onClick={() => {
    //             setFormAdding((form) => !form);
    //             setNewExtraItem({
    //               key_en: "",
    //               key_ar: "",
    //               value_en: "",
    //               value_ar: "",
    //             });
    //           }}
    //         >
    //           {!formAdding ? (
    //             <BsPlusSquareFill style={{ height: "20px", width: "20px" }} />
    //           ) : (
    //             <AiFillMinusSquare style={{ height: "20px", width: "20px" }} />
    //           )}
    //         </button>
    //         {formAdding && (
    //           <div className="form">
    //             <InputText
    //               label={lang === "en" ? "english key" : "المفتاح الانجليزي"}
    //               name="key_en"
    //               value={newExtraItem.key_en}
    //               handleChange={handleChangeNewExtra}
    //             />
    //             <InputText
    //               label={lang === "en" ? "english value" : "القيمة الانجليزية"}
    //               name="value_en"
    //               value={newExtraItem.value_en}
    //               handleChange={handleChangeNewExtra}
    //             />
    //             <InputText
    //               label={lang === "en" ? "arabic key" : "المفتاح العربي"}
    //               name="key_ar"
    //               value={newExtraItem.key_ar}
    //               handleChange={handleChangeNewExtra}
    //             />
    //             <InputText
    //               label={lang === "en" ? "arabic value" : "القيمة العربية"}
    //               name="value_ar"
    //               value={newExtraItem.value_ar}
    //               handleChange={handleChangeNewExtra}
    //             />

    //             <button
    //               className="btn bg-info text-white mx-auto d-block w-50 my-4"
    //               type="button"
    //               onClick={addNewExtraItem}
    //             >
    //               {lang === "en" ? "add items" : "اضف عناصر"}
    //             </button>
    //           </div>
    //         )}
    //         {newSub.extras?.length > 0 &&
    //           newSub?.extras?.map((item, idx) => (
    //             <div
    //               key={idx}
    //               className="border border-dark position-relative mt-3"
    //             >
    //               <p>
    //                 <span className="fw-bold">
    //                   {lang === "en" ? "english key " : " المفتاح العربي"}
    //                 </span>
    //                 {lang === "en" ? item?.key_en : item?.key_ar}
    //               </p>
    //               <p>
    //                 <span className="fw-bold">
    //                   {lang === "en" ? "english value " : " القيمة العربية"}
    //                 </span>
    //                 {lang === "en" ? item?.value_en : item?.value_ar}
    //               </p>
    //               <button
    //                 className="position-absolute btn text-white d-flex align-items-center justify-content-center"
    //                 type="button"
    //                 style={{
    //                   top: "-10px",
    //                   right: "-10px",
    //                   height: "30px",
    //                   width: "15px",
    //                   backgroundColor: "#DC3545",
    //                 }}
    //                 onClick={() => deleteExtraItem(item)}
    //               >
    //                 <span>X</span>
    //               </button>
    //               <button
    //                 className="position-absolute btn text-white d-flex align-items-center justify-content-center"
    //                 type="button"
    //                 style={{
    //                   top: "-10px",
    //                   right: "20px",
    //                   height: "30px",
    //                   width: "15px",
    //                   backgroundColor: "#00B526",
    //                 }}
    //                 onClick={() => openModel(item)}
    //               >
    //                 <span>&#10049;</span>
    //               </button>
    //               {modelEditExtraItem.showing &&
    //                 modelEditExtraItem.selectItem === item.key_en && (
    //                   <div
    //                     className="position-absolute top-0 start-0 bg-danger "
    //                     style={{ zIndex: 4 }}
    //                   >
    //                     <InputText
    //                       label={
    //                         lang === "en" ? "english key" : "المفتاح الانجليزي"
    //                       }
    //                       name={`key_${lang}`}
    //                       value={editItem[`key_${lang}`]}
    //                       handleChange={handleEditItem}
    //                     />
    //                     <InputText
    //                       label={
    //                         lang === "en"
    //                           ? "english value"
    //                           : "القيمة الانجليزية"
    //                       }
    //                       name={`value_${lang}`}
    //                       value={editItem[`value_${lang}`]}
    //                       handleChange={handleEditItem}
    //                     />
    //                     <button
    //                       type="button"
    //                       onClick={() => {
    //                         setNewSub({
    //                           ...newSub,
    //                           extras: newSub.extras.map((current) =>
    //                             current.key_en === item.key_en
    //                               ? editItem
    //                               : current
    //                           ),
    //                         });
    //                         setEditItem({
    //                           ...editItem,
    //                           key_en: "",
    //                           key_ar: "",
    //                           value_en: "",
    //                           value_ar: "",
    //                         });
    //                       }}
    //                     >
    //                       {lang === "en" ? "Upddate item" : "تحديث العنصر"}
    //                     </button>
    //                     <button
    //                       className="position-absolute btn text-white d-flex align-items-center justify-content-center"
    //                       type="button"
    //                       style={{
    //                         top: "-10px",
    //                         right: "-10px",
    //                         height: "30px",
    //                         width: "15px",
    //                         backgroundColor: "black",
    //                         zIndex: "10",
    //                       }}
    //                       onClick={() =>
    //                         setEditModelExtraItem((model) => !model.showing)
    //                       }
    //                     >
    //                       <span>X</span>
    //                     </button>
    //                   </div>
    //                 )}
    //             </div>
    //           ))}
    //       </div>
    //     )}
    //     <div className="update-btn-container">
    //       <button type="button" onClick={changeSubData}>
    //         Update
    //       </button>
    //     </div>
    //   </form>
    // </div>
  );
};

export default EditSubModel;
