import React from "react";
import { BsFillImageFill } from "react-icons/bs";
import "./InputFile.scss";
const InputFile = ({ lang, name, handleChange, error, isTouched }) => {
  return (
    <div className={`file_input_container ${error && isTouched && "error"} `}>
      <div className="file_input">
        <input
          type="file"
          className="file"
          name={name}
          onChange={handleChange}
          accept="image/*"
        />
        <div className="input_interface">
          <BsFillImageFill />
          <span>{lang === "en" ? "Add Image" : "إضافة صورة"}</span>
        </div>
      </div>
      <p className="validate_msg"></p>
    </div>
  );
};

export default InputFile;
