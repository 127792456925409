import { Box } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { publicUrl } from "../../../httpServices/httpServices";
import "./SectionsOffer.scss";
const SectionsOffers = ({ userCurrentPage, userSection, lang }) => {
  return (
    <section
      className={`landing_page_section ${
        userSection?.title_en === "offers" && "offers_section_style"
      }`}
    >
      <div className="sec_inner_head_a">
        {userSection.subSections.length > 0 ? (
          <header className="section_header">
            <h1 className="fw-semibold">{userSection[`title_${lang}`]}</h1>
            <h2 className=" mt-4">{userSection[`description_${lang}`]}</h2>
          </header>
        ) : (
          <header className="section_header ">
            <h1 className=" text-center" style={{ color: "#999999" }}>
              {lang === "ar"
                ? "ترقبوا عروضنا قريبًا"
                : "Look out for our offers soon"}
            </h1>
          </header>
        )}
        <ul className="m-0 p-0 row mt-5">
          {userSection.subSections.map(
            (sub) =>
              sub.isActive && (
                <div
                  key={sub._id}
                  className="col-lg-4 col-md-6 col-sm-12 p-0 mt-4 pb-3 d-sm-block d-flex justify-content-center"
                >
                  <div
                    class="card rounded border  text-center pb-4"
                    style={{
                      width: "75%",
                      height: "570px",
                      paddingTop: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        height: "auto",
                        mx: "auto",
                        width: {
                          md: 0.75,
                          sm: 1,
                        },
                      }}
                    >
                      <img
                        src={`${publicUrl}/${sub.image}`}
                        alt={sub[`title_${lang}`]}
                        style={{
                          height: "300px",
                          width: "100%",
                          margin: "0 auto",
                        }}
                      />
                    </Box>
                    <div class="card-body pb-4">
                      <h5 class="card-title fw-bold">{sub[`title_${lang}`]}</h5>
                      <p class="card-text">{sub[`description_${lang}`]}</p>
                      <ul
                        class="list-group list-group-flush m-0 p-0 w-100"
                        style={{ borderTop: "1px solid #dfdfdf" }}
                      >
                        {sub?.extras?.length > 0 &&
                          sub?.extras.slice(0, 2).map((extraItem, idx) => (
                            <li key={idx} class="list-group-item  p-2">
                              <h6 className="m-0 fw-bold">
                                {extraItem[`key_${lang}`]}
                              </h6>
                              <p className="m-0">
                                {extraItem[`value_${lang}`]}
                              </p>
                            </li>
                          ))}
                      </ul>
                      <NavLink
                        className="read-more-link"
                        to={`/${userCurrentPage?._id}/${userSection?._id}/${sub?._id}`}
                      >
                        {lang === "en" ? "Read More" : "أقرأ المزيد"}
                      </NavLink>
                    </div>
                  </div>
                </div>
              )
          )}
        </ul>
      </div>
    </section>
  );
};

export default SectionsOffers;
