import React, { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommentService from "../../../../httpServices/comment.service";
import { DELETE_REPLY } from "../../../../redux/types/types";
import { publicUrl } from "../../../../httpServices/httpServices";
import "./reply.scss";
function ShowReplys({ comment, blog, user }) {
  const { blogs } = useSelector((state) => state.blogs);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleDeleteReply = (commentId, replyId, blogId) => {
    CommentService.deleteReply(`comment/reply/${replyId}`).then((value) => {
      // dispatch to delete the reply from the redux itself
      dispatch({
        type: DELETE_REPLY,
        payload: { comment: commentId, blog: blogId, reply: replyId },
      });
    });
  };

  // const handleOpenReply = (commentId, blogId) => {
  //     navigate(`/adminDashboard/commentReply/${commentId}/${blogId}`);
  // };
  const checkAdminorRootUser = (reply) => {
    if (reply.user._id === user._id || user.role === "admin") return true;
    return false;
  };
  const lang = localStorage.getItem("lang");
  return (
    <div className={`reply reply_${lang}`}>
      {comment.replys?.length > 0 &&
        comment.replys.map((reply, index) => {
          return (
            <div className="reply-wrapper_container  my-4">
              <div className="image_wrapper">
                <img
                  src={`${
                    reply.user.image
                      ? `${publicUrl}/${reply.user.image}`
                      : "https://via.placeholder.com/150"
                  }`}
                  alt="reply "
                />
              </div>
              <div className="replay-wrapper">
                <p className="m-0 fw-bold">{reply.user[`fullName_${lang}`]}</p>

                <p className="reply_title" key={index}>
                  {reply.title}
                </p>
                {checkAdminorRootUser(reply) && (
                  <div className="reply__wrapper-delete">
                    <button
                      onClick={() =>
                        handleDeleteReply(comment._id, reply._id, blog._id)
                      }
                    >
                      <AiFillDelete
                        className="reply__font-delete"
                        style={{ cursor: "pointer" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        })}

      {/* <div className="btn-wrapper d-flex justify-content-end">
                <button
                    className="btn-link btn btn__reply"
                    onClick={() => handleOpenReply(comment._id, blog._id)}
                >
                    AddReply
                </button>
            </div> */}
    </div>
  );
}

export default ShowReplys;
