export const PUSH_SUB_TO_RESERVED_SECTION = "PUSH_SUB_TO_RESERVED_SECTION";
export const EDIT_RESERVED_SECTION_FROM_LANDING_PAGE =
  "EDIT_RESERVED_SECTION_FROM_LANDING_PAGE";
export const EDIT_RESERVED_SECTION = "EDIT_RESERVED_SECTION";
export const REMOVE_RESERVED_SECTION = "REMOVE_RESERVED_SECTION";
export const REMOVE_RESERVED_SUB_FROM_RESERVED_SECTION =
  "REMOVE_RESERVED_SUB_FROM_RESERVED_SECTION";
export const EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE =
  "EDIT_RESERVED_CHILD_SUB_IN_RESERVED_SECTION_FROM_LANDING_PAGE";
export const ADD_NEW_RESERVED_SECTION = "ADD_NEW_RESERVED_SECTION";
export const GET_ALL_ERS_SECTIONS = "GET_ALL_ERS_SECTIONS";
export const REMOVE_SEC_SUB_FROM_LANDING_PAGE = "REMOVE_SEC_SUB_FROM_LANDING_PAGE";