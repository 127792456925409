import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const visitorsService = {
  getAllVisitors: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    return data;
  },
};

export default visitorsService;
