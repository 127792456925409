// import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from '../../../httpServices/user.service';
import { useNavigate } from 'react-router';

const Email = () => {
    // const [user,setUser] = useState({})
    const navigate = useNavigate()

   

    const formik = useFormik({
        initialValues: {
           email: '',
           password: '',
         },
        
     
           validationSchema: Yup.object({
            email: Yup.string().email('Must be valid email').required('Required!'),
             password: Yup.string().min(6,'Password must be at least 6 letters').max(30, 'Password is too long').required('Required!'),
             
             
       
           }),
           onSubmit: (values) => {
            const changeEmail = () => {
              UserService.updateUser(`user/me/email`, {email: values.email, password: values.password}).then((value)=>value.message_en ? navigate(-1) :null)
            }
            changeEmail()
           }
        })

        // useEffect(()=>{
        //     // formik.setFieldValue('email',user.email)
        //     // formik.setFieldValue('password',user.password)
        //   },[])
    
          // useEffect(() => {
          // },[formik])
            
          

  return (
    <div style={{height:'900px', width:'99%'}}>
        <form onSubmit={formik.handleSubmit} className='d-flex flex-column justify-content-center align-items-center h-100'>
        <div >
              <label>
                <h6>New Email</h6>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                style={{ width: "415px", borderRadius: "15px", height: "50px" }}
                className="border m-2 "
              />
              {formik.touched.email && formik.errors.email ? (
                <p style={{color:'red'}}>{formik.errors.email}</p>
              ) : null}
            </div>
            <div >
              <label>
                <h6>Password</h6>
              </label>
              <input
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                style={{ width: "415px", borderRadius: "15px", height: "50px" }}
                className="border m-2 "
              />
              {formik.touched.password && formik.errors.password ? (
                <p style={{color:'red'}}>{formik.errors.password}</p>
              ) : null}
            </div>
            <button className='px-3 py-1 border rounded m-3' type='submit'>Update Email</button>
            </form>
    </div>
  )
}

export default Email