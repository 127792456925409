//-------------------User-------------------
export const SET_USER = 'SET_USER'
export const NEW_USER = 'NEW_USER'
export const ALL_USERS = 'ALL_USERS'
export const ALL_SUPER_ADMINS = 'ALL_SUPER_ADMINS'
export const ALL_ADMINS = 'ALL_ADMINS'
export const UPDATE_USER = 'UPDATE_USER'





// ----------------- Blogs ------------

export const NEW_BLOG = "NEW_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const EDIT_BLOG = "EDIT_BLOG";
export const ALL_BLOGS = "ALL_BLOGS";
export const ADD_COMMENT = 'ADD_COMMENT'
export const SET_BLOG = 'SET_BLOG'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const ADD_REPLY = 'ADD_REPLY'
export const DELETE_REPLY = 'DELETE_REPLY'
export const MANAGE_LIKE = 'MANAGE_LIKE'
// -------------------- Offers --------------------

export const ALL_OFFERS = "ALL_OFFERS";
export const TOGGLE_BTN = "TOGGLE_BTN";
export const ADD_OFFER = "ADD_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
// -------------------- Services ------------------

export const GET_SERVICES = 'GET_SERVICES'
export const DELETE_SERVICE = 'DELETE_SERVICE'


// ------------------- ContactUs -----------------

export const DELETE_CONTACT = "DELETE_OFFER";
// ========= Complaints and Suggestions ==========
export const GET_SUGGESTIONS = 'GET_SUGGESTIONS'
export const GET_COMPLAINS = 'GET_COMPLAINS'
export const DELETE_SUGGESTIONS = 'DELETE_SUGGESTIONS'
export const DELETE_COMPLAINS = 'DELETE_SUGGESTIONS'

