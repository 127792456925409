import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";

import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import BlogServices from "../../../../httpServices/blogs.services";
import { ALL_BLOGS, EDIT_BLOG } from "../../../../redux/types/types";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import styles from "./addNewBlog.module.scss";
import { CgClose } from "react-icons/cg";
import UploadServices, {
  publicUrl,
} from "../../../../httpServices/httpServices";
const EditBlog = () => {
  const dispatch = useDispatch();
  const { blogs } = useSelector((state) => state.blogs);
  const navigate = useNavigate();
  const { blogId } = useParams();
  const [imagePath, setImagePath] = useState(null);
  const [handleBlogType, setHandleBlogType] = useState("");
  const [blogKeyword, setBlogKeyword] = useState("");

  // const selectedBlog = blogs.filter(blog => blog.id === blogId)
  const [blog, setBlog] = useState({});
  // const [recievedBlogs, setRecievedBlogs] = useState([])
  const [editedBlog, setEditedBlog] = useState(null);
  const [blogType, setBlogType] = useState("null");
  const [file, setFile] = useState(null);
  const [singleKeword, setSingleKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);

  const submitHandler = (e) => {
    e.preventDefault();

    updateBlog(editedBlog);
  };
  const changeHandler = (e) => {
    // const { id, value } = e.target;
    // setEditedBlog({ ...editedBlog, [id]: value });
  };
  const handleClose = (e, target, index) => {
    setEditedBlog({
      ...editedBlog,
      keywords: editedBlog.keywords.filter((keyword) => keyword != target),
    });
  };

  const updateBlog = (blogInfo) => {
    if (editedBlog && editedBlog._id) {
      BlogServices.editBlog(`blog/${editedBlog._id}`, blogInfo)
        .then((value) => {
          dispatch({ type: EDIT_BLOG, payload: value.blog });

          console.log(value.message_en);
          navigate("/adminDashboard/blogs");
        })
        .catch((e) => {
          console.log("updateBlogError: ", e.message);
        });
    }
  };
  const setFormik = (blog) => {
    console.log("blog: ", blog);
    formik.setFieldValue("title", blog.title);
    formik.setFieldValue("description", blog.description);
    formik.setFieldValue("keywordDescription", blog.keywordDescription);
    formik.setFieldValue("image", blog.image);
    formik.setFieldValue("blogType", blog.blogType);
    if (blog.blogType == "event") {
      formik.setFieldValue("startDate", blog.startDate);
      console.log("blogEndDate", blog.endDate);
      formik.setFieldValue("endDate", blog.endDate);
    }
  };
  useEffect(() => {
    if (blogs.length == 0) {
      const getData = () => {
        BlogServices.getAllServices("blog")
          .then((data) => {
            console.log(data.blogs[0]);
            // setFormik(data.blogs[0])
            setFormik(data.blogs[0]);
            dispatch({
              type: ALL_BLOGS,
              payload: [
                ...data.blogs.map((blog) => {
                  return { ...blog, isLike: false };
                }),
              ],
            });
            setEditedBlog((prev) =>
              [
                ...data.blogs.map((blog) => {
                  return { ...blog, isLike: false };
                }),
              ].find((blog) => blog._id == blogId)
            );
            console.log(
              "rquest: data; ",
              [
                ...data.blogs.map((blog) => {
                  return { ...blog, isLike: false };
                }),
              ].find((blog) => blog._id == blogId)
            );
          })
          .catch((e) => {
            console.log("error: ", e.message);
          });
      };
      getData();
    } else {
      setEditedBlog((prev) => blogs.find((blog) => blog._id == blogId));
      setFormik(blogs.find((blog) => blog._id == blogId));
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      keywordDescription: "",
      image: "",
      blogType: "",
    },

    onSubmit: (values) => {
      if (values.blogType != "event") {
        delete values["startDate"];
        delete values["endDate"];
      }
      console.log(values);
      updateBlog(values);
    },

    validationSchema: Yup.object({
      title: Yup.string().required(),
      description: Yup.string().required(),
      keywordDescription: Yup.string().required(),
      startDate: Yup.string().when("blogType", {
        is: "event",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      endDate: Yup.string().when("blogType", {
        is: "event",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      // image: Yup.string().required()
      // // password: Yup.string().required(),
    }),
  });

  const handleFileChange = (e) => {
    // console.log(imageRef.current)
    // console.log(e.target.files[0])
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      const data = new FormData();
      data.append("image", e.target.files[0]);
      console.log("image: ", data.get("image"));
      UploadServices.uploadFile("upload", data)
        .then((value) => {
          console.log("value: ", value);
          formik.setFieldValue("image", value.filename);
        })
        .catch((e) => {
          console.log("ay 7aga: ", e.message);
        });
      // // setImagePath(_ => URL.createObjectURL(e.target.files[0]))
      // formik.setFieldValue('image', e.target.files[0].name)
    }
  };
  const handleChangeSingleKeyWord = (e) => {
    console.log("the data; ", e.target.value);
    setSingleKeyword((_) => e.target.value);
  };
  const handleKeywords = (value) => {
    console.log("the setnt value: ", value);
    if (!editedBlog.keywords.includes(value)) {
      setEditedBlog((prev) => {
        return { ...prev, keywords: [...prev.keywords, singleKeword] };
      });

      setSingleKeyword("");
    }
  };
  const deleteKeyword = (selectedKeyword) => {
    setEditedBlog({
      ...editedBlog,
      keywords: editedBlog.keywords.filter(
        (keyword) => keyword !== selectedKeyword
      ),
    });
  };
  const lang = localStorage.getItem("lang");
  return (
    <div className="">
      <div className={`${styles.formContainer}`}>
        {/* <h1 className="display-3 m-4">Add new blog</h1> */}
        <div className="d-flex align-items-center justify-content-center">
          <form
            onSubmit={formik.handleSubmit}
            className={` ${styles.formBody} position-relative`}
          >
            <div className={`${styles.iconWrapper}`}>
              <button onClick={() => navigate(-1)}>
                <CgClose />
              </button>
            </div>
            <div className={`${styles.iconWrapper}`}>
              <CgClose
                className={`${styles.addSymbol}`}
                onClick={() => navigate(-1)}
              />
            </div>
            <div className="form-group">
              <label>{lang === "en" ? "Title" : "العنوان"}</label>
              <input
                type="text"
                className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                value={formik.values.title}
                onChange={formik.handleChange}
                id="title"
                placeholder={lang === "en" ? "Title" : "العنوان"}
                onBlur={formik.handleBlur}
              />
              <p className="text-danger ">
                {formik.touched.title &&
                  formik.errors["title"] &&
                  `*.` + formik.errors.title}
              </p>
            </div>
            <div className="form-group">
              {editedBlog && editedBlog.blogType === "event" ? (
                <>
                  <div className="form-group">
                    <label>
                      {lang === "en" ? "Start Date" : "تاريخ البدء"}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                      value={formik.values.startDate}
                      onChange={formik.handleChange}
                      id="startDate"
                      placeholder={lang === "en" ? "Start Date" : "تاريخ البدء"}
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-danger ">
                      {formik.touched.startDate &&
                        formik.errors["startDate"] &&
                        `*.` + formik.errors.startDate}
                    </p>
                  </div>
                  <div className="form-group">
                    <label>
                      {lang === "en" ? "End Date" : "تاريخ الانتهاء"}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                      value={formik.values.endDate}
                      onChange={formik.handleChange}
                      id="endDate"
                      placeholder={
                        lang === "en" ? "End Date" : "تاريخ الانتهاء"
                      }
                    />
                  </div>
                  <p className="text-danger ">
                    {formik.touched.endDate &&
                      formik.errors["endDate"] &&
                      `*.` + formik.errors.endDate}
                  </p>
                </>
              ) : null}
            </div>
            <div className="wrapper ">
              <div className="btnimg">
                {editedBlog && editedBlog.user && !file ? (
                  <div
                    className={``}
                    style={{ height: "5rem", margin: "20px 0" }}
                  >
                    <img
                      src={`${publicUrl}/${editedBlog.image}`}
                      alt="profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid blue",
                        borderRadius: "5px",
                      }}
                      className={``}
                      // width="150px"
                      // style={{ height: "150px", borderRadius: "50%" }}
                    />
                  </div>
                ) : (
                  <div
                    className={``}
                    style={{ height: "5rem", width: "25rem", margin: "20px 0" }}
                  >
                    {file && (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="profile"
                        style={{
                          width: "100%",
                          height: "100%",
                          border: "1px solid blue",
                          borderRadius: "5px",
                        }}
                        className={``}
                        // width="150px"
                        // style={{ height: "150px", borderRadius: "50%" }}
                      />
                    )}
                  </div>
                )}
                {/* { ? (
                  <div className={``} style={{ height: '5rem', width: '25rem', margin: '20px 0' }}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt="profile"
                      style={{ width: '100%', height: '100%', border: '1px solid blue', borderRadius: '5px' }}
                      className={``}
                    // width="150px"
                    // style={{ height: "150px", borderRadius: "50%" }}

                    />
                  </div>
                ) : (
                  <img
                    src={`${publicUrl}/${editedBlog.user.image}`}
                    alt="profile"
                    width="150px"
                    style={{ height: '5rem', width: '25rem', margin: '20px 0', border: '1px solid blue', borderRadius: '5px', }}
                  />
                )} */}
              </div>
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </div>

            <div className="form-group">
              <label>{lang === "en" ? "Description" : "الوصف"}</label>
              <input
                type="text"
                className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                value={formik.values.description}
                onChange={formik.handleChange}
                id="description"
                placeholder={lang === "en" ? "Description" : "الوصف"}
                onBlur={formik.handleBlur}
              />
              <p className="text-danger ">
                {formik.touched.description &&
                  formik.errors["description"] &&
                  `*.` + formik.errors.description}
              </p>
            </div>
            <div className="form-group">
              <label>
                {lang === "en" ? "Keyword Description" : "وصف الكلمة المفتاحية"}
              </label>
              <input
                type="text"
                className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                value={formik.values.keywordDescription}
                onChange={formik.handleChange}
                id="keywordDescription"
                placeholder={
                  lang === "en" ? "Keyword Description" : "وصف الكلمة المفتاحية"
                }
                onBlur={formik.handleBlur}
              />
              <p className="text-danger ">
                {formik.touched.keywordDescription &&
                  formik.errors["keywordDescription"] &&
                  `*.` + formik.errors.keywordDescription}
              </p>
            </div>
            <div className="form-group d-flex flex-column">
              <label>{lang === "en" ? "Keywords" : "الكلمات المفتاحية"}</label>
              <div className="d-flex justify-conent-center align-items-center">
                <input
                  type="text"
                  className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                  value={singleKeword}
                  onChange={handleChangeSingleKeyWord}
                  id="Keyword"
                  placeholder={lang === "en" ? "Keywords" : "الكلمات المفتاحية"}
                  style={{ width: "90%" }}
                />
                <BsFillPlusCircleFill
                  className="justify-content-center align-items-center m-2 "
                  style={{ fontSize: "23px", color: "#2D62ED" }}
                  onClick={() => handleKeywords(singleKeword)}
                />
              </div>
              <div className=" d-flex  flex-wrap mt-3 h-auto" id="parent">
                {/* {editedBlog.keywords[0] && <p>{editedBlog.keywords.length}</p>} */}
                {editedBlog?.keywords[0] &&
                  editedBlog.keywords.map((keyword, index) => {
                    return (
                      <span
                        className="p-1 px-3 text-white m-3 rounded ms-2 position-relative"
                        key={index}
                        style={{ backgroundColor: "#6289F1" }}
                      >
                        <button
                          type="button"
                          onClick={() => deleteKeyword(keyword)}
                          className={`position-absolute bg-danger d-flex align-items-center justify-content-center text-white btn  ${
                            lang === "en" ? "end-0" : "start-0"
                          }`}
                          style={{
                            top: "-8px",
                            height: "20px",
                            width: "10px",
                          }}
                        >
                          X
                        </button>
                        {keyword}
                      </span>
                    );
                  })}
              </div>
            </div>

            <button
              type="submit"
              disabled={!blogType ? true : false}
              className={styles.submitButton}
            >
              {lang === "en" ? "Update" : "تحديث"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className=" d-flex  flex-wrap mt-3 h-auto" id='parent'>
  {editedBlog.keywords.map((keyword, index) => (
    <div
      className="bg-secondary p-1 px-3 text-white m-3 rounded ms-2"
      key={index}
      id={index}
      style={{ position: 'relative' }}
    >
      <AiFillCloseCircle onClick={(e) => handleClose(e, keyword, index)} style={{ position: 'absolute', top: '-7px', right: '-7px', fontSize: '25px', color: 'black', cursor: 'pointer' }} />
      {keyword}
    </div>
  ))}
</div> */
}
{
  /* <div className="">
  <div className={`${styles.formContainer}`}>

    <div className={`col-xs-12 ${styles.formBody}`}>

      <form
        onSubmit={formik.handleSubmit}
        className={` p-5 ${styles.formBody}`}
        style={{ width: "500px" }}
      >
        <div className={`${styles.iconWrapper}`}>

          <CgClose className={`${styles.addSymbol}`} />
        </div>
        <div className="form-group">

          <label>Title</label>
          <input
            type="text"
            className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
            value={formik.tilte}
            onChange={formik.handleChange}
            id="title"
            placeholder="title"
            onBlur={formik.handleBlur}

          />
          <p className="text-danger ">{formik.errors['title'] && `*.` + formik.errors.title}</p>

        </div>

        <div className="form-group">
          <label>Image URL</label>
          <input
            type="text"
            className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
            value={formik.image}
            onChange={formik.handleChange}
            id="image"
            placeholder="Image URL"
            onBlur={formik.handleBlur}
          />
          <p className="text-danger ">{formik.errors['image'] && `*.` + formik.errors.image}</p>

        </div>
        <div className="form-group">
          <label>Description</label>
          <input
            type="text"
            className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
            value={formik.Description}
            onChange={formik.handleChange}
            id="description"
            placeholder="Description"
            onBlur={formik.handleBlur}
          />
          <p className="text-danger ">{formik.errors['description'] && `*.` + formik.errors.description}</p>

        </div>
        <div className="form-group">
          <label>Keyword Description</label>
          <input
            type="text"
            className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
            value={formik.keywordDescription}
            onChange={formik.handleChange}
            id="keywordDescription"
            placeholder="Keyword Description"
            onBlur={formik.handleBlur}
          />
          <p className="text-danger ">{formik.errors['keywordDescription'] && `*.` + formik.errors.keywordDescription}</p>

        </div>
        <div className="form-group d-flex flex-column">
          <label>Keywords</label>
          <div className="d-flex justify-conent-center align-items-center">
            <input
              type="text"
              className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
             
              id="Keyword"
              placeholder="Keywords"
              style={{ width: "90%" }}
            />
            <BsFillPlusCircleFill className='justify-content-center align-items-center m-2 ' style={{ fontSize: '23px' }} onClick={() => {
              blogKeyword.length > 0 &&
                setEditedBlog((blog) => ({ ...blog, keywords: [...blog.keywords, blogKeyword] }))
              setBlogKeyword("")
            }} />
          </div>
          
        </div>

        <button type="submit" disabled={!blogType ? true : false} className="btn btn-primary ">
          submit
        </button>
      </form>
    </div>
  </div>
</div> */
}
export default EditBlog;
