import React from 'react'
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_CONTACT } from '../../../redux/types/types';

const ContactUs = () => {

  const dispatch = useDispatch()

  const deleteContact = (id) => {
    dispatch({type: DELETE_CONTACT, payload:id})
}

  const { contact } = useSelector((state) => state);


  const Contacts = contact.map((cont) => (
    <div key={cont.name} className='m-2 border d-flex flex-column p-3 justify-content-center align-items-center'>
            <AiFillDelete onClick={() => deleteContact(cont.name)} className='align-self-end' style={{color:'#e44d4d', fontSize:'20px'}}/>

        <h3>Name: {cont.name}</h3>
        <h6>Mail: {cont.mail}</h6>
        <h6>Phone: {cont.phone}</h6>
        <h6>message: {cont.message}</h6>
        <h6>service: {cont.service}</h6>
    </div>
  ))
  return (
    <div>
      {Contacts}
    </div>
  )
}

export default ContactUs