import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_SERVICE, GET_SERVICES } from "../../../redux/types/types";
import ServicesServices from "../../../httpServices/services.services";
import "./ReceiveServices.scss";
const ReceiveServices = () => {
  const [error, setError] = useState();
  const { services } = useSelector((state) => state.services);
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  useEffect(() => {
    if (services.length === 0) {
      const fetchServices = () => {
        ServicesServices.getServices(`receiveService`)
          .then((data) => {
            if (data) {
              dispatch({ type: GET_SERVICES, payload: data.data });
            }
          })
          .catch((error) => {
            setError(error.response.data.message_en);
          });
      };
      fetchServices();
    }
  }, [services, dispatch]);
  console.log(services)

  const deleteService = (id) => {
    ServicesServices.deleteService(`receiveService/${id}`).then((_) => {
      dispatch({ type: DELETE_SERVICE, payload: id });
    });
  };

  const theServices = services.map((service) => (
    <div
      key={service._id}
      className={`col-xl-auto col-lg-3 col-md-5 col-sm-9 bg-white m-2 p-3 d-flex flex-column justify-content-center align-items-center border`}
      style={{borderRadius:'15px'}}
          >
        
      <AiFillDelete
        onClick={() => deleteService(service._id)}
        className="align-self-end"
        style={{ color: "#e44d4d", fontSize: "20px" }}
      />

      <h6>{lang === 'en' ? `Name: ${service.userId?.fullName_en}` : `الإسم: ${service.userId?.fullName_ar}`}</h6>
      <h6>{lang === 'en' ? `Email: ` :"البريد الإلكتروني: "}{service.userId?.email}</h6>
      <h6>{lang === 'en' ? `Phone: ` : `رقم الجوال: `}{service.userId?.phone}</h6>
      <h6>{lang === 'en' ? `Description: `: `الوصف: `}{service.message}</h6>
    </div>
  ));
  return (
<div className="rec_services_page">
      <div className="row m-3 p-3 ">
      {error ? <h1>{error}</h1> : theServices}
    </div>
    </div>
  );
};

export default ReceiveServices;
