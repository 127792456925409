import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const PagesService = {
  getAllPages: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    return data;
  },
  getAllPagesForUser: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    return data;
  },
  getPageById: async function (endPoint, id) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}/${id}`);
    return data;
  },
  updatePage: async function (endPoint, id, res) {
    const { data } = await AxiosInstance.put(
      `${baseUrl}/${endPoint}/${id}`,
      res
    );
    return data;
  },
  addPage: async function (endPoint, reqsData) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}`,
      reqsData
    );
    return data;
  },
  deletePage: async function (endPoint, id) {
    const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}/${id}`);
    return data;
  },
  addSectionWithPage: async function (endPoint, IdPage, dataRE) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}/${IdPage}`,
      dataRE
    );
    return data;
  },
  addSubSectionWithPage: async function (endPoint, IdPage, dataRE) {
    console.log("dataRe: ", dataRE);
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}/${IdPage}`,
      dataRE
    );
    return data;
  },
  relatedSectionWithPage: async function (endPoint, IdPage, IdSection) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}/${IdPage}/${IdSection}`
    );
    return data;
  },
  deleteSectionFromPage: async function (endPoint, IdPage, IdSection) {
    const { data } = await AxiosInstance.delete(
      `${baseUrl}/${endPoint}/${IdPage}/${IdSection}`
    );
    return data;
  },
};

export default PagesService;
