const contact = [{
    name: 'Eminem',
    mail: 'Eminem@test.test',
    phone: '23465465',
    message: 'Look If you had One shot Or one opportunity To seize everything you ever wanted In one moment Would you capture it Or just let it slip?',
    service: 'hip hop'
},
{
    name: 'Nate',
    mail: 'NF@test.tset',
    phone: '054638268',
    message: 'Hey nate, How\'s life?',
    service: 'rap'
},
{
    name: 'Joji',
    mail: 'Joji@test.test',
    phone: '1132312',
    message: 'Cause sometimes I look in her eyes And that\'s where I find a glimpse of us',
    service: 'chill'
},
{
    name: 'Post Malone',
    mail: 'PM@test.test',
    phone: '1831255',
    message: 'I\'ve been fuckin\' hoes and poppin\' pillies Man, I feel just like a rockstar',
    service: 'hip hop'
},]

export default contact