import React, { useEffect, useState } from "react";
import "./reservedSections.scss";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import icon from "../../../assets/folder_icon.svg";

import {
  GET_ALL_ERS_SECTIONS,
  REMOVE_RESERVED_SECTION,
  REMOVE_RESERVED_SUB_FROM_RESERVED_SECTION,
} from "../../../redux/reducers/resSections_reducer/resSections_types";
import { BiPencil } from "react-icons/bi";
import {
  REMOVE_ALL_EXISTED_RESERVED_SECTIONS,
  REMOVE_ALL_EXISTED_RESERVED_SUBS,
} from "../../../redux/reducers/pages_reducer/page_types";
import SectionService from "../../../httpServices/sections.services";
import { publicUrl } from "../../../httpServices/httpServices";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import AddResSectionModel from "../../components/reserved/addResSectionModel/AddResSectionModel";
import EditReservedSectionModel from "../../components/reserved/edit_section/EditReservedSectionModel";
import { toast, ToastContainer } from "react-toastify";
const ReservedSectionsPage = () => {
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const { reservedSections } = useSelector((state) => state);

  useEffect(() => {
    if (!reservedSections[0]) {
      SectionService.getAllSections("section").then((res) => {
        if (res.sections) {
          dispatch({
            type: GET_ALL_ERS_SECTIONS,
            payload: res.sections.sections,
          });
        }
      });
    }
  }, [dispatch]);

  const removeSection = (section) => {
    SectionService.DeleteSection("section", section._id)
      .then((res) => {
        console.log(res);
        if (res.success) {
          dispatch({ type: REMOVE_RESERVED_SECTION, payload: section });
          dispatch({
            type: REMOVE_ALL_EXISTED_RESERVED_SECTIONS,
            payload: section,
          });
          console.log("section removed => ", res);
          toast.success(res.message_en);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const removeSub = (section, sub) => {
  //   SectionService.deleteSubSectionFromSection(
  //     "subSection",
  //     sub._id,
  //     section._id
  //   )
  //     .then((data) => {
  //       if (data.success) {
  //         dispatch({
  //           type: REMOVE_RESERVED_SUB_FROM_RESERVED_SECTION,
  //           payload: { section, sub },
  //         });
  //         dispatch({
  //           type: REMOVE_ALL_EXISTED_RESERVED_SUBS,
  //           payload: sub,
  //         });
  //       }
  //     })
  //     .catch((err) => {});
  // };
  // const [editModelReservedSub, setEditModelReservedSub] = useState({
  //   showing: false,
  //   sectionId: "",
  //   subId: "",
  // });
  // const openEditSubModel = (section, sub) => {
  //   setEditModelReservedSub({
  //     ...editModelReservedSub,
  //     showing: true,
  //     sectionId: section._id,
  //     subId: sub._id,
  //   });
  // };
  const [models, setModels] = useState({
    add: false,
    edit: { activiton: false, sectionId: "" },
  });
  const showEditModel = (section) => {
    setModels({
      ...models,
      edit: {
        activiton: true,
        sectionId: section._id,
      },
    });
  };

  return (
    <section className={`sections_page sections_style_${lang}`}>
      <ToastContainer />
      <div className="inner_container">
        <h3 className="heading">{lang === "en" ? "Sections" : "الاقسام"}</h3>
        <div className="sections_wrapper">
          {reservedSections.map((section) => (
            <div className="section" key={section._id}>
              <button
                className="delete_btn"
                onClick={() => removeSection(section)}
              >
                <MdOutlineDeleteOutline />
              </button>
              <button
                className="edit_btn"
                onClick={() => showEditModel(section)}
              >
                <BiPencil />
              </button>
              <NavLink
                className="route"
                // to={`/adminDashboard/sections/${section._id}`}
              >
                <div className="route-container">
                  <img src={icon} alt={section[`title_${lang}`]} />
                  {lang === "en" ? (
                    <h4>
                      {section?.title_en?.length > 18
                        ? `${section.title_en.slice(0, 18)}...`
                        : section.title_en}
                    </h4>
                  ) : (
                    <h4>
                      {section?.title_ar?.length > 18
                        ? `${section.title_ar.slice(0, 18)}...`
                        : section.title_ar}
                    </h4>
                  )}
                </div>
              </NavLink>
              {models.edit.activiton && (
                <EditReservedSectionModel
                  lang={lang}
                  setModels={setModels}
                  section={reservedSections.find(
                    ({ _id }) => _id === models.edit.sectionId
                  )}
                />
              )}
            </div>
          ))}
          <button
            className="add_btn"
            onClick={() => setModels({ ...models, add: true })}
          >
            <div>
              <FaPlus />
              <h4>{lang === "en" ? "Adding Section" : "إضافة قسم"}</h4>
            </div>
          </button>
        </div>
      </div>
      {models.add && (
        <AddResSectionModel
          lang={lang}
          setModels={setModels}
          reservedSections={reservedSections}
        />
      )}
    </section>
  );
};

export default ReservedSectionsPage;
