import contactUs from "../../../initialData/contact";
import { DELETE_OFFER } from "../../types/types";
const contactUsReducer = (state = contactUs, { type, payload }) => {

    switch (type) {

     
          case DELETE_OFFER:
            return state.filter((offer) => offer.name !== payload );
    
        default:
          return state;
      }
  }

export default contactUsReducer;
