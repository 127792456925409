import { AxiosInstance, baseUrl } from "../App";

const contactsServices = {
  getAllContacts: async function (endPoint) {
    try {
      const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
      if (data) return data;
    } catch (e) {
      console.log(e.message);
    }
  },
  deleteContacts: async function (endPoint) {
    try {
      const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}`);
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default contactsServices;
