import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../../../httpServices/user.service";
import { useNavigate } from "react-router";

const Password = () => {
  // const [user, setUser] = useState({});
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    

    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(6, "Password must be at least 6 letters")
        .max(30, "Password is too long")
        .required("Required!"),
      newPassword: Yup.string()
        .min(6, "Password must be at least 6 letters")
        .max(30, "Password is too long")
        .required("Required!"),
    }),
    onSubmit: (values) => {
      const changePassword = () => {
        UserService.updateUser(`user/me/password`, {password: values.oldPassword, newPass: values.newPassword}).then((value)=>value.data.message_en ? navigate(-1) :null)
      }
      changePassword()
      navigate(-1)
     }
  });

  // useEffect(() => {
  //   formik.setFieldValue("oldPassword", user.oldPassword);
  //   formik.setFieldValue("newPassword", user.newPassword);
  // }, [user]);

  // useEffect(() => {}, [formik]);

  return (
    <div style={{ height: "900px", width: "99%" }}>
      <form
        onSubmit={formik.handleSubmit}
        className="d-flex flex-column justify-content-center align-items-center h-100"
      >
        <div className="d-flex flex-column m-2">
          <label>
            <h6>Old Password</h6>
          </label>
          <input
            id="oldPassword"
            name="oldPassword"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
            onBlur={formik.handleBlur}
            style={{ width: "415px", borderRadius: "15px", height: "50px" }}
            className="border my-2"
          />
          {formik.touched.oldPassword && formik.errors.oldPassword ? (
            <p style={{ color: "red" }}>{formik.errors.oldPassword}</p>
          ) : null}
        </div>
        <div className="d-flex flex-column m-2">
          <label>
            <h6>New Password</h6>
          </label>
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.newPassword}
            onBlur={formik.handleBlur}
            style={{ width: "415px", borderRadius: "15px", height: "50px" }}
            className="border my-2"
          />
          {formik.touched.newPassword && formik.errors.newPassword ? (
            <p style={{ color: "red" }}>{formik.errors.newPassword}</p>
          ) : null}
        </div>
        <button className='px-3 py-1 border rounded m-3' type='submit'>Update Password</button>

      </form>
    </div>
  );
};

export default Password;
