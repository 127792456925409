import React, { useState } from "react";
import InputText from "../../forms/Input_text/InputText";
import { IoIosAddCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { ADD_RESERVED_SUB_TO_SUBS } from "../../../../redux/reducers/resSubs_reducer/resSubs_types";
import subSectionsService from "../../../../httpServices/subSection.services";
import UploadServices from "../../../../httpServices/httpServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputFile from "../../forms/input_file/InputFile";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillDelete,
} from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import "./addResSubModel.scss";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
const AddResSubModel = ({ setModels, lang }) => {
  const [errorMessage, setErrorMessage] = useState("");
  // const [newSub, setNewSub] = useState({
  //   title_en: "",
  //   title_ar: "",
  //   description_en: "",
  //   description_ar: "",
  //   image: "",
  //   keywordDescription: "",
  //   keywords: [],
  //   extras: [],
  // });
  // const dispatch = useDispatch();
  // const [subKeyword, setsubKeyword] = useState("");
  // const [newResSubImage, setNewResSubImage] = useState({});

  // const handleChange = (event) => {
  //   setNewSub({
  //     ...newSub,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // // const subIsExisted = reservedSubs.find(
  // //   ({ key_en }) => key_en === newSub.key_en
  // // );
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (newResSubImage) {
  //     const date = new FormData();
  //     date.append("image", newResSubImage);
  //     const resImage = await UploadServices.uploadFile("upload", date);
  //     newSub.image = resImage.filename;
  //   }
  //   subSectionsService
  //     .addNewSub("subSection", newSub)
  //     .then((res) => {
  //       if (res.subSection) {
  //         dispatch({
  //           type: ADD_RESERVED_SUB_TO_SUBS,
  //           payload: res.subSection,
  //         });
  //         setModel(false);
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response.data) {
  //         err.response.data[`message_${lang}`]
  //           ? setErrorMessage(err.response.data[`message_${lang}`])
  //           : setErrorMessage(err.response.data);
  //       }
  //     });
  // };
  // const keywordIsExisted = newSub.keywords.find((item) => item === subKeyword);
  // const addNewKeyword = () => {
  //   if (!keywordIsExisted && subKeyword.length > 0) {
  //     setNewSub({
  //       ...newSub,
  //       keywords: [...newSub.keywords, subKeyword],
  //     });
  //     setsubKeyword("");
  //   }
  // };

  // const [extraFields, setExtraFields] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });

  // const handleChangeExtraFields = (e) => {
  //   setExtraFields({
  //     ...extraFields,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const addNewExtraItem = () => {
  //   setNewSub({
  //     ...newSub,
  //     extras: [...newSub?.extras, extraFields],
  //   });
  //   setExtraFields({
  //     key_en: "",
  //     value_en: "",
  //     key_ar: "",
  //     value_ar: "",
  //   });
  // };
  // const deleteExtraItem = (selectItem) => {
  //   setNewSub({
  //     ...newSub,
  //     extras: newSub.extras.filter(
  //       ({ key_en }) => key_en !== selectItem.key_en
  //     ),
  //   });
  // };
  // const [modelEditExtraItem, setEditModelExtraItem] = useState({
  //   showing: false,
  //   selectItem: "",
  // });
  // const openModel = (item) => {
  //   setEditModelExtraItem((model) => ({
  //     ...model,
  //     showing: true,
  //     selectItem: item.key_en,
  //   }));
  // };
  // const [editItem, setEditItem] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });
  // const handleEditItem = (e) => {
  //   setEditItem({
  //     ...editItem,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  /*  return (

    <div className="add-sub-form position-absolute top-0 left-0 w-100 h-100 bg-white">
      <div style={{ marginTop: "150px" }} className="w-50 mx-auto text-end">
        <button
          className="btn bg-info text-white fw-bold text-uppercase"
          onClick={() => setModel(false)}
        >
          back
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className="mt-4"
        style={{ overflow: "scroll", height: "75vh" }}
      >
        {errorMessage && <p className="alert alert-absolute">{errorMessage}</p>}

        <h4 className="text-center">
          {lang === "en" ? "Add New Reserved Sub" : "اضافة قسم فرعي جديد"}
        </h4>
        <InputText
          label={lang === "en" ? "title english" : " اللقب بالنجلزي "}
          placeholder={lang === "en" ? "Sub Title" : "لقب القسم الفرعي"}
          type="text"
          name={`title_en`}
          value={newSub[`title_en`]}
          handleChange={handleChange}
        />
        <InputText
          label={lang === "en" ? "title arabic" : "اللقب بالعربي"}
          placeholder={lang === "en" ? "Sub Title" : "لقب القسم الفرعي"}
          type="text"
          name={`title_ar`}
          value={newSub[`title_ar`]}
          handleChange={handleChange}
        />
        <InputText
          label={lang === "en" ? "description english" : " الوصف بالنجلزي "}
          placeholder={lang === "en" ? "Sub Description" : "وصف القسم الفرعي"}
          type="text"
          name={`description_en`}
          value={newSub[`description_en`]}
          handleChange={handleChange}
        />
        <InputText
          label={lang === "en" ? "description arabic" : " الوصف بالعربي "}
          placeholder={lang === "en" ? "Sub Description" : "وصف القسم الفرعي"}
          type="text"
          name={`description_ar`}
          value={newSub[`description_ar`]}
          handleChange={handleChange}
        />
        <InputText
          label={lang === "en" ? "image link" : "رابط الصورة"}
          placeholder={lang === "en" ? "Image's Link" : "رابط الصورة"}
          name="image"
          type="file"
          handleChange={(e) => setNewResSubImage(e.target.files[0])}
        />
        <InputText
          label={lang === "en" ? "Keyword Description" : "وصف الكلمة الرئيسية"}
          placeholder="Sub Keyword Description"
          type="text"
          name="keywordDescription"
          value={newSub.keywordDescription}
          handleChange={handleChange}
        />
        <div className="mt-4">
          <label className="fs-5">{lang === "en" ? "Keywords" : "كلمات"}</label>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <input
              type="text"
              style={{ width: "90%" }}
              className="fs-5 py-2 px-3 rounded border border-dark"
              placeholder="Sub Keyword"
              value={subKeyword}
              onChange={(e) => setsubKeyword(e.target.value)}
            />
            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add-button"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {newSub.keywords.length > 0 && (
            <div className="mt-1 d-flex flex-wrap h-auto border border-dark pb-3">
              {newSub.keywords.map((keyword, index) => (
                <span
                  className="bg-info p-1 px-3 text-white mt-3 rounded ms-2 "
                  key={index}
                >
                  {keyword}
                </span>
              ))}
            </div>
          )}
          <h4 className="mt-3">
            {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
          </h4>
          <InputText
            label={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
            placeholder={
              lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"
            }
            type="text"
            name="key_en"
            value={extraFields.key_en}
            handleChange={handleChangeExtraFields}
          />
          <InputText
            label={lang === "en" ? "english value" : "القيمة الانجليزية"}
            placeholder={lang === "en" ? "english value" : "القيمة الانجليزية"}
            type="text"
            name="value_en"
            value={extraFields.value_en}
            handleChange={handleChangeExtraFields}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            placeholder={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="key_ar"
            value={extraFields.key_ar}
            handleChange={handleChangeExtraFields}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            placeholder={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="value_ar"
            value={extraFields.value_ar}
            handleChange={handleChangeExtraFields}
          />
          <button
            type="button"
            className="btn w-100 bg-info text-white fw-bold mt-3"
            onClick={addNewExtraItem}
          >
            {lang === "en" ? "add item" : "اضف عنصر"}
          </button>
          {newSub.extras.map((item, index) => (
            <div
              key={index}
              className="position-relative border border-dark mt-4"
            >
              <h4>{item[`key_${lang}`]}</h4>
              <h6>{item[`value_${lang}`]}</h6>
              <button
                className="position-absolute btn text-white d-flex align-items-center justify-content-center"
                type="button"
                style={{
                  top: "-10px",
                  right: "-10px",
                  height: "30px",
                  width: "15px",
                  backgroundColor: "#DC3545",
                }}
                onClick={() => deleteExtraItem(item)}
              >
                <span>X</span>
              </button>
              <button
                className="position-absolute btn text-white d-flex align-items-center justify-content-center"
                type="button"
                style={{
                  top: "-10px",
                  right: "20px",
                  height: "30px",
                  width: "15px",
                  backgroundColor: "#00B526",
                }}
                onClick={() => openModel(item)}
              >
                <span>&#10049;</span>
              </button>
              {modelEditExtraItem.showing &&
                modelEditExtraItem.selectItem === item.key_en && (
                  <div
                    className="position-absolute top-0 start-0 bg-danger "
                    style={{ zIndex: 4 }}
                  >
                    <InputText
                      label={
                        lang === "en" ? "english key" : "المفتاح الانجليزي"
                      }
                      name={`key_${lang}`}
                      value={editItem[`key_${lang}`]}
                      handleChange={handleEditItem}
                    />
                    <InputText
                      label={
                        lang === "en" ? "english value" : "القيمة الانجليزية"
                      }
                      name={`value_${lang}`}
                      value={editItem[`value_${lang}`]}
                      handleChange={handleEditItem}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setNewSub({
                          ...newSub,
                          extras: newSub.extras.map((current) =>
                            current.key_en === item.key_en ? editItem : current
                          ),
                        });
                        setEditItem({
                          ...editItem,
                          key_en: "",
                          key_ar: "",
                          value_en: "",
                          value_ar: "",
                        });
                      }}
                    >
                      {lang === "en" ? "Upddate item" : "تحديث العنصر"}
                    </button>
                    <button
                      className="position-absolute btn text-white d-flex align-items-center justify-content-center"
                      type="button"
                      style={{
                        top: "-10px",
                        right: "-10px",
                        height: "30px",
                        width: "15px",
                        backgroundColor: "black",
                        zIndex: "10",
                      }}
                      onClick={() =>
                        setEditModelExtraItem((model) => !model.showing)
                      }
                    >
                      <span>X</span>
                    </button>
                  </div>
                )}
            </div>
          ))}
          <button
            className="mt-5 w-100 py-2 px-2 fw-bold  btn btn-secondary"
            type="submit"
          >
            {lang === "en" ? "Add sub" : "اضافة قسم فرعي"}
          </button>
        </div>
      </form>
    </div>
  ); */
  const [newResSubImage, setNewResSubImage] = useState({});
  const formik = useFormik({
    initialValues: {
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      image: "",
      keywordDescription: "",
      keywords: [],
      extras: [],
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image: Yup.string(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
      // extras: Yup.array().min(
      //   1,
      //   lang === "en" ? "add extras item" : "اضف عنصر اكسترا"
      // ),
    }),
    onSubmit: async () => {
      if (pageImage) {
        const date = new FormData();
        date.append("image", pageImage);
        const resImage = await UploadServices.uploadFile("upload", date);
        newSub.image = resImage.filename;
      }
      subSectionsService
        .addNewSub("subSection", newSub)
        .then((res) => {
          if (res.subSection) {
            dispatch({
              type: ADD_RESERVED_SUB_TO_SUBS,
              payload: res.subSection,
            });
            removeAddSubModel();
            toast.success(res.messag_en);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data) {
            err.response.data[`message_${lang}`]
              ? setErrorMessage(err.response.data[`message_${lang}`])
              : setErrorMessage(err.response.data);
          }
        });
    },
  });
  const {
    values: newSub,
    setValues: setNewSub,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  
  const [pageImage, setPageImage] = useState(null);
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const uploadPageImg = (e) => {
    setPageImage(e.target.files[0]);
  };
  const removeUploadedImg = () => {
    setPageImage(null);
    setNewSub({
      ...newSub,
      image: "",
    });
  };
  const keywordIsExisted = newSub.keywords?.find((item) => item === keyword);
  const addNewKeyword = () => {
    if (!keywordIsExisted && keyword.length > 0) {
      setNewSub({
        ...newSub,
        keywords: [...newSub.keywords, keyword],
      });
      setKeyword("");
    } else {
      formik.setErrors({
        ...errors,
        keywords:
          lang === "en" ? "the keyword is existed" : "الكلمة المفتاحية موجودة",
      });
    }
  };
  const deleteKeyword = (item) => {
    setNewSub({
      ...newSub,
      keywords: newSub.keywords.filter((keyword) => keyword !== item),
    });
  };
  const removeAddSubModel = () => {
    setModels((models) => ({
      ...models,
      add: false,
    }));
  };
  const [extraFields, setExtraFields] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const [editItem, setEditItem] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const handleChangeExtraFields = (e) => {
    setExtraFields({
      ...extraFields,
      [e.target.name]: e.target.value,
    });
  };
  const valuesCompleted = Object.values(extraFields).every(
    (value) => value.length > 0
  );
  const itemExisted = newSub.extras.find(
    (item) =>
      item.key_en === extraFields?.key_en || item.key_en === editItem?.key_en
  );
  const addNewExtraItem = () => {
    if (valuesCompleted && itemExisted === undefined) {
      setNewSub({
        ...newSub,
        extras: [...newSub?.extras, extraFields],
      });
      setExtraFields({
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  const deleteExtraItem = (selectItem) => {
    setNewSub({
      ...newSub,
      extras: newSub.extras.filter(
        ({ key_en }) => key_en !== selectItem.key_en
      ),
    });
  };
  const [modelEditExtraItem, setEditModelExtraItem] = useState({
    showing: false,
    selectItem: "",
  });
  const openModel = (item) => {
    setEditModelExtraItem((model) => ({
      ...model,
      showing: true,
      selectItem: item.key_en,
    }));
  };
  const removeModel = () => {
    setEditModelExtraItem((model) => !model.showing);
    setEditItem({
      key_en: "",
      key_ar: "",
      value_en: "",
      value_ar: "",
    });
  };
  const handleEditItem = (e) => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.value,
    });
  };
  const changeExtrasItemData = (item) => {
    if (itemExisted === undefined) {
      setNewSub({
        ...newSub,
        extras: newSub.extras.map((current) =>
          current.key_en === item.key_en ? editItem : current
        ),
      });
      setEditItem({
        ...editItem,
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };

  return (
    <div className="add_res_sub_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
        >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeAddSubModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={newSub.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={newSub.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={newSub.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={newSub.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />

        {pageImage !== null ? (
          <div className="img-container">
            <img src={pageImage && URL.createObjectURL(pageImage)} alt="aa" />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={touched.image}
            handleChange={uploadPageImg}
          />
        )}
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={newSub.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border:
                  errors.keywords && touched?.keywords?.length < 1
                    ? "1px solid red"
                    : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onBlur={() => {
                formik.setTouched({ ...touched, keyword: true });
                if (newSub.keywords.length < 1) {
                  formik.setErrors({
                    ...errors,
                    keywords:
                      lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
                  });
                }
              }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && touched?.keywords?.length < 1 && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {newSub.keywords?.length > 0 && (
            <Box 
             component="div"
              sx={{
                height: "100px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: "#aac1fe",
                  transition: "0.5s all",
                  borderRadius: "15px",
                },

                " &::-webkit-scrollbar-thumb:hover": {
                  background: "#78a3e4",
                },
              }}
            
            className={` keywords_wrapper keywords_wrapper_${lang} `}>
              {newSub.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}
          <h5 className="mt-4 fw-bold  text-center">
            {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
          </h5>
          <InputText
            label={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
            type="text"
            name="key_en"
            value={extraFields.key_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "english value" : "القيمة الانجليزية"}
            type="text"
            name="value_en"
            value={extraFields.value_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="key_ar"
            value={extraFields.key_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="value_ar"
            value={extraFields.value_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <button
            type="button"
            className={`add_extras_item_btn ${
              errors.extras && touched.extras?.length === 0 && "error"
            }`}
            onClick={addNewExtraItem}
          >
            {lang === "en" ? "Add Item" : "اضف عنصر"}
          </button>
          {newSub.extras?.map((item, index) => (
            <div key={index} className="extras_item">
              {modelEditExtraItem.showing &&
              modelEditExtraItem.selectItem === item.key_en ? (
                <div className="edit_extra_item_model">
                  <InputText
                    label={
                      lang === "en" ? "english key" : "مفتاح اللغة العربية"
                    }
                    type="text"
                    name={`key_${lang}`}
                    value={lang === "en" ? editItem.key_en : editItem.key_ar}
                    placeholder={
                      lang === "en" ? "New english key" : "مفتاح عربي جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <InputText
                    label={lang === "en" ? "english value" : "القيمة العربية"}
                    type="text"
                    name={`value_${lang}`}
                    value={
                      lang === "en" ? editItem.value_en : editItem.value_ar
                    }
                    placeholder={
                      lang === "en"
                        ? "New english value"
                        : "قيمة اللغة الانجليزية جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <div className="edit-extras-item-model-btns">
                    <button
                      type="button"
                      onClick={() => changeExtrasItemData(item)}
                    >
                      <AiFillCheckCircle />
                    </button>
                    <button className="" type="button" onClick={removeModel}>
                      <AiFillCloseCircle />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h4>{item[`key_${lang}`]}</h4>
                  <h6>{item[`value_${lang}`]}</h6>
                  <button
                    className=" del-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => deleteExtraItem(item)}
                  >
                    <span>X</span>
                  </button>
                  <button
                    className=" edi-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => openModel(item)}
                  >
                    <span>&#10049;</span>
                  </button>
                </>
              )}
            </div>
          ))}
          <button
            className="mt-5 w-100 py-2 px-2 fw-bold  add_page_btn"
            type="submit"
          >
            {lang === "en" ? "Add Sub Section" : "اضافة قسم فرعي"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddResSubModel;
