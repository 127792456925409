import React, { useEffect, useState } from "react";
import "./reservationType.scss";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import AddTypeModal from "./addTypeModal/AddTypeModal";
import resTypesServices from "../../../httpServices/resTypes.services";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_RES_TYPES,
  DEL_RES_TYPES,
  GET_RES_TYPES,
} from "../../../redux/reducers/resTypes_reducer/resTypesReducer";
import { toast, ToastContainer } from "react-toastify";
const ReservationTypePage = () => {
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");
  const { resTypes } = useSelector((state) => state);
  const [modalAdd, setModalAdd] = useState(false);

  const addType = (newType) => {
    resTypesServices.adding(newType).then((res) => {
      if (res?.message_en) {
        dispatch({ type: ADD_RES_TYPES, payload: res?.recType });
        toast.success(res[`message_${lang}`]);
      }
    });
  };
  const deleteType = (item) => {
    resTypesServices.deleting(item).then((res) => {
      dispatch({ type: DEL_RES_TYPES, payload: item });
      toast.success(res[`message_${lang}`]);
    });
  };
  useEffect(() => {
    if (resTypes) {
      resTypesServices.fetching().then((res) => {
        if (res?.success) {
          dispatch({ type: GET_RES_TYPES, payload: res?.getAll });
        }
      });
    }
  }, [dispatch]);
  return (
    <div className={`resType_page resType_page_${lang}`}>
      <ToastContainer />
      <h3 className="heading">
        {lang === "en" ? "Reservation Types" : "أنواع الحجز"}
      </h3>

      <div className="d-flex flex-wrap">
        {resTypes[0]
          ? resTypes.map((type) => (
              <div class="type_card">
                <div
                  className="mx-auto bg-white d-flex align-items-center justify-content-center"
                  style={{
                    width: "95%",
                  }}
                >
                  <h3 class="card-title mt-3">
                    {type.name?.length > 16
                      ? `${type.name.slice(0, 16)}...`
                      : type.name}
                  </h3>
                  <button
                    className="delete_btn"
                    onClick={() => deleteType(type)}
                  >
                    <MdOutlineDeleteOutline />
                  </button>
                </div>
              </div>
            ))
          : undefined}
        <button className="add_btn" onClick={() => setModalAdd(true)}>
          <div>
            <FaPlus />
          </div>
        </button>
      </div>
      {modalAdd ? (
        <AddTypeModal setModalAdd={setModalAdd} addType={addType} />
      ) : undefined}
    </div>
  );
};

export default ReservationTypePage;
