import React from "react";
import { AiOutlineTwitter } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { IoLogoSnapchat } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./UserFooter.scss";
const UserFooter = ({ pages, lang }) => {
  return (
    <div className="footer-basic">
      <footer>
        <div className="social">
          <a
            href="https://www.instagram.com/drserafi/"
            target="_blank"
            rel="noreferrer"
          >
            <RiInstagramFill />
          </a>
          <a href="https://wa.me/0550090531" target="_blank" rel="noreferrer">
            <BsWhatsapp
              style={{ transform: lang === "ar" && "rotateY(180deg)" }}
            />
          </a>
          <a
            href="https://twitter.com/DrSerafiRehab"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineTwitter
              style={{ transform: lang === "ar" && "rotateY(180deg)" }}
            />
          </a>
          <a
            href="https://www.snapchat.com/add/drserafi?sender_web_id=9f182335-6cf5-409e-a210-4db21809c75e&device_type=desktop&is_copy_url=true"
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoSnapchat />
          </a>
        </div>
        <ul className="list-inline">
          {pages?.map((page) => {
            return (
              <li className="list-inline-item" key={page._id}>
                <Link className="a list-item-link" to={`/${page._id}`}>
                  {page[`title_${lang}`]}
                </Link>
              </li>
            );
          })}
        </ul>
        <p
          className="copyright"
          style={{ fontSize: "16px", fontWeight: "bold" }}
        >
          {lang === "en" ? "all rights reserved to" : "كل الحقوق محفوظة لدي"}
          <a
            href="https://www.sarri.sa/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#444", textDecoration: "none" }}
          >
            {lang === "en" ? "Sari tech" : " صاري للتكنولوجيا"}
          </a>{" "}
          © 2022
        </p>
      </footer>
    </div>
  );
};

export default UserFooter;
