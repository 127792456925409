import React, { useEffect, useState } from "react";
import "./AddSubModel.scss";
import { Link } from "react-router-dom";
import { PUSH_SUB_TO_RESERVED_SECTION } from "../../../../redux/reducers/resSections_reducer/resSections_types";
import { ADD_NEW_SUB } from "../../../../redux/reducers/pages_reducer/page_types";
import { useDispatch, useSelector } from "react-redux";
import subSectionsService from "../../../../httpServices/subSection.services";
import { GET_ALL_RESERVED_SUB } from "../../../../redux/reducers/resSubs_reducer/resSubs_types";
import SectionService from "../../../../httpServices/sections.services";
import AddSubForm from "../../../pages/adding_subForm/AddSubForm";
import { toast } from "react-toastify";
const AddSubModel = ({
  addSubModel,
  setAddSubModel,
  adminCurrentPage,
  adminSection,
  lang,
}) => {
  const [selectedValue, setSelectedValue] = useState("Select Sub Section");
  const dispatch = useDispatch();
  const { reservedSubs } = useSelector((state) => state);
  const [message, setMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [form, setForm] = useState(false);

  const newSub = reservedSubs?.find(
    (res_sub) => res_sub[`title_${lang}`] === selectedValue
  );
  useEffect(() => {
    if (!reservedSubs[0]) {
      subSectionsService.getAllsubSections("subSection").then((data) => {
        if (data.Subsections) {
          dispatch({
            type: GET_ALL_RESERVED_SUB,
            payload: data.Subsections.Subsections,
          });
        }
      });
    }
  }, [dispatch]);
  const matchingWord = ["offer", "service", "team"]
    .map((word) => adminSection?.title_en.includes(word))
    .includes(true);

  const addSubSection = () => {
    if (
      adminSection?.subSections.find(
        (res_sub) => res_sub[`title_${lang}`] === selectedValue
      )
    ) {
      setAlertColor("red");
      if (lang === "en") return setMessage(`${selectedValue} Sub is existed`);
      else return setMessage(`القسم الفرعي ${selectedValue} موجود`);
    } else if (matchingWord && !newSub.extras[0]) {
      if (lang === "en")
        setMessage("this sub Section is not available for adding");
      else setMessage("هذا القسم الفرعي لا يمكن اضافته");
      setAlertColor("red");
    } else if (!matchingWord && newSub?.extras[0]) {
      if (lang === "en")
        setMessage("this sub Section is not available for adding");
      else setMessage("هذا القسم الفرعي لا يمكن اضافته");
      setAlertColor("red");
    } else {
      SectionService.raletedsubSectionWithSection(
        "subSection",
        newSub?._id,
        adminSection?._id
      )
        .then((data) => {
          console.log(data);
          console.log(newSub);
          if (data.success) {
            dispatch({
              type: ADD_NEW_SUB,
              payload: { adminSection, newSub },
            });
            dispatch({
              type: PUSH_SUB_TO_RESERVED_SECTION,
              payload: { adminSection, newSub },
            });
            setAlertColor("#07BC0C");
            setMessage(data[`message_${lang}`]);
            toast.success(data[`message_${lang}`])
          }
        })
        .catch((error) => {
          if (error && error.response && error.response.data) {
            if (error.response.data[`message_${lang}`]) {
              setMessage(error.response.data[`message_${lang}`]);
            } else {
              setMessage(error.response.data);
            }
            setAlertColor("red");
          }
        });
    }
  };
  return !form ? (
    <div className="add-sub-model d-flex align-items-center justify-content-center">
      <div
        className={`elements rounded py-5 w-25  position-relative ${
          addSubModel && "showing"
        } `}
      >
        <button
          className="cancel-model btn btn-danger"
          onClick={() => setAddSubModel(false)}
        >
          <span>🗙</span>
        </button>
        <h4 className="text-center mb-5">
          {lang === "en" ? "Select Sub Section" : "اختر قسم فرعي"}
        </h4>
        <select
          className="w-50 mx-auto d-block rounded p-1"
          value={selectedValue}
          onChange={(e) => {
            setSelectedValue(e.target.value);
            setMessage("");
          }}
        >
          <option selected hidden>
            {lang === "en" ? "select sub" : "اختار قسم فرعي"}
          </option>
          {reservedSubs.map((res_sub) => (
            <option key={res_sub._id} value={res_sub[`title_${lang}`]}>
              {res_sub[`title_${lang}`]}
            </option>
          ))}
        </select>
        <button
          style={{ marginTop: "180px", backgroundColor: "#2D62ED" }}
          className="btn text-white d-block w-75 mx-auto"
          onClick={addSubSection}
        >
          {lang === "en" ? "submit" : "تقديم"}
        </button>
        <div
          className="mt-3 text-center d-flex align-items-center justify-content-center "
          style={{
            height: "30px",
            color: alertColor,
            fontWeight: "500",
          }}
        >
          {message}
        </div>
        <div className={`or-word or-word_${lang}`}></div>

        <div className="form_btn">
          <button onClick={() => setForm(true)}>
            {lang === "en" ? "Add new sub section" : "اضافة قسم فرعي"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <AddSubForm
      lang={lang}
      adminCurrentPage={adminCurrentPage}
      adminSection={adminSection}
      setAddSubModel={setAddSubModel}
      setForm={setForm}
    />
  );
};

export default AddSubModel;
