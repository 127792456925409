import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import contactsServices from '../../../httpServices/contact.services'
import { GET_SUGGESTIONS } from '../../../redux/types/types'

const SuggestionMessage = () => {
  const { suggestionId } = useParams()
  const { suggestions } = useSelector((state) => state.complains)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!suggestions[0]) {
      contactsServices
        .getAllContacts(`contact/filter/query/?type=suggestion`)
        .then((data) => {
          if (data) {
            dispatch({ type: GET_SUGGESTIONS, payload: data.contacts })
          }
        })
    }
  }, [])
  const suggestionMessage = suggestions.find((suggestion) => {
    return suggestion._id === suggestionId
  })
  return (
    <div className="d-flex justify-content-center align-items-center m-5 p-5">
      {
        <div
          key={suggestionMessage?._id}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <h1 className="p-5">Suggestion</h1>
          <p> {suggestionMessage?.message} </p>
        </div>
      }
    </div>
  )
}

export default SuggestionMessage
