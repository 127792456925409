import React, { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { publicUrl } from "../../../httpServices/httpServices";
const AdminSubSingle = () => {
  const lang = localStorage.getItem("lang");
  const { pages } = useSelector((state) => state);
  const { pageId, sectionId, subId } = useParams();
  const adminCurrentPage = pages.find(({ _id }) => _id === pageId);
  const adminCurrentSection = adminCurrentPage?.sections?.find(
    ({ _id }) => _id === sectionId
  );
  const adminCurrentSub = adminCurrentSection?.subSections?.find(
    ({ _id }) => _id === subId
  );

  return (
    <div
      className="vh-100 d-flex align-items-center justify-content-center "
      style={{ backgroundColor: "#5BDDF7" }}
    >
      <div
        className="details-container bg-white rounded"
        style={{
          width: "65%",
          height: "70vh",
          padding: "30px",
        }}
      >
        <div className="row h-100">
          <div className="col-8 h-100 d-flex align-items-center">
            <img
              src={`${publicUrl}/${adminCurrentSub.image}`}
              style={{ height: "100%", width: "100%" }}
              alt="img"
            />
          </div>
          <div className="col-4 ">
            <div clasName="w-75 mx-auto">
              <h3>{adminCurrentSub[`title_${lang}`]}</h3>
              <p>{adminCurrentSub[`description_${lang}`]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubSingle;
