import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import "./Profile.scss";
import { SlCamera } from "react-icons/sl";
import UserService from "../../../httpServices/user.service";
import { AiFillDelete, AiOutlineRollback } from "react-icons/ai";
import UploadServices, { publicUrl } from "../../../httpServices/httpServices";
import { useDispatch, useSelector } from "react-redux";
import OfferService from "../../../httpServices/offers.services";
import ServicesServices from "../../../httpServices/services.services";
import {
  ALL_OFFERS,
  DELETE_OFFER,
  DELETE_SERVICE,
  GET_SERVICES,
  UPDATE_USER,
} from "../../../redux/types/types";
import { toast, ToastContainer } from "react-toastify";

// import axios from "axios";

const Profile = () => {
  const dispatch = useDispatch();
  const { offers } = useSelector((state) => state.offers);
  // const { services } = useSelector((state) => state.services);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (offers.length === 0) {
      const fetchOffers = () => {
        OfferService.getOffers("receiveOffer/user")
          .then((data) => {
            if (data) {
              dispatch({ type: ALL_OFFERS, payload: data.data });
            }
          })
          .catch((error) => {
            const message = error.response.data.message_en;

            setError(message);
          });
      };
      fetchOffers();
    }
  }, [offers, dispatch]);

  // useEffect(() => {
  // if (services.length === 0) {
  //   const fetchServices = () => {
  //     ServicesServices.getServices(`receiveService/user`)
  //       .then((data) => {
  //         if (data) {
  //           dispatch({ type: GET_SERVICES, payload: data.data });
  //         }
  //       })
  //       .catch((error) => {
  //         setError(error.response.data.message_en);
  //       });
  //   };
  //   fetchServices();
  // }
  // }, [services, dispatch]);

  const deleteOffer = (id) => {
    OfferService.deleteOffer(`receiveOffer/user/${id}`).then((_) => {
      dispatch({ type: DELETE_OFFER, payload: id });
    });
  };

  // const deleteService = (id) => {
  //   ServicesServices.deleteService(`receiveService/user/${id}`).then((_) => {
  //     dispatch({ type: DELETE_SERVICE, payload: id });
  //   });
  // };

  const [user, setUser] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const getME = async () => {
    UserService.getUser("user/me").then((value) => {
      setUser(value.user);
      formik.setFieldValue("fullName", value.user.fullName_en);
      formik.setFieldValue("email", value.user.email);
      formik.setFieldValue("phone", value.user.phone);
      formik.setFieldValue("image", value.user.image);
    });
  };
  const handleUpdateUser = async () => {
    let resImage;
    if (file) {
      const data = new FormData();
      data.append("image", file);
      resImage = await UploadServices.uploadFile("upload", data);
    }
    UserService.updateUser(`user/${user._id}`, {
      fullName_en: formik.values.fullName,
      phone: formik.values.phone,
      image: resImage?.filename ? resImage?.filename : formik.values.image,
    })
      .then((value) => {
        toast.success(value.message_en);
        dispatch({ type: UPDATE_USER, payload: value.user });
      })
      .catch((err) => {
        if (err.response.data) {
          err.response.data[`message_${lang}`]
            ? setErrorMessage(err.response.data[`message_${lang}`])
            : setErrorMessage(err.response.data);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      fullName: "Loading...",
      email: "Loading...",
      phone: "Loading...",
    },

    validationSchema: Yup.object({
      fullName: Yup.string().max(35, "الإسم طويل ").min(4, "الإسم قصير "),
      phone: Yup.number().typeError("يجب أن يكون رقم هاتف صحيح"),
    }),
    onSubmit: (values) => {
      // check if user change the image

      if (
        user.fullName !== formik.values.fullName ||
        user.phone !== formik.values.phone
      ) {
        // call update method
        // updateProfileData(values)
        // setFile(null)

        handleUpdateUser();
      }
    },
  });

  useEffect(() => {
    getME();
  }, []);

  // useEffect(() => {
  // }, [formik]);

  return (
    <div>
      <ToastContainer />
      <div>
        <div className="d-flex flex-row py-5">
          <div className="container d-flex flex-column  pb-3 shadow profile_inner">
            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn"
                style={{ backgroundColor: "#5BA0D4" }}
                onClick={() => navigate(-1)}
              >
                <AiOutlineRollback fill="#fff" />
              </button>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="d-flex flex-column  align-items-center profile_form"
            >
              {/* photo */}
              <div className="wrapper mb-5">
                <div className="btnimg">
                  {!file ? (
                    <img
                      src={`${publicUrl}/${user.image}`}
                      alt="profile"
                      width="150px"
                      style={{ height: "150px", borderRadius: "50%" }}
                    />
                  ) : (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="profile"
                      width="150px"
                      style={{ height: "150px", borderRadius: "50%" }}
                    />
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <div className="d-flex flex-row w-100">
                <div className="d-flex flex-column profile_input_container">
                  <label>
                    <h6>{lang === "en" ? "Name" : "الاسم"}</h6>
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                    onBlur={formik.handleBlur}
                    className="border my-2 p-3"
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <p style={{ color: "red" }}>{formik.errors.fullName}</p>
                  ) : null}
                </div>
              </div>

              <div className="d-flex flex-row w-100">
                <div className="d-flex flex-column profile_input_container">
                  <label>
                    <h6>{lang === "en" ? "phone" : "رقم الجوال"}</h6>
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                    className="border my-2 p-3"
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <p style={{ color: "red" }}>{formik.errors.phone}</p>
                  ) : null}
                </div>
              </div>
              <div className="d-flex flex-column w-100 profile_input_container">
                <label>
                  <h6>{lang === "en" ? "Email" : "البريد الالكتروني"}</h6>
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  disabled
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  className="border my-3 p-3"
                />
                {formik.touched.email && formik.errors.email ? (
                  <p style={{ color: "red" }}>{formik.errors.email}</p>
                ) : null}
              </div>
              <div className="update_links">
                <Link
                  to={`${pathname}/update-email`}
                  className="border py-2 rounded bg-secondary m-3"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "35px",
                  }}
                >
                  {lang === "en" ? "Update Email" : "تحديث البريد الالكتروني"}
                </Link>

                <Link
                  to={`${pathname}/update-password`}
                  className="border  py-2 rounded bg-secondary m-3"
                  style={{
                    color: "white",
                    textDecoration: "none",
                    padding: "20px",
                  }}
                >
                  {lang === "en" ? "Update password" : "تحديث كلمة المرور"}
                </Link>
              </div>

              <button
                type="submit"
                className="px-5 m-4"
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  border: "none",
                  backgroundColor: "#5ba0d4",
                  color: "white",
                }}
              >
                {lang === "en" ? "Save changes" : "حفظ التعيرات"}
              </button>
            </form>
            <div>
              <div className="border p-4 w-100 ">
                <h2 className="text-center">
                  {lang === "en" ? "Offers" : "العروض"}
                </h2>
                <div className="d-md-flex d-block justify-content-evenly">
                  {offers.map((offer) => (
                    <div
                      key={offer._id}
                      className="mt-4 p-3 border position-relative pb-5"
                      style={{ height: "350px" }}
                    >
                      <div className="d-flex justify-content-end pb-2">
                        <AiFillDelete
                          onClick={() => deleteOffer(offer._id)}
                          className="align-self-end"
                          style={{
                            color: "#e44d4d",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                      {lang === "en" ? (
                        <h4>Title: {offer.offerId.title_en}</h4>
                      ) : (
                        <h4>العنوان: {offer.offerId.title_ar}</h4>
                      )}
                      {lang === "en" ? (
                        <h5>Description: {offer.offerId.description_en}</h5>
                      ) : (
                        <h5>الوصف: {offer.offerId.description_ar}</h5>
                      )}
                      {lang === "en" ? (
                        <h5>Type: {offer.typeRec}</h5>
                      ) : (
                        <h5>نوع الحجز: {offer.typeRec}</h5>
                      )}

                      {/* {offer.offerId.extras.map((extra) => (
                        <div>
                          {lang === "en" ? (
                            <h6>
                              {extra.key_en} : {extra.value_en}
                            </h6>
                          ) : (
                            <h6>
                              {extra.key_ar} : {extra.value_ar}
                            </h6>
                          )}
                        </div>
                      ))} */}
                      <ul class="list-group list-group-flush m-0 p-0 w-100 border d-flex ">
                        {offer.offerId?.extras?.length > 0 &&
                          offer.offerId?.extras
                            .slice(0, 3)
                            .map((extraItem, idx) => (
                              <li
                                key={idx}
                                className="list-group-item  border-0 p-2 text-center "
                              >
                                <h6 className="m-0 fw-bold">
                                  {extraItem[`key_${lang}`]}
                                </h6>
                                <p className="m-0">
                                  {extraItem[`value_${lang}`]}
                                </p>
                              </li>
                            ))}
                      </ul>

                      <div>
                        <div
                          className={`pending_btn border rounded py-2 px-3 ${
                            offer.status ? "bg-success" : "bg-warning"
                          } text-white`}
                        >
                          {offer.status
                            ? lang === "en"
                              ? "Accepted"
                              : "موافق عليه"
                            : lang === "en"
                            ? "Pending"
                            : "معلق"}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* <div className="border p-4">
                <h2 style={{ textAlign: "center" }}>services</h2>
                {services.map((service) => (
                  <div
                    key={service._id}
                    className="m-4 border d-flex flex-column p-3 justify-content-center align-items-center"
                  >
                    <AiFillDelete
                      onClick={() => deleteService(service._id)}
                      className="align-self-end"
                      style={{ color: "#e44d4d", fontSize: "20px" }}
                    />
                    {lang === "en" ? (
                      <h4>Title: {service.serviceId.title_en}</h4>
                    ) : (
                      <h4>العنوان: {service.serviceId.title_ar}</h4>
                    )}
                    {lang === "en" ? (
                      <h4>Description: {service.serviceId.description_en}</h4>
                    ) : (
                      <h4>الوصف: {service.serviceId.description_ar}</h4>
                    )}
                    {service.serviceId.extras.map((extra) => (
                      <div>
                        {lang === "en" ? (
                          <h6>
                            {extra.key_en} : {extra.value_en}
                          </h6>
                        ) : (
                          <h6>
                            {extra.key_ar} : {extra.value_ar}
                          </h6>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
