import React from "react";
import InputText from "../../../components/forms/Input_text/InputText";
import "./AddTypeModal.scss";

import { useDispatch } from "react-redux";

import { RiCloseFill } from "react-icons/ri";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
const AddTypeModal = ({ setModalAdd, addType }) => {
  const lang = localStorage.getItem("lang");

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: () => {
      addType(values);
      setModalAdd(false);
    },
    validationSchema: Yup.object({
      name: Yup.string().required(
        lang === "en" ? "name required" : "الأسم مطلوب"
      ),
    }),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formik;
  const removeModal = () => {
    setModalAdd(false);
  };
  return (
    <div className="add_type_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {/* {errorMessage && <p className="alert alert-danger">{errorMessage}</p>} */}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeModal}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "Name" : "الأسم"}
          type="text"
          name="name"
          value={values.name}
          handleChange={handleChange}
          error={errors.name}
          isTouched={touched.name}
          handleBlur={handleBlur}
        />
        <div className="d-flex align-items-center justify-content-center gap-3">
          <button
            className="mt-5 py-2 px-2 fw-bold  add_page_btn"
            type="submit"
          >
            {lang === "en" ? "Add Type" : "إضافة نوع"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTypeModal;
