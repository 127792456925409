import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogServices from "../../../../httpServices/blogs.services";
import { EDIT_BLOG } from "../../../../redux/types/types";

function Like({ object }) {
  // const { blogs } = useSelector(state => state.blogs)
  const [like, setLike] = useState(false);
  let { blog } = object;

  let timerId;
  const handleonMouseUp = () => {
    clearTimeout(timerId);
  };
  // sent as props
  const likeToggeled = (like) => {
    return like ? false : true;
  };
  const handleMouseDown = (blogId) => {
    timerId = setTimeout(() => {
      setLike((_) => likeToggeled(like));
      // console.log('isLike: ', likeToggeled(isLike))
    }, 500);
  };
  const dispatch = useDispatch();
  const makeLikeOnBlog = (blogId, likeType) => {
    BlogServices.addLike(`blog/addLike/${blogId}`, likeType).then((value) => {
      dispatch({ type: EDIT_BLOG, payload: value.blog });
      setLike((_) => false);
    });
  };
  const handleLike = (e, blogId) => {
    const emoji = e.target.innerHTML;
    let like = "";

    switch (emoji) {
      case "👍":
        like = "like";
        break;
      case "❤️️":
        like = "love";
        break;
      case "🧡":
        like = "care";
        break;
      case "😂":
        like = "smile";
        break;
      case "😠":
        like = "angry";
        break;
      default:
        return;
    }

    // set like on blog
    makeLikeOnBlog(blogId, like);
  };
  const handleWhichEmojiGetsChoosed = (emojiString) => {
    const emojis = {
      love: "❤️️",
      care: "️🧡",
      smile: "😂️",
      angry: "😠️",
      like: "👍️",
    };

    return emojis[emojiString];
  };
  const lang = localStorage.getItem("lang");
  return (
    <div className="d-flex flex-column">
      <div className={`btn__wrapper btn__wrapper_${lang}`}>
        <button
          className="btn btn-lg"
          style={{
            background: "transparent",
            borderRadius: "5px",
            padding: "5px 30px",
            color: "#aaa",
            fontSize: "25px",
          }}
          onMouseUp={handleonMouseUp}
          onMouseDown={() => handleMouseDown(blog._id)}
        >
          {lang === "en" ? "Likes" : "الاعجابات"}
          <span>
            {blog?.likes?.length}
            {blog.likes.length > 0 &&
              [
                ...new Set(
                  Object.values(blog.likes.map((like) => like.likeType))
                ),
              ].map((like, index) => {
                return (
                  <span key={index}>{handleWhichEmojiGetsChoosed(like)}</span>
                );
              })}
          </span>
        </button>
      </div>

      <div>
        {like && (
          <div className="">
            <span
              name="like"
              value="like"
              style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer" }}
              onClick={(e) => handleLike(e, blog._id)}
            >
              👍
            </span>
            <span
              name="love"
              value="love"
              style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer" }}
              onClick={(e) => handleLike(e, blog._id)}
            >
              ❤️️
            </span>
            <span
              name="care"
              value="care"
              style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer" }}
              onClick={(e) => handleLike(e, blog._id)}
            >
              🧡
            </span>
            <span
              name="laugh"
              value="laugh"
              style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer" }}
              onClick={(e) => handleLike(e, blog._id)}
            >
              😂
            </span>
            <span
              name="angry"
              value="angry"
              style={{ fontSize: "20px", margin: "0 10px", cursor: "pointer" }}
              onClick={(e) => handleLike(e, blog._id)}
            >
              😠
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Like;
