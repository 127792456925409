import React, { useState } from "react";
import "./login.css";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import UserService from "../../../httpServices/user.service";
import { AxiosInstance } from "../../../App";
import { Link, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export default function Login() {
  const lang = localStorage.getItem("lang");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const chooseWhereToGo = (user) => {
    if (user.role === "admin" || user.role === "super admin") {
      navigate("/adminDashboard");
    } else {
      const prevURL = search.get("url");
      prevURL ? navigate(`${prevURL}`) : navigate("/");
    }
  };
  const loginUser = (logedData) => {
    UserService.loginUser("user/login", logedData)
      .then((data) => {
        localStorage.setItem("token", data.userInfo.token);
        AxiosInstance.defaults.headers.common["Authorization"] =
          data.userInfo.token;
        // AxiosInstance.defaults.headers.common["Authorization"] = data.userInfo.token;
        chooseWhereToGo(data.userInfo.user);
        toast.success(data?.message_en);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message_en);
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    loginUser({ email, password });
  };
  return (
    <div className="container-fluid">
      <ToastContainer />
      <div className="row login">
        <div className="col-md-6 login__image"></div>
        <div className="col-md-6  ">
          <form action="" onSubmit={handleSubmit}>
            <div className="login__info">
              <div className="login__wrapper">
                <h1 className="text-center">
                  {lang === "en" ? `Login` : "تسجيل الدخول"} <span></span>
                </h1>
                <div className="form-group my-5 ">
                  <label className={lang === "ar" && "text-end"}>
                    {lang === "en" ? `Email` : "البريد الإلكتروني"}
                  </label>
                  <input
                    type="text"
                    className="form-control p-4"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group my-5">
                  <label className={lang === "ar" && "text-end"}>
                    {lang === "en" ? `Password` : "كلمة المرور"}
                  </label>
                  <input
                    type="password"
                    className="form-control p-4"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-start">
                  <p>
                    {lang === "en"
                      ? ` Don't have account? `
                      : ` ليس لديك حساب؟ `}
                  </p>
                  <Link
                    style={{ padding: "0 5px", textDecoration: "underline" }}
                    to={"/signup"}
                  >
                    {" "}
                    {lang === "en" ? " sign up now! " : " سجل الآن "}{" "}
                  </Link>
                </div>
                <div className="buttonWrapper">
                  <button className="btn  btn-lg ">
                    {lang === "en" ? `Login` : `تسجيل الدخول`}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
