import React, { useEffect, useState } from "react";
import "./AddSectionModel.scss";
import { Link } from "react-router-dom";
import { ADD_NEW_SECTION } from "../../../../redux/reducers/pages_reducer/page_types";
import { useSelector, useDispatch } from "react-redux";
import SectionService from "../../../../httpServices/sections.services";
import { GET_ALL_ERS_SECTIONS } from "../../../../redux/reducers/resSections_reducer/resSections_types";
import PagesService from "../../../../httpServices/pages.services";
import AddSectionForm from "../../../pages/adding_sectionForm/AddSectionForm";
import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";
const AddSectionModel = ({ setModel, adminCurrentPage, lang }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const reservedSections = useSelector((state) => state).reservedSections;
  const [colorAlert, setColorAlert] = useState("");
  const [form, setForm] = useState(false);
  const newSection = reservedSections?.find(
    (section) => section[`title_${lang}`] === selectedValue
  );
  useEffect(() => {
    if (!reservedSections[0]) {
      SectionService.getAllSections("section").then((res) => {
        if (res.sections) {
          dispatch({
            type: GET_ALL_ERS_SECTIONS,
            payload: res.sections.sections,
          });
        }
      });
    }
  }, [dispatch]);
  const handleSubmit = () => {
    // if (
    //   adminCurrentPage?.sections?.find(
    //     (section) => section[`title_${lang}`] === selectedValue
    //   )
    // ) {
    //   lang === "en"
    //     ? setMessage(`${selectedValue} section is existed`)
    //     : setMessage(`قسم ${selectedValue} موجود`);
    //   setColorAlert("red");
    // } else {
    //   PagesService.relatedSectionWithPage(
    //     "section",
    //     adminCurrentPage?._id,
    //     newSection?._id
    //   )
    //     .then((data) => {
    //       if (data.section) {
    //         dispatch({
    //           type: ADD_NEW_SECTION,
    //           payload: {
    //             newSection: data.section,
    //             adminCurrentPage: data.page,
    //           },
    //         });
    //         toast.success(data[`message_${lang}`]);
    //         // setMessage(data[`message_${lang}`]);
    //         setColorAlert("#5BA0CC");
    //       }
    //     })
    //     .catch((err) => {
    //       setMessage(err.response.data[`message_${lang}`]);
    //       setColorAlert("red");
    //       toast.error(err.response.data[`message_${lang}`]);
    //       console.log("error :",err)
    //     });
    // dispatch({
    //   type: ADD_NEW_SECTION,
    //   payload: { newSection:newSection, adminCurrentPage },
    // });
    PagesService.relatedSectionWithPage(
      "section",
      adminCurrentPage?._id,
      newSection?._id
    )
      .then((data) => {
        if (data.section) {
          dispatch({
            type: ADD_NEW_SECTION,
            payload: {
              newSection: data.section,
              adminCurrentPage: data.page,
            },
          });
          toast.success(data[`message_${lang}`]);
          setMessage(data[`message_${lang}`]);
          setColorAlert("#07BC0C");
        }
      })
      .catch((err) => {
        setMessage(err.response.data[`message_${lang}`]);
        setColorAlert("red");
        toast.error(err.response.data[`message_${lang}`]);
      });
  };
  return !form ? (
    <div className="add-sections-model d-flex align-items-center justify-content-center">
      <div className="elements rounded py-5 position-relative">
        <button
          className="cancel-model btn btn-danger"
          onClick={() => setModel(false)}
        >
          <RiCloseFill />
        </button>
        <h4 className="text-center mb-5">
          {lang === "en" ? "Select Section" : "اختر قسم"}
        </h4>
        <select
          className="w-50 mx-auto d-block border border-dark rounded p-1"
          value={selectedValue}
          onChange={(e) => {
            setSelectedValue(e.target.value);
            setMessage("");
          }}
        >
          <option selected hidden>
            {lang === "en" ? "select section" : "اختار قسم"}
          </option>
          {reservedSections.map((section) => (
            <option key={section._id} value={section[`title_${lang}`]}>
              {section[`title_${lang}`]}
            </option>
          ))}
        </select>
        <button
          style={{ marginTop: "180px", backgroundColor: "#2D62ED" }}
          className="btn  text-white d-block w-75 mx-auto"
          onClick={handleSubmit}
        >
          {lang === "en" ? "Submit" : "تقديم"}
        </button>
        <div
          className="mt-3 text-center d-flex align-items-center justify-content-center "
          style={{
            height: "30px",
            color: colorAlert,
            fontWeight: "500",
          }}
        >
          {message}
        </div>
        <div className={`or-word or-word_${lang}`}></div>

        <div className="form_btn">
          <button
            onClick={() => {
              setForm(true);
              setMessage("");
            }}
          >
            {lang === "en" ? "Add new section" : "اضافة قسم جديد"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <AddSectionForm setModel={setModel} setForm={setForm} />
  );
};
export default AddSectionModel;
