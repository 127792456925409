import { CHANGE_VALUES, DELETE_VALUES, SUBMIT_VALUES } from "./login_types";
const loginValues = {
  email: "",
  password: "",
  name: "",
};
const loginValuesReducer = (state = loginValues, { type, payload }) => {
  switch (type) {
    case CHANGE_VALUES:
      return { ...state, email: payload.email, password: payload.password };
    case DELETE_VALUES:
      return { ...state, email: "", password: "" };
    case SUBMIT_VALUES:
      return { ...state, email: payload.email, password: payload.password };
    default:
      return state;
  }
};
export default loginValuesReducer;
