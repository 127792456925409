import React, { useState, useEffect } from "react";
import "./layoutScreen.scss";
import "./layout_responsive.scss";
import { NavLink, useNavigate } from "react-router-dom";
import UserService from "../../../httpServices/user.service";
import { useSelector, useDispatch } from "react-redux";
import { SET_USER } from "../../../redux/types/types";
import {
  AiFillCaretDown,
  AiOutlineFolderOpen,
  AiOutlineHome,
} from "react-icons/ai";
import { MdOutlineContactPage, MdOutlineNotifications } from "react-icons/md";
import { HiOutlineFolderAdd } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { BsCalendarEvent, BsFillPeopleFill } from "react-icons/bs";
import { GiStatic } from "react-icons/gi";

import { publicUrl } from "../../../httpServices/httpServices";
import { SlLogout } from "react-icons/sl";
import { BsFillPersonFill } from "react-icons/bs";
import sidebarIcon from ".././../../assets/new/office.svg";
import { GoThreeBars } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
// this is the layout component for the entire app pages
const LayoutScreen = ({ children }) => {
  const [userProfile, setUser] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { pages } = useSelector((state) => state);
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleLogout = () => {
    // delete AxiosInstance.defaults.headers.common['Authurization']
    localStorage.removeItem("token");
    navigate(`/login`);
  };
  const userDefaultPage = pages.find((page) => page.default);
  useEffect(() => {
    if (Object.keys(user).length === 0) {
      UserService.getUser("user/me").then((value) => {
        setUser((_) => value.user);
        dispatch({ type: SET_USER, payload: value.user });
      });
    } else {
      setUser((_) => user);
    }
  }, [user, dispatch]);
  const lang = localStorage.getItem("lang");
  const sideBarlinks = [
    {
      title_en: "Home",
      title_ar: "الرئيسية",
      path: "/adminDashboard",
      icon: <AiOutlineHome />,
    },
    {
      title_en: "Pages",
      title_ar: "الصفحات",
      path: "/adminDashboard/pages",
      icon: <MdOutlineContactPage />,
    },
    {
      title_en: "Sections",
      title_ar: "الاقسام",
      path: "/adminDashboard/sections",
      icon: <AiOutlineFolderOpen />,
    },
    {
      title_en: "Sub Sections",
      title_ar: "الاقسام الفرعية",
      path: "/adminDashboard/subSections",
      icon: <HiOutlineFolderAdd />,
    },
  ];
  const { pathname } = useLocation();
  const roleOnLang = (role) => {
    if (role === "admin") {
      if (lang === "en") {
        return "Admin";
      } else {
        return "مشرف";
      }
    } else {
      if (lang === "en") {
        return "Super Admin";
      } else {
        return "مشرف إداري";
      }
    }
  };
  const [showing, setShowing] = useState({
    reservations: false,
    admins: false,
    requests: false,
    statistics: false,
  });
  const showLinksList = (list_item) => {
    setShowing((currentBooleans) => ({
      ...currentBooleans,
      reservations:
        list_item === "reservations" && !currentBooleans.reservations,
      admins: list_item === "admins" && !currentBooleans.admins,
      requests: list_item === "requests" && !currentBooleans.requests,
      statistics: list_item === "statistics" && !currentBooleans.statistics,
    }));
  };
  const checkLinks = [
    "/adminDashboard",
    "/adminDashboard/pages",
    "/adminDashboard/sections",
    "/adminDashboard/subSections",
    "/adminDashboard/contactSari",
  ].find((url) => url === pathname);
  useEffect(() => {
    if (checkLinks !== undefined) {
      setShowing({
        reservations: false,
        admins: false,
        request: false,
        statistics: false,
      });
    } else if (
      [
        "/adminDashboard/reservations/services",
        "/adminDashboard/reservations/offers",
      ].indexOf(pathname) !== -1
    ) {
      setShowing({
        ...showing,
        reservations: true,
      });
    } else if (
      ["/adminDashboard/admins", "/adminDashboard/supAdmins"].indexOf(
        pathname
      ) !== -1
    ) {
      setShowing({
        ...showing,
        admins: true,
      });
    } else if (
      [
        "/adminDashboard/reservations/services",
        "/adminDashboard/reservations/offers",
      ].indexOf(pathname) !== -1
    ) {
      setShowing({
        ...showing,
        reservations: true,
      });
    } else if (
      ["/adminDashboard/admins", "/adminDashboard/supAdmins"].indexOf(
        pathname
      ) !== -1
    ) {
      setShowing({
        ...showing,
        admins: true,
      });
    } else if (
      [
        "/adminDashboard/requests/complaints",
        "/adminDashboard/requests/suggestions",
      ].indexOf(pathname) !== -1
    ) {
      setShowing({
        ...showing,
        requests: true,
      });
    }
    openSidebar && setOpenSidebar(false);
  }, [pathname]);
  return (
    <div className={`layout layout_${lang}`}>
      <div className={`sidebar ${openSidebar && "actived"}`}>
        <button
          className={`toggle_btn toggle_btn_${lang}`}
          onClick={() => setOpenSidebar((boolean) => !boolean)}
        >
          {openSidebar && <AiOutlineClose />}
        </button>
        <h4>{lang === "en" ? "Dashboard" : "لوحة التحكم"}</h4>
        <div className="ul-container">
          <ul className={lang === "en" ? "ms-auto" : "me-auto"}>
            {sideBarlinks.map((link, idx) => (
              <li key={idx} className="link">
                <NavLink
                  exact
                  to={link.path}
                  className={
                    link.path === pathname ? `actived_page_${lang}` : undefined
                  }
                >
                  {link.icon}
                  <span>{link[`title_${lang}`]} </span>
                </NavLink>
              </li>
            ))}
            <li
              className={`link-dropdown    ${
                showing.reservations ? "showing-links-list" : undefined
              }`}
            >
              <button
                className={`${lang}_radius`}
                onClick={() => showLinksList("reservations")}
              >
                <BsCalendarEvent />
                <span>{lang === "en" ? "Reservations" : "الحجوزات"}</span>
                <AiFillCaretDown className="down-arrow" />
              </button>
              <ul className={`${lang}_ul_style`}>
                <li
                  className={
                    pathname === "/adminDashboard/reservations/offers"
                      ? `actived_page_${lang}`
                      : undefined
                  }
                >
                  <NavLink to="/adminDashboard/reservations/offers">
                    {lang === "en" ? "offers" : "عروض"}
                  </NavLink>
                </li>
                <li
                  className={
                    pathname ===
                      "/adminDashboard/reservations/reservationsType" &&
                    `actived_page_${lang}`
                  }
                >
                  <NavLink to="/adminDashboard/reservations/reservationsType">
                    {lang === "en" ? "reservation types" : "أنواع الحجز"}
                  </NavLink>
                </li>
              </ul>
            </li>
            <li
              className={`link-dropdown    ${
                showing.admins && "showing-links-list"
              }`}
            >
              <button
                className={`${lang}_radius`}
                onClick={() => showLinksList("admins")}
              >
                <BsFillPeopleFill />
                <span>{lang === "en" ? "Admins" : "المشرفين"}</span>
                <AiFillCaretDown className="down-arrow" />
              </button>
              <ul className={`${lang}_ul_style`}>
                <li
                  className={
                    pathname === "/adminDashboard/admins" &&
                    `actived_page_${lang}`
                  }
                >
                  <NavLink to="/adminDashboard/admins">
                    {lang === "en" ? "Super Admins" : "المشرفيين"}
                  </NavLink>
                </li>
                <li
                  className={
                    pathname === "/adminDashboard/supAdmins" &&
                    `actived_page_${lang}`
                  }
                >
                  <NavLink to="/adminDashboard/supAdmins">
                    {lang === "en" ? "Admins" : "المشرفين الفرعيين"}
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <li
              className={`link-dropdown    ${
                showing.requests && "showing-links-list"
              }`}
            >
              <button
                className={`${lang}_radius`}
                onClick={() => showLinksList("requests")}
              >
                <AiOutlinePullRequest />
                <span>{lang === "en" ? "Com Requests" : "طلبات التواصل"}</span>
                <AiFillCaretDown className="down-arrow" />
              </button>
              <ul className={`${lang}_ul_style`}>
                <li
                  className={
                    pathname === "/adminDashboard/requests/complaints" &&
                    `actived_page_${lang}`
                  }
                >
                  <NavLink to="/adminDashboard/requests/complaints">
                    {lang === "en" ? "Complaints" : "شكاوي"}
                  </NavLink>
                </li>
                <li
                  className={
                    pathname === "/adminDashboard/requests/suggestions" &&
                    `actived_page_${lang}`
                  }
                >
                  <NavLink to="/adminDashboard/requests/suggestions">
                    {lang === "en" ? "Suggestions" : "مقترحات"}
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li
              className={`link-dropdown    ${
                showing.statistics && "showing-links-list"
              }`}
            >
              <button
                className={`${lang}_radius`}
                onClick={() => showLinksList("statistics")}
              >
                <GiStatic />
                <span>{lang === "en" ? "Statistics" : "الاحصائيات"}</span>
                <AiFillCaretDown className="down-arrow" />
              </button>
              <ul className={`${lang}_ul_style`}>
                <li
                  className={
                    pathname === "/adminDashboard/statistics/visitors"
                      ? `actived_page_${lang}`
                      : undefined
                  }
                >
                  <NavLink to="/adminDashboard/statistics/visitors">
                    {lang === "en" ? "Visitors" : "الزوار"}
                  </NavLink>
                </li>
                <li
                  className={
                    pathname === "/adminDashboard/statistics/users"
                      ? `actived_page_${lang}`
                      : undefined
                  }
                >
                  <NavLink to="/adminDashboard/statistics/users">
                    {lang === "en" ? "Users" : "المستخدمين"}
                  </NavLink>
                </li>
              </ul>
            </li>
            {/* <li className="link">
              <NavLink
                exact
                to="/adminDashboard/contactSari"
                className={
                  pathname === "/adminDashboard/contactSari" &&
                  `actived_page_${lang}`
                }
              >
                <IoIosCall />
                <span>
                  {lang === "en" ? "Contact with Sari" : "تواصل مع صاري"}
                </span>
              </NavLink>
            </li> */}
            <li className="link">
              <NavLink
                exact
                to="/"
                onClick={() => localStorage.removeItem("token")}
                className={
                  pathname === "/adminDashboard/contactSari"
                    ? `actived_page_${lang}`
                    : undefined
                }
              >
                <BiLogOutCircle
                  style={{
                    transform: lang === "ar" ? "rotateY(180deg)" : "rotateY(0)",
                  }}
                />
                <span>{lang === "en" ? "Log Out" : "تسجيل الخروج"}</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="img-sidebar">
          <img src={sidebarIcon} alt={lang === "en" ? "image" : "صورة"} />
        </div>
      </div>
      <div className="layout_body">
        <nav className={`top_nav ${lang === "en" ? "ps-5 pe-2" : "pe-5 ps-2"}`}>
          <button
            className="toggle_btn"
            onClick={() => setOpenSidebar((boolean) => !boolean)}
          >
            {!openSidebar && <GoThreeBars />}
          </button>

          <h6 className="designer">
            <span>
              {lang === "en"
                ? "The dashboard is designed by "
                : "لوحة التحكم مصممة عن طريق "}
            </span>
            <a href="https://www.sarri.sa/" target="_blank" rel="noreferrer">
              {lang === "en" ? "Sari Tech" : "صاري للتكنولوجيا"}
            </a>
          </h6>
          <div className="lang-notifications-profile">
            <div className="notifications">
              <MdOutlineNotifications />
            </div>
            {/* "https://tse1.mm.bing.net/th?id=OIP.3k8Oxem06mw5jPAXQnJdjAHaFj&pid=Api&P=0" */}
            {/* <div
              className={`profile ${lang === "en" ? "pe-2" : "ps-2"} dropdown`}
            >
              {user["image"] && (
                <img src={`${publicUrl}/${user["image"]}`} alt="" />
              )}
              <div>
                {user?.fullName_en && <h6>{user[`fullName_${lang}`]}</h6>}
                <p>{roleOnLang(user.role)}</p>
              </div>
            </div> */}
            <div
              className={`profile ${lang === "en" ? "pe-2" : "ps-2"} dropdown`}
            >
              {user["image"] && (
                <img src={`${publicUrl}/${user["image"]}`} alt="" />
              )}
              <div>
                {user?.fullName_en && <h6>{user[`fullName_${lang}`]}</h6>}
                <p>{roleOnLang(user.role)}</p>
              </div>
              <button
                className="dropdown-toggle"
                style={{ backgroundColor: "transparent" }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ></button>
              <ul className={`dropdown-menu dropdown-menu_${lang}`}>
                <li>
                  <NavLink to="/profile">
                    <BsFillPersonFill />
                    <span>{lang === "en" ? "Profile" : "الملف الشخصي"}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    // to={`/login`}
                    to={`/${userDefaultPage?._id}`}
                    onClick={() => {
                      localStorage.removeItem("token");
                    }}
                  >
                    <SlLogout
                      style={{ transform: lang === "en" && "rotateY(180deg)" }}
                    />
                    <span>{lang === "en" ? "Logout" : "تسجيل الخروج"}</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <button
              className="dropdown-toggle  language_btn"
              style={{ backgroundColor: "transparent" }}
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {lang !== "en" ? "العربية" : "English"}
            </button>
            <ul className={`dropdown-menu langs_dropdown`}>
              <li onClick={() => dispatch({ type: "SWITCH_LANGUAGE" })}>
                {lang !== "en" ? "English" : "العربية"}
              </li>
            </ul>
          </div>
        </nav>
        <div className="layout_content">{children}</div>
      </div>
    </div>
  );
};

export default LayoutScreen;
