import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const OfferService = {
  getOffers: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    return data;
  },
  deleteOffer: async function (endPoint) {
    const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}`);
    return data;
  },
  updateState: async function (endPoint, status) {
    const { data } = await AxiosInstance.put(`${baseUrl}/${endPoint}`, {
      status: status,
    });
    return data;
  },
};
export default OfferService;
