export const GET_RES_TYPES = "GET_RES_TYPES";
export const ADD_RES_TYPES = "ADD_RES_TYPES";
export const DEL_RES_TYPES = "DEL_RES_TYPES";

const resTypesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case GET_RES_TYPES:
      return [...payload];
    case ADD_RES_TYPES:
      return [...state, payload];
    case DEL_RES_TYPES:
      return state.filter((item) => item._id !== payload._id);
    default:
      return state;
  }
};
export default resTypesReducer;
