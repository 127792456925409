import React, { useEffect, useState } from "react";
import { publicUrl } from "../../../httpServices/httpServices";
import { Box, CircularProgress, Stack } from "@mui/material";
import "./UserPageIntro.scss";

const UserPageIntro = ({ userCurrentPage, lang }) => {
  const indexOfTitle_en = userCurrentPage?.description_en.indexOf(",");
  const indexOfTitle_ar = userCurrentPage?.description_ar.indexOf(",");
  const originTitle_en = userCurrentPage?.description_en.slice(
    0,
    indexOfTitle_en
  );
  const originTitle_ar = userCurrentPage?.description_ar.slice(
    0,
    indexOfTitle_ar
  );
  const originDes_en = userCurrentPage?.description_en.slice(
    indexOfTitle_en + 1,
    userCurrentPage?.description_en.length
  );
  const originDes_ar = userCurrentPage?.description_ar.slice(
    indexOfTitle_ar + 1,
    userCurrentPage[`description_${lang}`].length
  );
  console.log("image", `${publicUrl}/${userCurrentPage?.image_en}`);

  return (
    <section
      className="hero-section"
      style={{
        backgroundImage: `url(${publicUrl}/${userCurrentPage?.image_ar})`,
        direction: lang === "en" ? "ltr" : "rtl",
      }}
    >
      <img
        src={`${publicUrl}/${userCurrentPage?.image_en}`}
        alt={lang === "en" ? originTitle_en : originTitle_ar}
      />
      <Box component="div" className="text-container">
        <h1>{lang === "en" ? originTitle_en : originTitle_ar}</h1>
        <h2>{lang === "en" ? originDes_en : originDes_ar}</h2>{" "}
      </Box>
    </section>
  );
};

export default UserPageIntro;
