import React, { useEffect, useState } from "react";
import InputText from "../../components/forms/Input_text/InputText";
import "./AddSubForm.scss";
import { IoIosAddCircle } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { GET_ALL_PAGES } from "../../../redux/reducers/pages_reducer/page_types";
import PagesService from "../../../httpServices/pages.services";
import SectionService from "../../../httpServices/sections.services";
import { ADD_NEW_SUB_BY_FORM } from "../../../redux/reducers/pages_reducer/page_types";
import { useNavigate, useParams } from "react-router";
import { ADD_RESERVED_SUB_TO_SUBS } from "../../../redux/reducers/resSubs_reducer/resSubs_types";
import { PUSH_SUB_TO_RESERVED_SECTION } from "../../../redux/reducers/resSections_reducer/resSections_types";
import { BsPlusSquareFill } from "react-icons/bs";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillDelete,
  AiFillMinusSquare,
  AiOutlineRollback,
} from "react-icons/ai";
import UploadServices from "../../../httpServices/httpServices";
import InputFile from "../../components/forms/input_file/InputFile";
import { RiCloseFill } from "react-icons/ri";
import { useFormik } from "formik";
import * as Yup from "yup";
import subSectionsService from "../../../httpServices/subSection.services";
const AddSubForm = ({
  lang,
  setAddSubModel,
  adminCurrentPage,
  adminSection,
  setForm,
}) => {
  // const lang = localStorage.getItem("lang");
  // const { pages } = useSelector((state) => state);
  // const { pageId, sectionId } = useParams();
  // const adminCurrentPage = pages.find(({ _id }) => _id === pageId);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [errorMessage, setErrorMessage] = useState("");
  // const adminSection = pages[0]
  //   ? adminCurrentPage.sections.find(({ _id }) => _id === sectionId)
  //   : "";
  // useEffect(() => {
  //   if (!pages[0])
  //     PagesService.getAllPages("page").then((data) => {
  //       dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
  //     });
  // }, []);
  // const matchingTitle = ["offer", "team", "service"]
  //   .map((word) => adminSection?.title_en?.includes(word))
  //   .includes(true);
  // const [newSub, setNewSub] = useState({
  //   title_en: "",
  //   title_ar: "",
  //   description_en: "",
  //   description_ar: "",
  //   image: "",
  //   keywordDescription: "",
  //   keywords: [],
  //   extras: [],
  // });
  // const [extraFields, setExtraFields] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });
  // const [subImage, setSubImage] = useState({});

  // const [subKeyword, setsubKeyword] = useState("");
  // const handleChange = (event) => {
  //   setNewSub({
  //     ...newSub,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  // const keywordIsExisted = newSub.keywords.find((item) => item === subKeyword);
  // const addNewKeyword = () => {
  //   if (!keywordIsExisted && subKeyword.length > 0) {
  //     setNewSub({
  //       ...newSub,
  //       keywords: [...newSub.keywords, subKeyword],
  //     });

  //     setsubKeyword("");
  //   }
  // };
  // const deleteKeyword = (item) => {
  //   setNewSub({
  //     ...newSub,
  //     keywords: newSub.keywords.filter((keyword) => keyword !== item),
  //   });
  // };
  // const handleChangeExtraFields = (e) => {
  //   setExtraFields({
  //     ...extraFields,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (subImage) {
  //     const date = new FormData();
  //     date.append("image", subImage);
  //     const resImage = await UploadServices.uploadFile("upload", date);
  //     newSub.image = resImage.filename;
  //   }
  //   PagesService.addSubSectionWithPage("subSection", sectionId, newSub)
  //     .then((data) => {
  //       if (data.subSection) {
  //         dispatch({
  //           type: ADD_RESERVED_SUB_TO_SUBS,
  //           payload: data.subSection,
  //         });
  //         dispatch({
  //           type: ADD_NEW_SUB_BY_FORM,
  //           payload: {
  //             adminCurrentPage,
  //             adminSection,
  //             newSub: data.subSection,
  //           },
  //         });
  //         dispatch({
  //           type: PUSH_SUB_TO_RESERVED_SECTION,
  //           payload: { adminSection, newSub: data.subSection },
  //         });
  //         navigate(`/adminDashboard/${adminCurrentPage._id}`);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error && error.response.data) {
  //         if (error.response.data[`message_${lang}`]) {
  //           setErrorMessage(error.response.data[`message_${lang}`]);
  //         } else {
  //           setErrorMessage(error.response.data);
  //         }
  //       }
  //     });
  // };
  // const [modelEditExtraItem, setEditModelExtraItem] = useState({
  //   showing: false,
  //   selectItem: "",
  // });

  // const openModel = (item) => {
  //   setEditModelExtraItem((model) => ({
  //     ...model,
  //     showing: true,
  //     selectItem: item.key_en,
  //   }));
  // };
  // const deleteExtraItem = (selectedItem) => {
  //   setNewSub({
  //     ...newSub,
  //     extras: newSub.extras.filter(
  //       ({ key_en }) => key_en !== selectedItem.key_en
  //     ),
  //   });
  // };
  // const [editItem, setEditItem] = useState({
  //   key_en: "",
  //   key_ar: "",
  //   value_en: "",
  //   value_ar: "",
  // });
  // const handleEditItem = (e) => {
  //   setEditItem({
  //     ...editItem,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // const addNewExtraItem = () => {
  //   setNewSub({
  //     ...newSub,
  //     extras: [...newSub?.extras, extraFields],
  //   });
  //   setExtraFields({
  //     key_en: "",
  //     value_en: "",
  //     key_ar: "",
  //     value_ar: "",
  //   });
  // };
  const [pageImage, setPageImage] = useState(null);
  const { pageId, sectionId } = useParams();
  const [subImage, setSubImage] = useState({});
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  console.log();
  const formik = useFormik({
    initialValues: {
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      image: "",
      keywordDescription: "",
      keywords: [],
      extras: [],
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      // image: Yup.string().required(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
      // extras: Yup.array().min(
      //   1,
      //   lang === "en" ? "add extras item" : "اضف عنصر اكسترا"
      // ),
    }),
    onSubmit: async () => {
      if (pageImage !== null) {
        const date = new FormData();
        date.append("image", pageImage);
        const resImage = await UploadServices.uploadFile("upload", date);
        newSub.image = resImage.filename;
        console.log(newSub);
      }
      PagesService.addSubSectionWithPage("subSection", sectionId, newSub)
        .then((data) => {
          if (data.subSection) {
           
            dispatch({
              type: ADD_RESERVED_SUB_TO_SUBS,
              payload: data.subSection,
            });
            dispatch({
              type: ADD_NEW_SUB_BY_FORM,
              payload: {
                adminCurrentPage,
                adminSection,
                newSub: data.subSection,
              },
            });
            dispatch({
              type: PUSH_SUB_TO_RESERVED_SECTION,
              payload: { adminSection, newSub: data.subSection },
            });
            removeAddSubForm();
          }
        })
        .catch((error) => {
          if (error && error.response?.data) {
            if (error.response.data[`message_${lang}`]) {
              setErrorMessage(error.response.data[`message_${lang}`]);
            } else {
              setErrorMessage(error.response.data);
            }
          }
        });
    },
  });
  const {
    values: newSub,
    setValues: setNewSub,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const uploadPageImg = (e) => {
    setPageImage(e.target.files[0]);
  };
  const removeUploadedImg = () => {
    setPageImage(null);
    setNewSub({
      ...newSub,
      image: "",
    });
  };
  const keywordIsExisted = newSub.keywords?.find((item) => item === keyword);
  const addNewKeyword = () => {
    if (!keywordIsExisted && keyword.length > 0) {
      setNewSub({
        ...newSub,
        keywords: [...newSub.keywords, keyword],
      });
      setKeyword("");
    } else {
      formik.setErrors({
        ...errors,
        keywords:
          lang === "en" ? "the keyword is existed" : "الكلمة المفتاحية موجودة",
      });
    }
  };
  const deleteKeyword = (item) => {
    setNewSub({
      ...newSub,
      keywords: newSub.keywords.filter((keyword) => keyword !== item),
    });
  };
  const removeAddSubForm = () => {
    setAddSubModel(false);
  };
  const [extraFields, setExtraFields] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const [editItem, setEditItem] = useState({
    key_en: "",
    key_ar: "",
    value_en: "",
    value_ar: "",
  });
  const handleChangeExtraFields = (e) => {
    setExtraFields({
      ...extraFields,
      [e.target.name]: e.target.value,
    });
  };
  const valuesCompleted = Object.values(extraFields).every(
    (value) => value.length > 0
  );
  const itemExisted = newSub.extras.find(
    (item) =>
      item.key_en === extraFields?.key_en || item.key_en === editItem?.key_en
  );
  const addNewExtraItem = () => {
    if (valuesCompleted && itemExisted === undefined) {
      setNewSub({
        ...newSub,
        extras: [...newSub?.extras, extraFields],
      });
      setExtraFields({
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  const deleteExtraItem = (selectItem) => {
    setNewSub({
      ...newSub,
      extras: newSub.extras.filter(
        ({ key_en }) => key_en !== selectItem.key_en
      ),
    });
  };
  const [modelEditExtraItem, setEditModelExtraItem] = useState({
    showing: false,
    selectItem: "",
  });
  const openModel = (item) => {
    setEditModelExtraItem((model) => ({
      ...model,
      showing: true,
      selectItem: item.key_en,
    }));
  };
  const removeModel = () => {
    setEditModelExtraItem((model) => !model.showing);
    setEditItem({
      key_en: "",
      key_ar: "",
      value_en: "",
      value_ar: "",
    });
  };
  const handleEditItem = (e) => {
    setEditItem({
      ...editItem,
      [e.target.name]: e.target.value,
    });
  };
  const changeExtrasItemData = (item) => {
    if (itemExisted === undefined) {
      setNewSub({
        ...newSub,
        extras: newSub.extras.map((current) =>
          current.key_en === item.key_en ? editItem : current
        ),
      });
      setEditItem({
        ...editItem,
        key_en: "",
        key_ar: "",
        value_en: "",
        value_ar: "",
      });
    }
  };
  return (
    // <div className="add-sub-form">
    //   <form onSubmit={handleSubmit}>
    //     {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
    //     <h4 className="text-center">
    //       {lang === "en" ? "Add New Sub Section" : "اضافة قسم فرعي جديد"}
    //     </h4>
    //     <InputText
    //       label={lang === "en" ? "english title" : "اللقب الانجليزي"}
    //       placeholder={lang === "en" ? "english title" : "اللقب الانجليزي"}
    //       type="text"
    //       name="title_en"
    //       value={newSub.title_en}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "arabic title" : "اللقب العربي"}
    //       placeholder={lang === "en" ? "arabic title" : "اللقب العربي"}
    //       type="text"
    //       name="title_ar"
    //       value={newSub.title_ar}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "english description" : "الوصف الانجليزي"}
    //       placeholder={
    //         lang === "en" ? "english page description" : "وصف الصفحة الانجليزي"
    //       }
    //       type="text"
    //       name="description_en"
    //       value={newSub.description_en}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "arabic description" : "الوصف العربي"}
    //       placeholder={
    //         lang === "en" ? "arabic page description" : "وصف الصفحة العربي"
    //       }
    //       type="text"
    //       name="description_ar"
    //       value={newSub.description_ar}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={
    //         lang === "en" ? "arabic keyword description" : "وصف الكلمة الرئيسية"
    //       }
    //       placeholder={
    //         lang === "en" ? "arabic keyword description" : "وصف الكلمة الرئيسية"
    //       }
    //       type="text"
    //       name="keywordDescription"
    //       value={newSub.keywordDescription}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "image link" : "رابط الصورة"}
    //       placeholder={lang === "en" ? "image link" : "رابط الصورة"}
    //       type="file"
    //       name="image"
    //       value={newSub.image}
    //       handleChange={(e) => setSubImage(e.target.files[0])}
    //     />
    //     {matchingTitle && (
    //       <>
    //         <h4 clssName="text-danger">Extra Items</h4>
    //         <button
    //           type="button"
    //           className="btn ms-auto"
    //           style={{ backgroundColor: "transparent" }}
    //           onClick={() => setFormAdd((form) => !form)}
    //         >
    //           {!formAdd ? (
    //             <BsPlusSquareFill style={{ height: "20px", width: "20px" }} />
    //           ) : (
    //             <AiFillMinusSquare style={{ height: "20px", width: "20px" }} />
    //           )}
    //         </button>
    //         {formAdd && (
    //           <>
    //             <InputText
    //               label={
    //                 lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"
    //               }
    //               placeholder={
    //                 lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"
    //               }
    //               type="text"
    //               name="key_en"
    //               value={extraFields.key_en}
    //               handleChange={handleChangeExtraFields}
    //             />
    //             <InputText
    //               label={lang === "en" ? "english value" : "القيمة الانجليزية"}
    //               placeholder={
    //                 lang === "en" ? "english value" : "القيمة الانجليزية"
    //               }
    //               type="text"
    //               name="value_en"
    //               value={extraFields.value_en}
    //               handleChange={handleChangeExtraFields}
    //             />
    //             <InputText
    //               label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
    //               placeholder={
    //                 lang === "en" ? "arabic key" : "مفتاح اللغة العربية"
    //               }
    //               type="text"
    //               name="key_ar"
    //               value={extraFields.key_ar}
    //               handleChange={handleChangeExtraFields}
    //             />
    //             <InputText
    //               label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
    //               placeholder={
    //                 lang === "en" ? "arabic key" : "مفتاح اللغة العربية"
    //               }
    //               type="text"
    //               name="value_ar"
    //               value={extraFields.value_ar}
    //               handleChange={handleChangeExtraFields}
    //             />
    //             <button
    //               type="button"
    //               className="btn w-100 bg-info text-white fw-bold mt-3"
    //               onClick={addNewExtraItem}
    //             >
    //               {lang === "en" ? "add item" : "اضف عنصر"}
    //             </button>
    //           </>
    //         )}
    //         {newSub?.extras?.map((item, idx) => (
    //           <div
    //             key={idx}
    //             className="border border-dark position-relative mt-3 px-3 py-2"
    //           >
    //             <h4>{item[`key_${lang}`]}</h4>
    //             <h6 className="mb-0">{item[`value_${lang}`]}</h6>
    //             <button
    //               className="position-absolute btn text-white d-flex align-items-center justify-content-center"
    //               type="button"
    //               style={{
    //                 top: "-10px",
    //                 right: "-10px",
    //                 height: "30px",
    //                 width: "15px",
    //                 backgroundColor: "#DC3545",
    //               }}
    //               onClick={() => deleteExtraItem(item)}
    //             >
    //               <span>X</span>
    //             </button>
    //             <button
    //               className="position-absolute btn text-white d-flex align-items-center justify-content-center"
    //               type="button"
    //               style={{
    //                 top: "-10px",
    //                 right: "20px",
    //                 height: "30px",
    //                 width: "15px",
    //                 backgroundColor: "#00B526",
    //               }}
    //               onClick={() => openModel(item)}
    //             >
    //               <span>&#10049;</span>
    //             </button>
    //             {modelEditExtraItem.showing &&
    //               modelEditExtraItem.selectItem === item.key_en && (
    //                 <div
    //                   className="position-absolute top-0 start-0 bg-danger "
    //                   style={{ zIndex: 4 }}
    //                 >
    //                   <InputText
    //                     label={
    //                       lang === "en" ? "english key" : "المفتاح الانجليزي"
    //                     }
    //                     name={`key_${lang}`}
    //                     value={editItem[`key_${lang}`]}
    //                     handleChange={handleEditItem}
    //                   />
    //                   <InputText
    //                     label={
    //                       lang === "en" ? "english value" : "القيمة الانجليزية"
    //                     }
    //                     name={`value_${lang}`}
    //                     value={editItem[`value_${lang}`]}
    //                     handleChange={handleEditItem}
    //                   />
    //                   <button
    //                     type="button"
    //                     onClick={() => {
    //                       setNewSub({
    //                         ...newSub,
    //                         extras: newSub.extras.map((current) =>
    //                           current.key_en === item.key_en
    //                             ? editItem
    //                             : current
    //                         ),
    //                       });
    //                       setEditItem({
    //                         ...editItem,
    //                         key_en: "",
    //                         key_ar: "",
    //                         value_en: "",
    //                         value_ar: "",
    //                       });
    //                     }}
    //                   >
    //                     {lang === "en" ? "Upddate item" : "تحديث العنصر"}
    //                   </button>
    //                   <button
    //                     className="position-absolute btn text-white d-flex align-items-center justify-content-center"
    //                     type="button"
    //                     style={{
    //                       top: "-10px",
    //                       right: "-10px",
    //                       height: "30px",
    //                       width: "15px",
    //                       backgroundColor: "black",
    //                       zIndex: "10",
    //                     }}
    //                     onClick={() =>
    //                       setEditModelExtraItem((model) => !model.showing)
    //                     }
    //                   >
    //                     <span>X</span>
    //                   </button>
    //                 </div>
    //               )}
    //           </div>
    //         ))}
    //       </>
    //     )}
    //     <div className="mt-4">
    //       <label className="fs-5">
    //         {lang === "en" ? "keywords" : "الكلمات"}
    //       </label>
    //       <div className="d-flex align-items-center justify-content-between mt-2">
    //         <input
    //           type="text"
    //           style={{ width: "90%" }}
    //           className="fs-5 py-2 px-3 rounded border border-dark"
    //           placeholder="Sub Keyword"
    //           value={subKeyword}
    //           onChange={(e) => setsubKeyword(e.target.value)}
    //         />
    //         <div
    //           style={{ width: "10%" }}
    //           className="d-flex align-items-center justify-content-center "
    //         >
    //           <button
    //             className="add-button"
    //             type="button"
    //             onClick={addNewKeyword}
    //           >
    //             <IoIosAddCircle />
    //           </button>
    //         </div>
    //       </div>
    //       {newSub.keywords.length > 0 && (
    //         <div className="mt-1  h-auto border border-dark">
    //           {newSub.keywords.map((keyword, index) => (
    //             <span
    //               className="bg-info p-1 d-block w-75 mx-auto px-3 text-white mt-2 rounded position-relative"
    //               key={index}
    //             >
    //               {keyword}
    //               <button
    //                 className="cancel-model btn btn-danger position-absolite position-absolute pt-3 d-flex align-items-center justify-content-center"
    //                 style={{
    //                   height: "5px",
    //                   width: "5px",
    //                   top: "-10px",
    //                   right: "-10px",
    //                 }}
    //                 type="button"
    //                 onClick={() => deleteKeyword(keyword)}
    //               >
    //                 <p>🗙</p>
    //               </button>
    //             </span>
    //           ))}
    //         </div>
    //       )}
    //       <button
    //         className="mt-5 w-100 py-2 px-2 fw-bold  btn btn-secondary"
    //         type="submit"
    //       >
    //         {lang === "en" ? "Add Sub Section" : "اضافة قصم فرعي"}
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <div className="add_res_sub_model sub_sections_page ">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button
            className={`back_btn ${lang === "en" ? "me-2" : "ms-2"} `}
            onClick={() => {
              setForm(false);
            }}
          >
            <AiOutlineRollback
              style={{ transform: lang === "ar" && "rotateY(180deg)" }}
            />
          </button>
          <button className="cancel-model" onClick={removeAddSubForm}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={newSub.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={newSub.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={newSub.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={newSub.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />

        {pageImage !== null ? (
          <div className="img-container">
            <img src={pageImage && URL.createObjectURL(pageImage)} alt="img" />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={touched.image}
            handleChange={uploadPageImg}
          />
        )}
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={newSub.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border:
                  errors.keywords && touched?.keywords?.length < 1
                    ? "1px solid red"
                    : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onBlur={() => {
                formik.setTouched({ ...touched, keyword: true });
                if (newSub.keywords.length < 1) {
                  formik.setErrors({
                    ...errors,
                    keywords:
                      lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
                  });
                }
              }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && touched?.keywords?.length < 1 && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {newSub.keywords?.length > 0 && (
            <div className={` keywords_wrapper keywords_wrapper_${lang} `}>
              {newSub.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </div>
          )}
          <h5 className="mt-4 fw-bold  text-center">
            {lang === "en" ? "Extra Items" : "عناصر الاكسترا"}
          </h5>
          <InputText
            label={lang === "en" ? "english key" : "مفتاح اللغة الانجليزي"}
            type="text"
            name="key_en"
            value={extraFields.key_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "english value" : "القيمة الانجليزية"}
            type="text"
            name="value_en"
            value={extraFields.value_en}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="key_ar"
            value={extraFields.key_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <InputText
            label={lang === "en" ? "arabic key" : "مفتاح اللغة العربية"}
            type="text"
            name="value_ar"
            value={extraFields.value_ar}
            handleChange={handleChangeExtraFields}
            error={errors.extras}
            isTouched={touched.extras?.length === 0 ? true : false}
          />
          <button
            type="button"
            className={`add_extras_item_btn ${
              errors.extras && touched.extras?.length === 0 && "error"
            }`}
            onClick={addNewExtraItem}
          >
            {lang === "en" ? "Add Item" : "اضف عنصر"}
          </button>
          {newSub.extras?.map((item, index) => (
            <div key={index} className="extras_item">
              {modelEditExtraItem.showing &&
              modelEditExtraItem.selectItem === item.key_en ? (
                <div className="edit_extra_item_model">
                  <InputText
                    label={
                      lang === "en" ? "english key" : "مفتاح اللغة العربية"
                    }
                    type="text"
                    name={`key_${lang}`}
                    value={lang === "en" ? editItem.key_en : editItem.key_ar}
                    placeholder={
                      lang === "en" ? "New english key" : "مفتاح عربي جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <InputText
                    label={lang === "en" ? "english value" : "القيمة العربية"}
                    type="text"
                    name={`value_${lang}`}
                    value={
                      lang === "en" ? editItem.value_en : editItem.value_ar
                    }
                    placeholder={
                      lang === "en"
                        ? "New english value"
                        : "قيمة اللغة الانجليزية جديد"
                    }
                    handleChange={handleEditItem}
                  />
                  <div className="edit-extras-item-model-btns">
                    <button
                      type="button"
                      onClick={() => changeExtrasItemData(item)}
                    >
                      <AiFillCheckCircle />
                    </button>
                    <button className="" type="button" onClick={removeModel}>
                      <AiFillCloseCircle />
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h4>{item[`key_${lang}`]}</h4>
                  <h6>{item[`value_${lang}`]}</h6>
                  <button
                    className=" del-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => deleteExtraItem(item)}
                  >
                    <span>X</span>
                  </button>
                  <button
                    className=" edi-btn position-absolute btn text-white d-flex align-items-center justify-content-center"
                    type="button"
                    onClick={() => openModel(item)}
                  >
                    <span>&#10049;</span>
                  </button>
                </>
              )}
            </div>
          ))}
          <button
            className="mt-5 w-100 py-2 px-2 fw-bold  add_page_btn"
            type="submit"
          >
            {lang === "en" ? "Add Sub Section" : "اضافة قسم فرعي"}
          </button>
        </div>
      </form>
    </div>
  );
};
export default AddSubForm;
