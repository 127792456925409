import React, { useEffect, useState } from "react";
import "./PagesPage.scss";
import { useSelector, useDispatch } from "react-redux";
import { BiPencil } from "react-icons/bi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import PagesService from "../../../httpServices/pages.services";
import {
  GET_ALL_PAGES,
  REMOVE_PAGE,
} from "../../../redux/reducers/pages_reducer/page_types";
import { NavLink } from "react-router-dom";
import icon from "../../../assets/folder_icon.svg";
import { FaPlus } from "react-icons/fa";
import AddPageModel from "../../components/landing/add_page_model/AddPageModel";
import EditPageModel from "../../components/landing/edit_page_model/EditPageModel";
import { toast, ToastContainer } from "react-toastify";

const PagesPage = () => {
  const { pages } = useSelector((state) => state);
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const [models, setModels] = useState({
    add: false,
    edit: { activiton: false, pageId: "" },
  });
  const removePage = (page) => {
    PagesService.deletePage("page", page._id).then((res) => {
      if (res) {
        dispatch({ type: REMOVE_PAGE, payload: page });
        toast.success(res[`message_${lang}`]);
      }
    });
  };
  const showEditModel = (page) => {
    setModels({
      ...models,
      edit: {
        activiton: true,
        pageId: page._id,
      },
    });
  };
  useEffect(() => {
    if (!pages[0]) {
      PagesService.getAllPages("page").then((data) => {
        dispatch({ type: GET_ALL_PAGES, payload: data.pages.pages });
        console.log("pages", data.pages.pages);
      });
    }
  }, [dispatch]);
  return (
    <section className={`pages_page page_style_${lang}`}>
      <ToastContainer />

      <div className="inner_container">
        <h3 className="heading">{lang === "en" ? "Pages" : "الصفحات"}</h3>
        <div className="pages_wrapper">
          {pages.map((page, pageIndex) => (
            <div className="page" key={page._id}>
              {pageIndex > 2 && (
                <button className="delete_btn" onClick={() => removePage(page)}>
                  <MdOutlineDeleteOutline />
                </button>
              )}
              <button className="edit_btn" onClick={() => showEditModel(page)}>
                <BiPencil />
              </button>
              <NavLink
                className="route"
                to={`/adminDashboard/pages/${page._id}`}
              >
                <div className="route-container">
                  <img src={icon} alt={page[`title_${lang}`]} />
                  {lang === "en" ? (
                    <h4>
                      {page?.title_en?.length > 18
                        ? `${page.title_en.slice(0, 18)}...`
                        : page.title_en}
                    </h4>
                  ) : (
                    <h4>
                      {page?.title_ar?.length > 20
                        ? `${page.title_ar.slice(0, 18)}...`
                        : page.title_ar}
                    </h4>
                  )}
                </div>
              </NavLink>
              {models.edit.activiton && page._id === models.edit.pageId && (
                <EditPageModel
                  setModels={setModels}
                  lang={lang}
                  page={pages.find(({ _id }) => _id === models.edit.pageId)}
                />
              )}
            </div>
          ))}

          <button
            className="add_btn"
            onClick={() => setModels({ ...models, add: true })}
          >
            <div>
              <FaPlus />
              <h4>{lang === "en" ? "Adding Page" : "إضافة صفحة"}</h4>
            </div>
          </button>
        </div>
        {/* <div className="mt-5">
          <h3>{lang === "en" ? "Blogs" : "المدونات"}</h3>
          <div className="pages_wrapper">
            <div className="page">
              <div className="page_container">
                <NavLink className="route" to="/adminDashboard/blogs">
                  <div className="route-container">
                    <img src={icon} alt="page" />
                    <h4>{lang === "en" ? "Blogs" : "المدونات"}</h4>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {models.add && <AddPageModel setModels={setModels} />}
    </section>
  );
};

export default PagesPage;
