import React, { useEffect, useState } from "react";
import InputText from "../../forms/Input_text/InputText";
import "./AddPageModel.scss";
import { IoIosAddCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import { ADD_PAGE } from "../../../../redux/reducers/pages_reducer/page_types";
import PagesService from "../../../../httpServices/pages.services";
import UploadServices from "../../../../httpServices/httpServices";
import { RiCloseFill } from "react-icons/ri";
import InputFile from "../../forms/input_file/InputFile";
import { AiFillDelete } from "react-icons/ai";
import { useFormik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Box, Typography } from "@mui/material";
const AddPageModel = ({ setModels }) => {
  const lang = localStorage.getItem("lang");
  const [pageKeyword, setPageKeyword] = useState("");
  const [pageImage_en, setPageImage_en] = useState(null);
  const [pageImage_ar, setPageImage_ar] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      image_en: "",
      image_ar: "",
      keywordDescription: "",
      keywords: [],
      sections: [],
    },
    onSubmit: async () => {
      if (pageImage_en) {
        const data = new FormData();
        data.append("image", pageImage_en);
        const resImage = await UploadServices.uploadFile("upload", data);
        console.log(resImage);
        newPage.image_en = resImage.filename;
      }
      if (pageImage_ar) {
        const data = new FormData();
        data.append("image", pageImage_ar);
        const resImage = await UploadServices.uploadFile("upload", data);
        newPage.image_ar = resImage.filename;
      }
      PagesService.addPage("page", newPage)
        .then((data) => {
          if (data.success) {
            dispatch({ type: ADD_PAGE, payload: data.page });
            removeAddPageModel();
            toast.success(data.message_en);
          }
        })
        .catch((err) => {
          if (err.response.data) {
            err.response.data[`message_${lang}`]
              ? setErrorMessage(err.response.data[`message_${lang}`])
              : setErrorMessage(err.response.data);
          }
        });
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image_en: Yup.string().required(
        lang === "en" ? "Image is required" : "الصورة مطلوبة"
      ),
      image_ar: Yup.string().required(
        lang === "en" ? "Image is required" : "الصورة مطلوبة"
      ),
      // image_ar: Yup.string().required(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
  });
  const {
    values: newPage,
    setValues: setNewPage,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  const keywordIsExisted = newPage.keywords?.find(
    (item) => item === pageKeyword
  );
  console.log("errors",errors);
  const addNewKeyword = () => {
    if (!keywordIsExisted && pageKeyword.length > 0) {
      setNewPage({
        ...newPage,
        keywords: [...newPage.keywords, pageKeyword],
      });
      setPageKeyword("");
    } else {
      formik.setErrors({
        ...errors,
        keywords:
          lang === "en" ? "the keyword is existed" : "الكلمة المفتاحية موجودة",
      });
    }
  };
  const deleteKeyword = (item) => {
    setNewPage({
      ...newPage,
      keywords: newPage.keywords.filter((keyword) => keyword !== item),
    });
  };
  const uploadPageImg_en = (e) => {
    setPageImage_en(e.target.files[0]);
    setNewPage({
      ...newPage,
      image_en: e.target.files[0].name,
    });
  };
  const removeUploadedImg_en = () => {
    setPageImage_en(null);
    setNewPage({
      ...newPage,
      image_en: "",
    });
  };
  const uploadPageImg_ar = (e) => {
    setPageImage_ar(e.target.files[0]);
    setNewPage({
      ...newPage,
      image_ar: e.target.files[0].name,
    });
  };
  const removeUploadedImg_ar = () => {
    setPageImage_ar(null);
    setNewPage({
      ...newPage,
      image_ar: "",
    });
  };
  // const removeUploadedImg = () => {
  //   setPageImage(null);
  //   setNewPage({
  //     ...newPage,
  //     image: "",
  //   });
  //   formik.setTouched({
  //     ...touched,
  //     image: true,
  //   });
  //   formik.setErrors({
  //     ...errors,
  //     image: true,
  //   });
  // };

  const removeAddPageModel = () => {
    setModels((models) => ({
      ...models,
      add: false,
    }));
  };
  useEffect(() => {
    if (pageImage_en !== null) {
      formik.setTouched({
        ...touched,
        image_en: true,
      });
    }
    if (pageImage_ar !== null) {
      formik.setTouched({
        ...touched,
        image_ar: true,
      });
    }
    
  }, [pageImage_en , pageImage_ar]);

  return (
    <div className="add_page_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeAddPageModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={newPage.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={newPage.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={newPage.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={newPage.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              left: lang === "en" ? 0 : undefined,
              right: lang === "ar" ? 0 : undefined,
              bottom: pageImage_en === null ? "-30px" : "-20px",
              textTransform: "uppercase",
              fontSize: "18px",
              fontFamily: '"Tajawal", sans-serif',
            }}
          >
            {lang === "en"
              ? "image while English"
              : "الصورة أثناء اللغة الإنجليزية"}
          </Typography>
        </Box>
        {pageImage_en !== null ? (
          <div className="img-container">
            <img
              src={pageImage_en && URL.createObjectURL(pageImage_en)}
              alt={lang === "en" ? "page background" : "خلفية الصفحة"}
            />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg_en}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <>
            <InputFile
              lang={lang}
              name="image_en"
              error={errors.image_en}
              isTouched={touched.image_en}
              handleChange={uploadPageImg_en}
            />
          </>
        )}

        {/* Arabic Image */}

        <Box
          sx={{
            position: "relative",
            mt: "25px",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              left: lang === "en" ? 0 : undefined,
              right: lang === "ar" ? 0 : undefined,
              bottom: "-25px",
              textTransform: "uppercase",
              fontSize: "18px",
              fontFamily: '"Tajawal", sans-serif',
            }}
          >
            {lang === "en"
              ? "image while Arabic"
              : "الصورة أثناء اللغة العربية"}
          </Typography>
        </Box>
        <Box mt="50px">
          {pageImage_ar !== null ? (
            <div className="img-container">
              <img
                src={pageImage_ar && URL.createObjectURL(pageImage_ar)}
                alt={lang === "en" ? "page background" : "خلفية الصفحة"}
              />
              <div className="delete">
                <button type="button" onClick={removeUploadedImg_ar}>
                  <AiFillDelete />
                </button>
              </div>
            </div>
          ) : (
            <>
              <InputFile
                lang={lang}
                name="image_ar"
                error={errors.image_ar}
                isTouched={touched.image_ar}
                handleChange={uploadPageImg_ar}
              />
            </>
          )}
        </Box>
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={newPage.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border:
                  errors.keywords && touched?.keyword
                    ? "1px solid red"
                    : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={pageKeyword}
              onChange={(e) => setPageKeyword(e.target.value)}
              onBlur={() => {
                formik.setTouched({ ...touched, keyword: true });
                if (newPage.keywords.length < 1) {
                  formik.setErrors({
                    ...errors,
                    keywords:
                      lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
                  });
                }
              }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && touched?.keyword && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {newPage.keywords?.length > 0 && (
            <Box
              component="div"
              sx={{
                height: "100px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: "#aac1fe",
                  transition: "0.5s all",
                  borderRadius: "15px",
                },

                " &::-webkit-scrollbar-thumb:hover": {
                  background: "#78a3e4",
                },
              }}
              className={` keywords_wrapper keywords_wrapper_${lang} `}
            >
              {newPage.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}

          <button
            className="mt-5 w-100 py-2 px-2 fw-bold  add_page_btn"
            type="submit"
          >
            {lang === "en" ? "Add Page" : "اضافة صفحة"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPageModel;
