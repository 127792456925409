import React from "react";
import { NavLink } from "react-router-dom";
const MobileNavbar = ({ toggle, setToggle, pages, lang }) => {
  const loginToken = localStorage.getItem("token");
  return (
    <ul
      className={`smallNav ${
        toggle && "showing"
      } d-flex d-sm-none justify-content-center align-items-center `}
    >
      <div className="smallNav-container d-flex justify-content-center align-items-center ">
        {pages.map((page) => {
          return (
            <li key={page._id} onClick={() => setToggle(false)}>
              <NavLink to={`/${page._id}`}>{page[`title_${lang}`]}</NavLink>
            </li>
          );
        })}
        <li className="mt-3 page_link">
          <NavLink
            to={!loginToken ? "/login" : "/"}
            onClick={() => loginToken && localStorage.removeItem("token")}
            style={{ color: "#6C6557" }}
          >
            {!loginToken
              ? lang === "en"
                ? "Login"
                : "تسجيل الدخول"
              : lang === "en"
              ? "Log out"
              : "تسجيل الخروج"}
          </NavLink>
        </li>
        {loginToken && (
          <li className=" mobile_profile_link">
            <NavLink to="/profile">
              {lang === "en" ? "Profile" : "الملف الشخصي"}
            </NavLink>
          </li>
        )}
      </div>
    </ul>
  );
};

export default MobileNavbar;
