import axios from "axios";
import { baseUrl } from "../App";
export const publicUrl = "https://drserafi.com:8080/uploads";
//export const publicUrl = "http://localhost:5000/uploads";
const UploadServices = {
  uploadFile: async function (endPoint, dateForm) {
    const { data } = await axios.post(`${baseUrl}/${endPoint}`, dateForm);
    if (data) {
      return data;
    } 
  },
};

export default UploadServices;
