import React, { useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import "./AddResSectionModel.scss";
import { ADD_NEW_RESERVED_SECTION } from "../../../../redux/reducers/resSections_reducer/resSections_types";
import SectionService from "../../../../httpServices/sections.services";
import UploadServices from "../../../../httpServices/httpServices";
import { useFormik } from "formik";
import { RiCloseFill } from "react-icons/ri";
import { AiFillDelete } from "react-icons/ai";
import InputText from "../../forms/Input_text/InputText";
import InputFile from "../../forms/input_file/InputFile";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
const AddResSectionModel = ({ setModels, lang }) => {
  const { reservedSections } = useSelector((state) => state);
  // const [newSection, setNewSection] = useState({
  //   [`title_en`]: "",
  //   [`title_ar`]: "",
  //   [`description_en`]: "",
  //   [`description_ar`]: "",
  //   image: "",
  //   keywordDescription: "",
  //   keywords: [],
  //   subSections: [],
  // });
  const [newSecImage, setNewSecImage] = useState({});

  // const [sectionKeyword, setSectionKeyword] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  // const handleChange = (event) => {
  //   setNewSection({
  //     ...newSection,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // const deleteKeyword = (item) => {
  //   setNewSection({
  //     ...newSection,
  //     keywords: newSection.keywords.filter((_, idx) => idx !== item),
  //   });
  // };
  // const sectionIsExisted = reservedSections.find(
  //   ({ _id }) => _id === newSection._id
  // );
  // const dispatch = useDispatch();

  // const keywordIsExisted = newSection.keywords.find(
  //   (item) => item === sectionKeyword
  // );
  // const addNewKeyword = () => {
  //   if (!keywordIsExisted && sectionKeyword.length > 0) {
  //     setNewSection({
  //       ...newSection,
  //       keywords: [...newSection.keywords, sectionKeyword],
  //     });
  //     setSectionKeyword("");
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      image: "",
      keywordDescription: "",
      keywords: [],
      subSections: [],
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image: Yup.string(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
    onSubmit: async () => {
      if (pageImage) {
        const date = new FormData();
        date.append("image", pageImage);
        let resImage = await UploadServices.uploadFile("upload", date);
        newSection.image = resImage.filename;
      }

      SectionService.addNewSection("section", newSection)
        .then((res) => {
          if (res.section) {
            dispatch({
              type: ADD_NEW_RESERVED_SECTION,
              payload: res.section,
            });

            toast.success(res[`message_${lang}`]);
            removeAddPageModel();
          }
        })
        .catch((err) => {
          if (err.response.data) {
            err.response.data[`message_${lang}`]
              ? setErrorMessage(err.response.data[`message_${lang}`])
              : setErrorMessage(err.response.data);
          }
        });
    },
  });
  const {
    values: newSection,
    setValues: setNewSection,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  const sectionIsExisted = reservedSections.find(
    ({ _id }) => _id === newSection._id
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [pageImage, setPageImage] = useState(null);
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const uploadPageImg = (e) => {
    setPageImage(e.target.files[0]);
  };
  const removeUploadedImg = () => {
    setPageImage(null);
    setNewSection({
      ...newSection,
      image: "",
    });
  };
  const keywordIsExisted = newSection.keywords?.find(
    (item) => item === keyword
  );
  const addNewKeyword = () => {
    if (!keywordIsExisted && keyword.length > 0) {
      setNewSection({
        ...newSection,
        keywords: [...newSection.keywords, keyword],
      });
      setKeyword("");
    } else {
      formik.setErrors({
        ...errors,
        keywords:
          lang === "en" ? "the keyword is existed" : "الكلمة المفتاحية موجودة",
      });
    }
  };
  const deleteKeyword = (item) => {
    setNewSection({
      ...newSection,
      keywords: newSection.keywords.filter((keyword) => keyword !== item),
    });
  };
  const removeAddPageModel = () => {
    setModels((models) => ({
      ...models,
      add: false,
    }));
  };
  return (
    // <div className="add-reserved-section-form">\
    //   <div className="btn-back w-50 text-end">
    //     <button
    //       className="btn px-5 bg-info text-white text-uppercase"
    //       onClick={() => setModel(false)}
    //     >
    //       {lang === "en" ? "back" : "الي الخلف"}
    //     </button>
    //   </div>
    //   <form>
    //     {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
    //     <h4 className="text-center">
    //       {lang === "en" ? "Add New Reserved Section" : "اضافة قسم محجوز جديد"}
    //     </h4>
    //     <InputText
    //       label={lang === "en" ? "title english" : "اللقب بالنجلزي "}
    //       name={`title_en`}
    //       value={newSection[`title_en`]}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "title arabic" : " اللقب بالعربي "}
    //       name={`title_ar`}
    //       value={newSection[`title_ar`]}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "description english" : "الوصف بالنجلزي"}
    //       name={`description_en`}
    //       value={newSection[`description_en`]}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "description arabic" : "الوصف بالعربي"}
    //       name={`description_ar`}
    //       value={newSection[`description_ar`]}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       type="file"
    //       name="image"
    //       handleChange={(e) => setNewSecImage(e.target.files[0])}
    //     />
    //     <InputText
    //       label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
    //       placeholder={
    //         lang === "en" ? "Keyword Description" : "وصف الكلمة الرئيسية"
    //       }
    //       type="text"
    //       name="keywordDescription"
    //       value={newSection.keywordDescription}
    //       handleChange={handleChange}
    //     />
    //     <div className="mt-4">
    //       <label className="fs-5">
    //         {lang === "en" ? "Keywords" : "الكلمات"}
    //       </label>
    //       <div className="d-flex align-items-center justify-content-between mt-2">
    //         <input
    //           type="text"
    //           style={{ width: "90%" }}
    //           className="fs-5 py-2 px-3 rounded border border-dark"
    //           placeholder="Section Keyword"
    //           value={sectionKeyword}
    //           onChange={(e) => setSectionKeyword(e.target.value)}
    //         />
    //         <div
    //           style={{ width: "10%" }}
    //           className="d-flex align-items-center justify-content-center "
    //         >
    //           <button
    //             className="add-button"
    //             type="button"
    //             onClick={addNewKeyword}
    //           >
    //             <IoIosAddCircle />
    //           </button>
    //         </div>
    //       </div>
    //       <div className="mt-1 d-flex flex-wrap h-auto">
    //         {newSection.keywords.map((keyword, index) => (
    //           <span
    //             className="bg-info p-1 px-3  text-white mt-3 ms-3 rounded ms-2 position-relative"
    //             key={index}
    //           >
    //             <button
    //               className="position-absolute bg-danger px-1 py-0 fs-7 border-0 text-white rounded"
    //               style={{ top: "-10px", right: "-10px", zIndex: 3 }}
    //               onClick={() => deleteKeyword(index)}
    //             >
    //               x
    //             </button>
    //             {keyword}
    //           </span>
    //         ))}
    //       </div>
    //       <button
    //         className="mt-5 w-100 py-2 px-2 fw-bold  btn btn-secondary"
    //         type="submit"
    //         onClick={addNewSection}
    //       >
    //         Add Section
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <div className="add_res_section_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeAddPageModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={newSection.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={newSection.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={newSection.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={newSection.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />

        {pageImage !== null ? (
          <div className="img-container">
            <img src={pageImage && URL.createObjectURL(pageImage)} alt="aa" />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={touched.image}
            handleChange={(e) => setPageImage(e.target.files[0])}
          />
        )}
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={newSection.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border:
                  errors.keywords && touched?.keyword
                    ? "1px solid red"
                    : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onBlur={() => {
                formik.setTouched({ ...touched, keyword: true });
                if (newSection.keywords.length < 1) {
                  formik.setErrors({
                    ...errors,
                    keywords:
                      lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
                  });
                }
              }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && touched?.keyword && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {newSection.keywords?.length > 0 && (
            <Box
              component="div"
              sx={{
                height: "100px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: "#aac1fe",
                  transition: "0.5s all",
                  borderRadius: "15px",
                },

                " &::-webkit-scrollbar-thumb:hover": {
                  background: "#78a3e4",
                },
              }}
              className={` keywords_wrapper keywords_wrapper_${lang} `}
            >
              {newSection.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}

          <button
            className="mt-5 w-100 py-2 px-2 fw-bold  add_page_btn"
            type="submit"
          >
            {lang === "en" ? "Add Section" : "اضافة قسم"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddResSectionModel;
