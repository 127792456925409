import React, { useEffect, useState } from 'react'
import './HomePage.scss'
import { useDispatch, useSelector } from 'react-redux'
import FirstRow from './first_row/FirstRow'
import SecondRow from './second_row/SecondRow'
import LastRow from './last_row/LastRow'
import BlogServices from '../../../httpServices/blogs.services'
import { ALL_BLOGS, ALL_USERS } from '../../../redux/types/types'
import UserService from '../../../httpServices/user.service'
import visitorsService from '../../../httpServices/visitors.services'
const HomePage = () => {
  const { user } = useSelector((state) => state)
  const { blogs } = useSelector((state) => state)
  const lang = localStorage.getItem('lang')
  const [countVis, setCountVis] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!blogs.blogs[0]) {
      BlogServices.getAllServices('blog')
        .then((data) => {
          if (data) {
            dispatch({
              type: ALL_BLOGS,
              payload: [
                ...data.blogs.map((blog) => {
                  return {
                    ...blog,
                    isLike: false,
                  }
                }),
              ],
            })
          }
        })
        .catch((e) => {})
    }
    if (!user.users[0]) {
      UserService.getAllUsers(`user`).then((res) => {
        if (res) {
          dispatch({ type: ALL_USERS, payload: res.users })
        }
      })
    }
    visitorsService.getAllVisitors('visitors').then((res) => {
      setCountVis(res.visitors.length)
    })
  }, [])
  return (
    <section className={`home_page home_page_${lang}`}>
      <div className="home_container">
        <FirstRow
          user={user.user}
          countUsers={user.users.length}
          countVis={countVis}
          lang={lang}
        />
        <SecondRow
          lang={lang}
          blogs={blogs.blogs.slice(0, 2)}
          users={user.users.slice(0, 7)}
        />
        {/* <LastRow lang={lang}  /> */}
        {/* <LastRow lang={lang} /> */}
      </div>
    </section>
  )
}

export default HomePage
