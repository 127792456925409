import { AxiosInstance } from "../App";
import { baseUrl } from "../App";

const AdminsServices = {
  getAllAdmins: async function (endPoint) {
    try {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    if (data)
    return data;
  } catch (e) {
    console.log(e.message)
  }
}};

export default AdminsServices;