import { TiChartLineOutline } from "react-icons/ti";
import { AxiosInstance } from "../App";
import { baseUrl } from "../App";


const CommentService = {
    getAllComments: async function (endPoint) {
        try {
            const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`)
            if (data)
                return data;
        } catch (e) {
            console.log('comments Error: ', e.message)
        }
    },
    addComment: async function (endPoint, commentInfo) {

        const { data } = await AxiosInstance.post(`${baseUrl}/${endPoint}`, commentInfo)
        if (data.success)
            console.log('comment Added Successfully',)
        return data;

    },
    deleteComment: async function (endPoint) {
        try {
            const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}`)
            if (data) {
                console.log('comment Deleted successfully; ', data)
                // return data;
            }
        } catch (e) {
            console.log('deletingComment`Error: ', e.message)
        }
    },
    addReply: async function (endPoint, replyInfo) {
        try {
            const { data } = await AxiosInstance.post(`${baseUrl}/${endPoint}`, replyInfo)
            if (data)
                console.log(`datA: `, data)
            return data;
        } catch (e) {
            console.log('adding reply error: ', e.message)
        }
    },
    deleteReply: async function (endPoint, replyInfo) {
        try {
            const { data } = await AxiosInstance.delete(`${baseUrl}/${endPoint}`, replyInfo)
            if (data) {
                console.log('deletedReply Successfully: ', data)
                return data;
            }
        } catch (e) {
            console.log('deleteReplyError: ', e.message)
        }

    }
}
export default CommentService