import { GET_SUGGESTIONS, GET_COMPLAINS, DELETE_SUGGESTIONS, DELETE_COMPLAINS } from "../types/types";
const initialState = {
    complains: [],
    suggestions: []
}

const contactReducer = (state = initialState, {type, payload}) => {

    switch (type) {
        case GET_SUGGESTIONS: 
        return {...state, suggestions: [...payload]}

        case GET_COMPLAINS: 
        return {...state, complains: [...payload]}
        case DELETE_SUGGESTIONS:
            return { ...state, suggestions: [...state.suggestions].filter(suggestion => suggestion._id !== payload) };  
            case DELETE_COMPLAINS:
                return { ...state, complains: [...state.complains].filter(complain => complain._id !== payload) };  
        default:
            return state
    }
}

export default contactReducer