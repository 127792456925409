import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./UserNav.scss";
import { useDispatch } from "react-redux";
import logo from "./rehab_logo.svg";
import MobileNavbar from "./MobileNavbar";
import { FaTimes, FaBars } from "react-icons/fa";
const UserNav = ({ lang, pages }) => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const loginToken = localStorage.getItem("token");
  return (
    // <nav className="user_nav px-5 d-flex justify-content-between align-items-center w-100 position-fixed top-0 start-0">
    //   <NavLink to={`/profile`} className="home-link">
    //     {lang === "en" ? "profile" : "الملف الشخصي"}
    //   </NavLink>
    //   <div className="d-flex">
    //     <ul className="mx-0 px-0">
    //       {pages.map((page) => {
    //         return (
    //           <li key={page._id}>
    //             <NavLink to={`/${page._id}`}>{page[`title_${lang}`]}</NavLink>
    //           </li>
    //         );
    //       })}
    //     </ul>

    //     <li className={`login-link  ${lang === "en" ? "me-2 ms-5" : "me-5"} `}>
    //       <NavLink to="/login">
    //         {lang === "en" ? "Login" : "تسجيل الدخول"}
    //       </NavLink>
    //     </li>
    //     <button
    //       className={`btn text-white fw-bold py-1 ${
    //         lang === "en" ? "ms-1" : "me-3"
    //       }`}
    //       style={{ backgroundColor: "#007ba7" }}
    //       onClick={() => dispatch({ type: "SWITCH_LANGUAGE" })}
    //     >
    //       {lang === "en" ? "العربية" : "EN"}
    //     </button>
    //   </div>
    // </nav>
    <>
      <nav className="rehab_user_navbar">
        <div className="logo-link-container">
          <div className="nav-container">
            <NavLink exact to="/">
              <img src={logo} alt="site logo" />
            </NavLink>
            <ul
              className={`d-sm-flex d-none ${
                lang === "en" && "flex-row-reverse"
              } `}
            >
              {pages.map((page) => {
                return (
                  <li  key={page._id}>
                    <NavLink to={`/${page._id}`}>
                      {page[`title_${lang}`]}
                    </NavLink>
                  </li>
                );
              })}
              <div
                className={`d-flex align-items-center  ${
                  lang === "en" ? "flex-row-reverse ms-3" : "me-3"
                } `}
                style={{ gap: "15px" }}
              >
                <li className="extra-link">
                  <NavLink
                    to={!loginToken ? "/login" : "/"}
                    onClick={() =>
                      loginToken && localStorage.removeItem("token")
                    }
                  >
                    {!loginToken
                      ? lang === "en"
                        ? "Login"
                        : "تسجيل الدخول"
                      : lang === "en"
                      ? "Log out"
                      : "تسجيل الخروج"}
                  </NavLink>
                </li>
                {loginToken && (
                  <li className="extra-link profile_link">
                    <NavLink to="/profile">
                      {lang === "en" ? "Profile" : "الملف الشخصي"}
                    </NavLink>
                  </li>
                )}
                <button
                  className={`btn text-white fw-bold py-1 `}
                  style={{ backgroundColor: "#CF9045" }}
                  onClick={() => dispatch({ type: "SWITCH_LANGUAGE" })}
                >
                  {lang === "en" ? "العربية" : "EN"}
                </button>
              </div>
            </ul>
            <div className="d-inline-block d-sm-none align-self-center">
              <button
                className="py-2 px-4 laguages-nav-btn"
                onClick={() => setToggle((toggle) => !toggle)}
              >
                {toggle ? <FaTimes /> : <FaBars />}
              </button>
              <button
                className={`btn text-white fw-bold py-1 `}
                style={{ backgroundColor: "#CF9045" }}
                onClick={() => dispatch({ type: "SWITCH_LANGUAGE" })}
              >
                {lang === "en" ? "العربية" : "EN"}
              </button>
            </div>
          </div>
        </div>
      </nav>

      <MobileNavbar
        toggle={toggle}
        setToggle={setToggle}
        pages={pages}
        lang={lang}
      />
    </>
  );
};

export default UserNav;
