import React, { useState } from "react";
import InputText from "../../forms/Input_text/InputText";
import { useDispatch } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import { AiFillCloseCircle, AiFillDelete } from "react-icons/ai";
import "./EditReservedSectionModel.scss";
import { EDIT_EXISTED_RESERVED_SECTION } from "../../../../redux/reducers/pages_reducer/page_types";
import { EDIT_RESERVED_SECTION } from "../../../../redux/reducers/resSections_reducer/resSections_types";
import SectionService from "../../../../httpServices/sections.services";
import UploadServices, {
  publicUrl,
} from "../../../../httpServices/httpServices";
import { useFormik } from "formik";
import InputFile from "../../forms/input_file/InputFile";
import { RiCloseFill } from "react-icons/ri";
import * as Yup from "yup";
import SelectTag from "../../forms/select_tag/SelectTag";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
const EditReservedSectionModel = ({ section, setModels, lang }) => {
  const dispatch = useDispatch();
  // const [newSection, setNewSection] = useState({
  //   title_en: selectedSection.title_en,
  //   title_ar: selectedSection.title_ar,
  //   description_en: selectedSection.description_en,
  //   description_ar: selectedSection.description_ar,
  //   image: selectedSection?.image,
  //   keywords: selectedSection.keywords,
  //   keywordDescription: selectedSection?.keywordDescription,
  //   subSections: selectedSection?.subSections,
  // });

  // const [keyword, setKeyword] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  // const handleChange = (event) => {
  //   setNewSection({
  //     ...newSection,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // const keywordIsExisted = newSection.keywords.find((item) => item === keyword);

  // const addKeyword = () => {
  //   if (!keywordIsExisted && keyword.length > 0) {
  //     setNewSection({
  //       ...newSection,
  //       keywords: [...newSection.keywords, keyword],
  //     });
  //     setKeyword("");
  //   }
  // };
  // const deleteKeyword = (parameter) => {
  //   setNewSection(
  //     Object.assign({}, newSection, {
  //       keywords: newSection.keywords.filter((key) => key !== parameter),
  //     })
  //   );
  // };

  // const changeSectionData = async () => {
  //   let data = {
  //     title_en: newSection.title_en,
  //     title_ar: newSection.title_ar,
  //     image: newSection.image,
  //     keywords: newSection.keywords,
  //     keywordDescription: newSection.keywordDescription,
  //     description_en: newSection.description_en,
  //     description_ar: newSection.description_ar,
  //     extras: newSection.extra,
  //   };
  //   if (editResSecImage) {
  //     const date = new FormData();
  //     date.append("image", editResSecImage);
  //     const resImage = await UploadServices.uploadFile("upload", date);
  //     data.image = resImage.filename;
  //   }
  //   SectionService.updateSection("section", selectedSection._id, data)
  //     .then((res) => {
  //       if (res.section) {
  //         dispatch({
  //           type: EDIT_RESERVED_SECTION,
  //           payload: { selectedSection, newSection: res.section },
  //         });
  //         dispatch({
  //           type: EDIT_EXISTED_RESERVED_SECTION,
  //           payload: { selectedSection, newSection: res.section },
  //         });
  //         setModel({ ...model, boolean: false });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response.data) {
  //         err.response.data[`message_${lang}`]
  //           ? setErrorMessage(err.response.data[`message_${lang}`])
  //           : setErrorMessage(err.response.data);
  //       }
  //     });
  // };
  const formik = useFormik({
    initialValues: {
      _id: section._id,
      title_en: section.title_en,
      title_ar: section.title_ar,
      description_en: section.description_en,
      description_ar: section.description_ar,
      image: section?.image,
      keywords: section.keywords,
      keywordDescription: section?.keywordDescription,
      subSections: section?.subSections,
      isActive: section?.isActive,
    },
    validationSchema: Yup.object({
      title_en: Yup.string().required(
        lang === "en" ? "english title required" : "العنوان الانجليزي مطلوب"
      ),
      title_ar: Yup.string().required(
        lang === "en" ? "arabic title required" : "العنوان العربي مطلوب"
      ),
      description_en: Yup.string().required(
        lang === "en" ? "english description required" : "الوصف الانجليزي مطلوب"
      ),
      description_ar: Yup.string().required(
        lang === "en" ? "arabic description required" : "الوصف العربي مطلوب"
      ),
      keywordDescription: Yup.string().required(
        lang === "en"
          ? "keyword description required"
          : "وصف الكلمة الرئيسية مطلوب"
      ),

      image: Yup.string(),
      keywords: Yup.array().min(
        1,
        lang === "en" ? "add keyword" : "اضف كلمة مفتاحية"
      ),
    }),
    onSubmit: async () => {
      let data = {
        title_en: selectedSection.title_en,
        title_ar: selectedSection.title_ar,
        image: selectedSection.image,
        keywords: selectedSection.keywords,
        keywordDescription: selectedSection.keywordDescription,
        description_en: selectedSection.description_en,
        description_ar: selectedSection.description_ar,
        extras: selectedSection.extra,
        isActive: selectedSection.isActive,
      };
      if (pageImage) {
        const date = new FormData();
        date.append("image", pageImage);
        const resImage = await UploadServices.uploadFile("upload", date);
        data.image = resImage.filename;
      }
      SectionService.updateSection("section", selectedSection._id, data)
        .then((res) => {
          if (res.section) {
            dispatch({
              type: EDIT_RESERVED_SECTION,
              payload: { selectedSection, newSection: res.section },
            });
            dispatch({
              type: EDIT_EXISTED_RESERVED_SECTION,
              payload: { selectedSection, newSection: res.section },
            });
            removeEditPageModel();
            toast.success(res.message_en);
          }
        })
        .catch((err) => {
          if (err.response.data) {
            err.response.data[`message_${lang}`]
              ? setErrorMessage(err.response.data[`message_${lang}`])
              : setErrorMessage(err.response.data);
          }
        });
    },
  });
  const {
    values: selectedSection,
    setValues: setSelectedSection,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;
  const [editResSecImage, setEditResSecImage] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [pageImage, setPageImage] = useState(null);
  const [keyword, setKeyword] = useState("");
  // const uploadPageImg = (e) => {
  //   setPageImage(e.target.files[0]);
  //   setSelectedSection({
  //     ...selectedSection,
  //     image: e.target.files[0].name,
  //   });
  // };
  // const removeUploadedImg = () => {
  //   setPageImage(null);
  //   setSelectedSection({
  //     ...selectedSection,
  //     image: "",
  //   });
  // };
  const uploadPageImg = (e) => {
    setPageImage(e.target.files[0]);
  };
  const removeUploadedImg = () => {
    setPageImage(null);
    setSelectedSection({
      ...selectedSection,
      image: "",
    });
  };
  const addNewKeyword = () => {
    if (keyword.length > 0) {
      if (selectedSection.keywords.indexOf(keyword) === -1) {
        setSelectedSection({
          ...selectedSection,
          keywords: [...selectedSection.keywords, keyword],
        });
        setKeyword("");
      } else {
        formik.setErrors({
          ...errors,
          keywords:
            lang === "en"
              ? "this keyword is existed before"
              : "هذه الكلمة المفتاحية موجودة من قبل",
        });
      }
    }
  };
  const deleteKeyword = (item) => {
    setSelectedSection({
      ...selectedSection,
      keywords: selectedSection.keywords.filter((keyword) => keyword !== item),
    });
  };
  const removeEditPageModel = () => {
    setModels((models) => ({
      ...models,
      edit: {
        activition: false,
        pageId: "",
      },
    }));
  };
  const [selectedActivity, setSelectActivity] = useState(
    selectedSection.isActive ? "active" : "not_active"
  );
  const handleActivition = (e) => {
    const { value } = e.target;
    setSelectActivity(value);
    setSelectedSection({
      ...selectedSection,
      isActive: value === "active" ? true : false,
    });
  };

  return (
    // <div className="edit-reserved-section-model">
    //   <form>
    //     {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
    //     <h4 className="text-center">
    //       {lang === "en" ? "Edit Section" : "تعديل القسم"}
    //     </h4>
    //     <button
    //       className="cancel-model btn btn-danger"
    //       type="button"
    //       onClick={() => setModel({ ...model, boolean: false })}
    //     >
    //       <span>🗙</span>
    //     </button>
    //     <InputText
    //       label={lang === "en" ? "title " : "اللقب  "}
    //       name={`title_${lang}`}
    //       value={newSection[`title_${lang}`]}
    //       handleChange={handleChange}
    //     />
    //     <InputText
    //       label={lang === "en" ? "section image link" : "رابط صورة القسم"}
    //       name="image"
    //       type="file"
    //       handleChange={(e) => setEditResSecImage(e.target.files[0])}
    //     />
    //     <InputText
    //       label={lang === "en" ? "description " : "الوصف "}
    //       name={`description_${lang}`}
    //       value={newSection[`description_${lang}`]}
    //       handleChange={handleChange}
    //     />

    //     <InputText
    //       label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
    //       name="keywordDescription"
    //       value={newSection.keywordDescription}
    //       handleChange={handleChange}
    //     />
    //     <div className="row mt-1">
    //       <div className="col-10">
    //         <InputText
    //           label={lang === "en" ? "keyword" : "الكلمة"}
    //           name="keyword"
    //           value={keyword}
    //           handleChange={(e) => setKeyword(e.target.value)}
    //         />
    //       </div>
    //       <div className="col-2 d-flex align-items-end justify-content-center  pb-2">
    //         <button
    //           type="button"
    //           style={{ border: 0, outline: 0, backgroundColor: "transparent" }}
    //           onClick={addKeyword}
    //         >
    //           <IoIosAddCircle style={{ height: "28px", width: "28px" }} />
    //         </button>
    //       </div>
    //       {newSection.keywords?.length > 0 && (
    //         <div className="keywords">
    //           {newSection.keywords.map((keyword, idx) => (
    //             <p key={idx}>
    //               {keyword}
    //               <button type="button" onClick={() => deleteKeyword(keyword)}>
    //                 <AiFillCloseCircle />
    //               </button>
    //             </p>
    //           ))}
    //         </div>
    //       )}
    //     </div>
    //     <div className="update-btn-container">
    //       <button type="button" onClick={changeSectionData}>
    //         {lang === "en" ? "Edit" : "تعديل"}
    //       </button>
    //     </div>
    //   </form>
    // </div>
    <div className="edit_res_section_model">
      <form
        className={`form form_${lang} shadow-sm mx-auto p-4`}
        onSubmit={handleSubmit}
      >
        {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
        <div className="d-flex justify-content-end mb-3">
          <button className="cancel-model" onClick={removeEditPageModel}>
            <RiCloseFill />
          </button>
        </div>
        <InputText
          label={lang === "en" ? "english title" : "العنوان الانجليزي"}
          type="text"
          name="title_en"
          value={selectedSection.title_en}
          handleChange={handleChange}
          error={errors.title_en}
          isTouched={touched.title_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic title" : "العنوان العربي"}
          type="text"
          name="title_ar"
          value={selectedSection.title_ar}
          handleChange={handleChange}
          error={errors.title_ar}
          isTouched={touched.title_ar}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "english description" : "الوصف الانجليزي"}
          type="textarea"
          name="description_en"
          value={selectedSection.description_en}
          handleChange={handleChange}
          error={errors.description_en}
          isTouched={touched.description_en}
          handleBlur={handleBlur}
        />
        <InputText
          label={lang === "en" ? "arabic description" : "الوصف العربي"}
          type="textarea"
          name="description_ar"
          value={selectedSection.description_ar}
          handleChange={handleChange}
          error={errors.description_ar}
          isTouched={touched.description_ar}
          handleBlur={handleBlur}
        />
        <SelectTag
          label={lang === "en" ? "Activity" : "الفعالية"}
          lang={lang}
          selectedActivity={selectedActivity}
          activedValue={selectedSection.isActive}
          handleActivition={handleActivition}
        />
        {/* {selectedSection.image || pageImage ? (
          <div className="img-container">
            <img
              src={
                !pageImage
                  ? `${publicUrl}/${selectedSection.image}`
                  : URL.createObjectURL(pageImage)
              }
              alt="aa"
            />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={pageImage === null}
            handleChange={uploadPageImg}
          />
        )} */}
        {selectedSection.image || pageImage ? (
          <div className="img-container">
            <img
              src={
                !pageImage
                  ? `${publicUrl}/${selectedSection.image}`
                  : URL.createObjectURL(pageImage)
              }
              alt="aa"
            />
            <div className="delete">
              <button type="button" onClick={removeUploadedImg}>
                <AiFillDelete />
              </button>
            </div>
          </div>
        ) : (
          <InputFile
            lang={lang}
            name="image"
            error={errors.image}
            isTouched={pageImage === null}
            handleChange={uploadPageImg}
          />
        )}
        <InputText
          label={lang === "en" ? "keyword description" : "وصف الكلمة الرئيسية"}
          type="text"
          name="keywordDescription"
          value={selectedSection.keywordDescription}
          handleChange={handleChange}
          error={errors.keywordDescription}
          isTouched={touched.keywordDescription}
          handleBlur={handleBlur}
        />
        <div className="mt-3">
          <label style={{ fontSize: "19px" }}>
            {lang === "en" ? "keywords" : "الكلمات المفتاحية"}
          </label>
          <div className="d-flex align-items-center justify-content-between">
            <input
              type="text"
              style={{
                width: "90%",
                border: errors.keywords ? "1px solid red" : "1px solid #2D62ED",
              }}
              className="fs-5 m-0 py-2 px-3 rounded"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              // onBlur={() => {
              //   formik.setTouched({ ...touched, keyword: true });
              //   if (selectedSection.keywords.length < 1) {
              //     formik.setErrors({
              //       ...errors,
              //       keywords:
              //         lang === "en" ? "add keyword" : "اضف كلمة مفتاحية",
              //     });
              //   }
              // }}
            />

            <div
              style={{ width: "10%" }}
              className="d-flex align-items-center justify-content-center "
            >
              <button
                className="add_keyword_btn"
                type="button"
                onClick={addNewKeyword}
              >
                <IoIosAddCircle />
              </button>
            </div>
          </div>
          {errors.keywords && (
            <p style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
              {errors.keywords}
            </p>
          )}
          {selectedSection.keywords?.length > 0 && (
            <Box
              sx={{
                height: "100px",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius:
                    lang === "en" ? "0 15px 15px 0" : "15px 0 0 15px",
                },

                "&::-webkit-scrollbar-thumb": {
                  background: "#aac1fe",
                  transition: "0.5s all",
                  borderRadius: "15px",
                },

                " &::-webkit-scrollbar-thumb:hover": {
                  background: "#78a3e4",
                },
              }}
              className={` keywords_wrapper keywords_wrapper_${lang} `}
            >
              {selectedSection.keywords.map((keyword, index) => {
                return (
                  <span key={index}>
                    {keyword}
                    <button
                      type="button"
                      onClick={() => deleteKeyword(keyword)}
                    >
                      <p>🗙</p>
                    </button>
                  </span>
                );
              })}
            </Box>
          )}

          <button
            className={`mt-5 w-100 py-2 px-2 fw-bold  add_page_btn ${
              Object.keys(formik.errors).length > 0 && "disabled_btn"
            }`}
            type="submit"
          >
            {lang === "en" ? "Update Section" : "تحديث القسم"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditReservedSectionModel;
