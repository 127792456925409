import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import BlogServices from "../../../../httpServices/blogs.services";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NEW_BLOG, SET_USER } from "../../../../redux/types/types";
import UserService from "../../../../httpServices/user.service";
import { BsFillPlusCircleFill } from "react-icons/bs";
import * as Yup from "yup";
import styles from "./addNewBlog.module.scss";
import { AiFillCloseCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { useRef } from "react";
import UploadServices, {
  publicUrl,
} from "../../../../httpServices/httpServices";
import BlogImage from "../../../../assets/blogImage.png";

function AddNewBlog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  // const [blogKeyword, setBlogKeyword] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const { user } = useSelector((state) => state.user);
  const [keywords, setKeywords] = useState([]);
  const [singleKeword, setSingleKeyword] = useState("");
  const [blogType, setBlogType] = useState(null);
  const [file, setFile] = useState(null);
  const addBlog = async (blogInfo) => {
    let blog = {};
    await BlogServices.addBlog("blog", {
      ...blogInfo,
      user: userProfile._id,
    }).then((value) => {
      blog = { ...value.newBlog, __v: undefined, user: userProfile._id };
      dispatch({ type: NEW_BLOG, payload: blog });
      // navigating to the other Blogs pages
      navigate("/adminDashboard/blogs");
    });
  };

  const handleBlogType = (value) => {
    setBlogType((_) => value);
    formik.setFieldValue("blogType", value);
  };
  const handleChangeSingleKeyWord = (e) => {
    setSingleKeyword((_) => e.target.value);
  };

  const handleKeywords = (value) => {
    console.log("the setnt value: ", value);
    if (!keywords.includes(value)) {
      setKeywords((prev) => [...prev, value]);
      setSingleKeyword("");
    }
  };
  const deleteKeyword = (keyword) =>
    setKeywords(keywords.filter((each) => each !== keyword));
  useEffect(() => {
    if (Object.keys(user).length > 1) {
      setUserProfile((_) => user);
    } else {
      const getUserProfile = () => {
        UserService.getUser("user/me").then((value) => {
          setUserProfile((_) => value.user);
          dispatch({ type: SET_USER, payload: value.user });
        });
      };
      getUserProfile();
    }
  }, [user, dispatch]);

  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: "",
      endDate: "",
      description: "",
      keywordDescription: "",
      image: "asdas",
      blogType,
    },

    onSubmit: (values) => {
      // alert(JSON.stringify({ ...values, keywords }, null, 2));
      if (values.blogType != "event") {
        delete values["startDate"];
        delete values["endDate"];
      }

      // console.log('ajkdfjk', )
      // now call add blog

      addBlog({ ...values, keywords });
      console.log(values, keywords);
    },

    validationSchema: Yup.object({
      title: Yup.string().required(),
      description: Yup.string().required(),
      keywordDescription: Yup.string().required(),
      startDate: Yup.string().when("blogType", {
        is: "event",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      endDate: Yup.string().when("blogType", {
        is: "event",
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      // image: Yup.string().required().matches(/.jpg|.png|.jpeg|.svg\b/g)
      // password: Yup.string().required(),
    }),
  });

  const handleFileChange = (e) => {
    // console.log(imageRef.current)
    // console.log(e.target.files[0])
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      const data = new FormData();
      data.append("image", e.target.files[0]);
      console.log("image: ", data.get("image"));
      UploadServices.uploadFile("upload", data)
        .then((value) => {
          console.log("value: ", value);
          formik.setFieldValue("image", value.filename);
        })
        .catch((e) => {
          console.log("ay 7aga: ", e.message);
        });
      // // setImagePath(_ => URL.createObjectURL(e.target.files[0]))
      // formik.setFieldValue('image', e.target.files[0].name)
    }
  };
  return (
    <div className="">
      <div
        className={`${styles.formContainer}
      `}
      >
        <div className="d-flex align-items-center justify-content-center">
          <form
            onSubmit={formik.handleSubmit}
            className={` ${styles.formBody} position-relative`}
          >
            <div className={`${styles.iconWrapper}`}>
              <button onClick={() => navigate(-1)}>
                <CgClose />
              </button>
            </div>
            <div className="form-group">
              <label>{lang === "en" ? "Title" : "العنوان"}</label>
              <input
                type="text"
                className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                value={formik.tilte}
                onChange={formik.handleChange}
                id="title"
                placeholder={lang === "en" ? "Title" : "العنوان"}
                onBlur={formik.handleBlur}
              />
              <p className="text-danger ">
                {formik.errors["title"] && `*.` + formik.errors.title}
              </p>
            </div>
            <div className="form-group">
              <label>{lang === "en" ? "Type" : "النوع"}</label>
              <select
                className={`form-select ${styles.input} ${styles.inputField}
                                `}
                onChange={(e) => handleBlogType(e.target.value)}
                id="blogType"
              >
                <option value="Select Type" hidden selected>
                  {lang === "en" ? "Choose Option" : "اختار"}
                </option>
                <option value="blog">{lang === "en" ? "Blog" : "مدونة"}</option>
                <option value="event">
                  {lang === "en" ? "Event" : "فعالية"}
                </option>
              </select>
              {blogType && blogType === "event" ? (
                <>
                  <div className="form-group">
                    <label>
                      {lang === "en" ? "Start Date" : "تاريخ البدء"}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                      value={formik.startDate}
                      onChange={formik.handleChange}
                      id="startDate"
                      placeholder="Start Date"
                      onBlur={formik.handleBlur}
                    />
                    <p className="text-danger ">
                      {formik.errors["startDate"] &&
                        `*.` + formik.errors.startDate}
                    </p>
                  </div>
                  <div className="form-group">
                    <label>
                      {lang === "en" ? "End Date" : "تاريخ الانتهاء"}
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                      value={formik.endDate}
                      onChange={formik.handleChange}
                      id="endDate"
                      placeholder="End Date"
                    />
                  </div>
                  <p className="text-danger ">
                    {formik.errors["endDate"] && `*.` + formik.errors.endDate}
                  </p>
                </>
              ) : null}
            </div>
            <div className="wrapper ">
              <div className="btnimg">
                {!file ? (
                  <div
                    className={``}
                    style={{ height: "5rem", margin: "20px 0" }}
                  >
                    <img
                      src={`${BlogImage}`}
                      alt="profile"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid blue",
                        borderRadius: "5px",
                      }}
                      className={``}
                      // width="150px"
                      // style={{ height: "150px", borderRadius: "50%" }}
                    />
                  </div>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="profile"
                    width="100%"
                    style={{
                      height: "5rem",
                      width: "100%",
                      margin: "20px 0",
                      border: "1px solid blue",
                      borderRadius: "5px",
                    }}
                  />
                )}
              </div>
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </div>

            <div className="form-group">
              <label>{lang === "en" ? "Description" : "الوصف"}</label>
              <input
                type="text"
                className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                value={formik.Description}
                onChange={formik.handleChange}
                id="description"
                placeholder={lang === "en" ? "Description" : "الوصف"}
                onBlur={formik.handleBlur}
              />
              <p className="text-danger ">
                {formik.errors["description"] &&
                  `*.` + formik.errors.description}
              </p>
            </div>
            <div className="form-group">
              <label>
                {lang === "en" ? "Keyword Description" : "وصف الكلمة الرئيسية"}
              </label>
              <input
                type="text"
                className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                value={formik.keywordDescription}
                onChange={formik.handleChange}
                id="keywordDescription"
                placeholder={
                  lang === "en" ? "Keyword Description" : "وصف الكلمة الرئيسية"
                }
                onBlur={formik.handleBlur}
              />
              <p className="text-danger ">
                {formik.errors["keywordDescription"] &&
                  `*.` + formik.errors.keywordDescription}
              </p>
            </div>
            <div className="form-group d-flex flex-column">
              <label>{lang === "en" ? "Keywords" : "الكلمات المفتاحية"}</label>
              <div className="d-flex justify-conent-center align-items-center">
                <input
                  type="text"
                  className={`form-control ${styles.input}
                                    ${styles.inputField}
                                `}
                  value={singleKeword}
                  onChange={handleChangeSingleKeyWord}
                  id="Keyword"
                  placeholder={lang === "en" ? "Keywords" : "الكلمات المفتاحية"}
                  style={{ width: "90%" }}
                />
                <BsFillPlusCircleFill
                  className="justify-content-center align-items-center m-2 "
                  style={{ fontSize: "23px", color: "#2D62ED" }}
                  onClick={() => handleKeywords(singleKeword)}
                />
              </div>
              <div className=" d-flex  flex-wrap mt-3 h-auto">
                {keywords.length > 0 &&
                  keywords.map((keyword, index) => {
                    return (
                      <span
                        className="p-1 px-3 text-white m-3 rounded ms-2 position-relative"
                        key={index}
                        style={{ backgroundColor: "#6289F1" }}
                      >
                        <button
                          onClick={() => deleteKeyword(keyword)}
                          className={`position-absolute bg-danger d-flex align-items-center justify-content-center text-white btn  ${
                            lang === "en" ? "end-0" : "start-0"
                          }`}
                          style={{
                            top: "-8px",
                            height: "20px",
                            width: "10px",
                          }}
                        >
                          X
                        </button>
                        {keyword}
                      </span>
                    );
                  })}
              </div>
            </div>
            {/* <div className="form-group" style={{ background: 'black', display: 'block', width: '100px', height: '100px', display: 'none' }} ref={inputRef}>
                            <input type="file" placeholder="input Your File" />
                        </div> */}

            <button
              type="submit"
              disabled={!blogType ? true : false}
              className={styles.submitButton}
            >
              {lang === "en" ? "Add" : "إضافة"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddNewBlog;
