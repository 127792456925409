import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import subSectionsService from "../../../httpServices/subSection.services";
import { publicUrl } from "../../../httpServices/httpServices";
import "./UserSubSingle.scss";
import SectionService from "../../../httpServices/sections.services";
import ReceverService from "../../../httpServices/recever.services";
import { AiOutlineRollback } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import UserNav from "../../components/user_nav/UserNav";
import { ADD_OFFER } from "../../../redux/types/types";
import resTypesServices from "../../../httpServices/resTypes.services";
import { GET_RES_TYPES } from "../../../redux/reducers/resTypes_reducer/resTypesReducer";
const UserSubSingle = () => {
  const { pages, resTypes } = useSelector((state) => state);
  const { pageId, sectionId, subId } = useParams();
  const currentPage = pages.find(({ _id }) => _id === pageId);
  const [currentSection, setCurrentSection] = useState([]);
  const [currentSub, setCurrentSub] = useState({});
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const loginTokenIsExisted = localStorage.getItem("token");
  const { pathname } = useLocation();
  const matchingTitles = ["offer", "service"]

    .map((word) => currentSection?.title_en?.includes(word))
    .includes(true);
  function receved(id) {
    if (currentSection?.title_en?.includes("offer")) {
      let data = {
        offerId: id,
        typeRec: type,
      };
      console.log(data);
      if (type) {
        ReceverService.takeOffer("receiveOffer", sectionId, data)
          .then((res) => {
            if (res.status) {
              console.log(res.offer);
              setMessage(res[`message_${lang}`]);
              setErrorMessage("");
              dispatch({ type: ADD_OFFER, payload: res.offer });
            }
          })
          .catch((err) => {
            if (err.response.data.message_en === "Access Denied") {
              setErrorMessage(
                lang === "en"
                  ? "Please login first to take the offer"
                  : "من فضللك سجل الدخول اولاً لحجز العرض"
              );
            }
            if (err.response.data) {
              setMessage("");
              err.response.data[`message_${lang}`]
                ? setErrorMessage(err.response.data[`message_${lang}`])
                : setErrorMessage(err.response.data);
            }
          });
      } else { 
        toast.error(lang === "en" ? "Select type of reservation first" : "اختار نوع حجز أولاً" )
      }
    }
  }

  useEffect(() => {
    const sub = currentSection?.subSections?.find(({ _id }) => _id === subId);
    const section = currentPage?.sections?.find(({ _id }) => _id === sectionId);
    if (sub) {
      setCurrentSub(sub);
    } else {
      subSectionsService.getSubSectionById("subSection", subId).then((res) => {
        if (res.subsection) {
          setCurrentSub(res.subsection);
        }
      });
    }
    if (section) {
      setCurrentSection(section);
    } else {
      SectionService.getSectionById("section", sectionId).then((res) => {
        if (res.section) {
          setCurrentSection(res.section);
        }
      });
    }
  }, []);
  const navigateToLoginPage = () => {
    toast.error(lang === "en" ? "Login First" : "سجل الدخول اولاً");
    setTimeout(() => navigate(`/login?url=${pathname}`), 3000);
  };
  const [type, setType] = useState("");
  useEffect(() => {
    if (resTypes) {
      resTypesServices.fetching().then((res) => {
        if (res?.success) {
          dispatch({ type: GET_RES_TYPES, payload: res?.getAll });
        }
      });
    }
  }, [dispatch]);
  return (
    <div className="userSubSingle">
      <UserNav lang={lang} pages={pages} />
      <ToastContainer />

      {currentSub.title_en && (
        <div className="details-container bg-white shadow">
          <div className="d-flex justify-content-start back_to_prev_page_btn">
            <button onClick={() => navigate(-1)}>
              <AiOutlineRollback />
            </button>
          </div>
          <div className="img_container">
            <img src={`${publicUrl}/${currentSub?.image}`} alt="" />
          </div>
          <div className="other_container">
            <div clasName="w-75 mx-auto">
              <h2 className="fw-bold">{currentSub[`title_${lang}`]}</h2>
              <p className="sub_des">{currentSub[`description_${lang}`]}</p>
              {matchingTitles ? (
                <div>
                  <select
                    className="my-3 py-1 px-3"
                    style={{
                      borderRadius: "5px",
                      border: "1px solid #008000",
                    }}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option hidden selected>
                      {lang === "en" ? "Reservation Type" : "نوع الحجز"}
                    </option>
                    {resTypes?.map(({ name, _id }) => (
                      <option key={_id} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : undefined}
              {matchingTitles && (
                <div>
                  <button
                    className="reserve-user-button"
                    onClick={() =>
                      loginTokenIsExisted === null
                        ? navigateToLoginPage()
                        : receved(currentSub._id)
                    }
                  >
                    {lang === "en" ? "Reservse Now" : "احجز الان"}
                  </button>
                  {message && !errorMessage && (
                    <h6 className="alert alert-success reservsed_message  mx-auto">
                      {message}
                    </h6>
                  )}
                  {!message && errorMessage && (
                    <h6 className="alert alert-danger reservsed_message mx-auto">
                      {errorMessage}
                    </h6>
                  )}
                </div>
              )}
              {currentSub?.extras?.length > 0 && (
                <div className="extras_wrapper">
                  {currentSub?.extras?.length > 0 &&
                    currentSub?.extras.map((extraItem, idx) => (
                      <div class="card extras_item" key={idx}>
                        <div class="card-body">
                          <h6 class="card-title fw-bold">
                            {extraItem[`key_${lang}`]}
                          </h6>
                          <p class="card-text">{extraItem[`value_${lang}`]}</p>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserSubSingle;
