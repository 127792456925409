import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./newBlog.scss";
import BlogServices from "../../../../../httpServices/blogs.services";
import UserService from "../../../../../httpServices/user.service";
import { ALL_BLOGS, SET_USER } from "./../../../../../redux/types/types";
import Comment from "../../Comments/Comment";
import Like from "../../LikeComponent/Like";
import { AiFillHeart, AiOutlineComment } from "react-icons/ai";
import { publicUrl } from "../../../../../httpServices/httpServices";
import { MdOutlineArrowBack } from "react-icons/md";
function NewBlogDesign() {
  const dispatch = useDispatch();
  const [currentBlog, setCurrentBlog] = useState(null);

  const { blogs } = useSelector((state) => state.blogs);
  const { user } = useSelector((state) => state.user);

  const { blogId } = useParams();
  useEffect(() => {
    setCurrentBlog((prev) => blogs.find((blog) => blog._id === blogId));
  }, [blogs, blogId]);

  useEffect(() => {
    if (blogs.length == 0) {
      const getData = () => {
        BlogServices.getAllServices("blog")
          .then((data) => {
            setCurrentBlog((_) =>
              [
                ...data.blogs.map((blog) => {
                  return { ...blog, isLike: false };
                }),
              ].find((blog) => blog._id == blogId)
            );
            dispatch({
              type: ALL_BLOGS,
              payload: [
                ...data.blogs.map((blog) => {
                  return { ...blog, isLike: false };
                }),
              ],
            });
          })
          .catch((e) => {
            console.log("error: ", e);
          });
      };
      getData();

      const getUserProfile = () => {
        UserService.userProfile("user/me").then((value) => {
          // dispatching to save the loged in user
          dispatch({ type: SET_USER, payload: value.user });
        });
      };

      getUserProfile();
    } else {
      setCurrentBlog((_) =>
        [
          ...blogs.map((blog) => {
            return { ...blog, isLike: false };
          }),
        ].find((blog) => blog._id == blogId)
      );
    }
    // // get userProfile
  }, [blogs, dispatch, blogId, user]);
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  return (
    <div className="newBlog ">
      <div className="blog_wrapper">
        {currentBlog && (
          <div className="newBlog__content">
            <div className="back_btn d-flex justify-content-end">
              <button onClick={() => navigate(-1)}>
                <MdOutlineArrowBack
                  style={{ transform: lang === "en" && "rotateY(180deg)" }}
                />
              </button>
            </div>
            <h2 className="lead text-end text-secondary my-4 text-center">
              {lang === "en" ? "Blog" : "مدونة"}
            </h2>
            <div className="imageWrapper">
              <img
                src={
                  `${publicUrl}/${currentBlog.image}` ||
                  `https://images.pexels.com/photos/237272/pexels-photo-237272.jpeg?auto=compress&cs=tinysrgb&w=600`
                }
                alt="im"
              />
            </div>

            {/* {blogInfo} */}
            <div className="newBlog__info ">
              <p className="lead my-2 title">{currentBlog.title}</p>
              <p className="description">{currentBlog.description}</p>
            </div>
            <div className="reaction_wrapper">
              <div className="reaction__comments">
                <AiOutlineComment className="commentFont" />
                <span className="commentWord mx-1">
                  {currentBlog.comments.length}
                  {lang === "en" ? " comment" : " تعليق"}
                </span>
              </div>
              <div className="reaction__likes">
                <Like object={{ blog: currentBlog }} />
                {/* <span
                  className={`likeWord ${lang === "en" ? "ms-3" : "me-3"} `}
                >
                  {currentBlog.likes.length}{" "}
                 
                </span> */}
              </div>
            </div>
            <div className={` comments comments_${lang}`}>
              {<Comment blog={currentBlog} user={user} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NewBlogDesign;
