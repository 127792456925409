import {
  NEW_BLOG,
  DELETE_BLOG,
  EDIT_BLOG,
  ALL_BLOGS,
  ADD_COMMENT,
  SET_BLOG,
  DELETE_COMMENT,
  ADD_REPLY,
  DELETE_REPLY,
} from "../../types/types";
import blogs, { Blog } from "../../../initialData/blogs";

const initialState = {
  blogs: [],
  blog: {},
};
const blogsReducer = (state = initialState, { type, payload }) => {
  // const blogs = await Blog()
  // state = blogs
  switch (type) {
    case NEW_BLOG:
      return { ...state, blogs: [...state.blogs, payload] };
    // case DELETE_BLOG:
    //   // return { ...state,blogs: ...state.filter((blog) => blog.id !== payload); }
    case EDIT_BLOG:
      return {
        ...state,
        blogs: [...state.blogs].map((blog) =>
          blog._id == payload._id ? payload : blog
        ),
      };
    case ALL_BLOGS:
      return { ...state, blogs: [...payload] };
    case SET_BLOG:
      return {
        ...state,
        blog: [...state.blogs].find((blog) => blog._id == payload),
      };
    case DELETE_BLOG:
      return { ...state, blogs: [...state.blogs].filter(blog => blog._id !== payload) }

    case ADD_COMMENT:
      // console.log('ADDComment: ', payload)
      // let newBlogs = [...state.blogs]
      // newBlogs = newBlogs.map(blog => blog._id == payload.blog ? { ...blog, comments: [...blog.comments, payload] } : blog)

      return {
        ...state,
        blogs: state.blogs.map((eachBlog) =>
          eachBlog._id === payload.blog
            ? {
              ...eachBlog,
              comments: [...eachBlog.comments, payload],
            }
            : eachBlog
        ),
      };
    case DELETE_COMMENT:
      return {
        ...state,
        blogs: [...state.blogs].map((blog) =>
          blog._id == payload.blog
            ? {
              ...blog,
              comments: [...blog.comments].filter(
                (comment) => comment._id != payload.comment
              ),
            }
            : blog
        ),
      };
    case ADD_REPLY:
      return {
        ...state,
        blogs: [...state.blogs].map((blog) =>
          blog._id == payload.blog
            ? {
              ...blog,
              comments: [...blog.comments].map((comment) =>
                comment._id == payload.comment
                  ? { ...comment, replys: [...comment.replys, payload] }
                  : comment
              ),
            }
            : blog
        ),
      };
    case DELETE_REPLY:
      return {
        ...state,
        blogs: [...state.blogs].map(blog => blog._id == payload.blog ?
          {
            ...blog, comments: [...blog.comments].map(comment => comment._id == payload.comment ?
              { ...comment, replys: [...comment.replys].filter(reply => reply._id != payload.reply) } : comment)
          } : blog)
      }

    default:
      return state;
  }
};
export default blogsReducer;
