import axios from "axios";
import { baseUrl } from "../App";
import { AxiosInstance } from "../App";

const UserService = {
  loginUser: async function (endPoint, loginData) {
    const { data } = await axios.post(`${baseUrl}/${endPoint}`, loginData);
    return data;
  },
  createUser: async function (endPoint, userInfo) {
    const { data } = await AxiosInstance.post(
      `${baseUrl}/${endPoint}`,
      userInfo
    );
    if (data) {
      return data;
    }
  },
  getUser: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    if (data) {
      return data;
    }
  },
  updateUser: async function (endPoint, userInfo) {
    const { data } = await AxiosInstance.put(
      `${baseUrl}/${endPoint}`,
      userInfo
    );
    if (data) {
      return data;
    }
  },
  userProfile: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    if (data) return data;
  },
  getAllUsers: async function (endPoint) {
    const { data } = await AxiosInstance.get(`${baseUrl}/${endPoint}`);
    if (data) return data;
  },
  deleteAll: async function (endPoint, ids) {
    const { data } = await AxiosInstance.put(`${baseUrl}/${endPoint}`, ids);
    if (data) return data;
  },
};

export default UserService;
